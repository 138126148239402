import { http } from "../request"

interface WorkAddLikeResponse {
    collector_id: number
    work_id: number
}

export const workRemoveLikeApi = async (id: number, accessToken: string) => {
    return await http.delete<WorkAddLikeResponse>(`/work/like/${id}`, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
}
