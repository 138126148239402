import React, { createContext, memo, useEffect, useMemo, useState } from 'react'
import MyButton from '../../components/my/MyButton'
import { useLocation } from 'react-router-dom'
import { collectibleFindApi } from '../../api/collectible/find'
import useAccessToken from '../../hooks/useAccessToken'
import { parseNormal } from '../../utils/parseCollectibles'
import { CollectibleProps } from '../../vo/collectible.vo'
import { CollectibleList } from '../../components/collectible/CollectibleList'
import { workFindApi } from '../../api/work/find'
import { WorkVo } from '../../vo/work.vo'
import { BookList } from '../../components/book/BookList'

interface Tab {
  id: number
  name: string
  pageSize: number
}

type SearchContextType = {
  currentPage: number
  pageSize: number
}

export const SearchContext = createContext<SearchContextType>({
  currentPage: 0,
  pageSize: 0
})

const Search: React.FC = memo(() => {
  // router
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const keywords = searchParams.get('keywords')

  // other data
  const tabs: Tab[] = [ 
    { id: 1, name: "書籍", pageSize: 8 },
    { id: 2, name: "藏品", pageSize: 9 }
  ]

  // responsive data
  const [collectibles, setCollectibles] = useState<CollectibleProps[]>([])
  const [collectiblesLoading, setCollectiblesLoading] = useState<boolean>(false)
  const [books, setBooks] = useState<WorkVo[]>([])
  const [booksLoading, setBooksLoading] = useState<boolean>(false)
  const [selectedTabId, setSelectedTabId] = useState<number>(tabs[0].id) // 当前tab
  const [total, setTotal] = useState<number>(0) // 数据总数

  // hook
  const { accessToken } = useAccessToken()

  // mounted: fetch data
  useEffect(() => {
    async function searchCollectibles() {
      setCollectiblesLoading(true)
      const collectibles = parseNormal(await collectibleFindApi({ content: keywords || "", }, accessToken))
      setCollectibles(collectibles)
      setTotal(collectibles.length)
      setCollectiblesLoading(false)
    }
    async function searchBooks() {
      setBooksLoading(true)
      const books = await workFindApi(accessToken, { search: keywords || "" })
      setBooks(books)
      setTotal(books.length)
      setBooksLoading(false)
    }
    switch(selectedTabId) {
      case 1: searchBooks(); return
      case 2: searchCollectibles(); return
    }
  }, [accessToken, keywords, selectedTabId])

  // method: 切换tab
  const handleChangeSelectedTab = (tab: Tab) => {
    setSelectedTabId(tab.id)
    setTotal(0)
  }

  // computed: 当前 Tab 相应组件
  const RenderList = useMemo(() => {
    const headerSlot = (
      <section className='text-primary text-base my-8'>
        搜尋 : { keywords } ({ total }個結果)
      </section>
    )
    switch(selectedTabId) {
      case 1: return <BookList data={books} loading={booksLoading} headerSlot={headerSlot} />
      case 2: return <CollectibleList data={collectibles} loading={collectiblesLoading} headerSlot={headerSlot} />
    }
  }, [selectedTabId, keywords, total, books, booksLoading, collectibles, collectiblesLoading])

  return (
    <main className='pt-20'>
      {/* 分类导航 */}
      <div className='flex justify-center gap-4 pb-10'>
        {
          tabs.map(tab => (
            <MyButton
              key={tab.id}
              onClick={() => handleChangeSelectedTab(tab)}
              style={{
                backgroundColor: selectedTabId === tab.id ? "#ebeee8" : "transparent",
                fontWeight: selectedTabId === tab.id ? "bold" : "normal"
              }}
              className='px-7 shadow-none h-10 text-sm'
            >{tab.name}</MyButton>
          ))
        }
      </div>
      { RenderList }
    </main>
  )
})

export default Search