import React, { useContext, useEffect, useState } from 'react';
import { Button, Drawer, Spin, Popconfirm } from 'antd';
import PlusSVG from '../svgs/PlusSVG';
import { ChapterVo } from '../../vo/chapter.vo';
import { WorkVo } from '../../vo/work.vo';
import ViewportProvider, { ViewportProviderContext } from '../../providers/ViewportProvider';
import { useAppDispatch} from '../../redux'
import "../../assets/css/chapterListDrawer.css";
import { IoIosArrowBack } from "react-icons/io";
import BackButton from '../buttons/backButton';
import { GoTrash } from "react-icons/go";
import { useDispatch } from 'react-redux';
import { chapterDeleteAsync } from '../../redux/modules/editSlice';
import useAccessToken from '../../hooks/useAccessToken';

interface ChapterListProps {
    setChaptersVisible: (visible: boolean) => void;
    chaptersVisible: boolean;
    editWorkLoading: boolean;
    editWork: WorkVo | null;
    selectedEditChapterId: number;
    border: string;
    handleChangeSelectedEditChapterId: (id: number) => void;
    handleAddEditChapter: () => void;
}

const ChapterList: React.FC<ChapterListProps> = ({
    setChaptersVisible,
    chaptersVisible,
    editWorkLoading,
    editWork,
    selectedEditChapterId,
    border,
    handleChangeSelectedEditChapterId,
    handleAddEditChapter,
}) => {
    const { isMobile, isDesktop, notDesktop, isTablet } = useContext(ViewportProviderContext);

    const { accessToken } = useAccessToken()

    const [chapterListKey, setChapterListKey] = useState(0);

    useEffect(() => {
        setChapterListKey(prevKey => prevKey + 1);
    }, [editWork?.chapters]);

    const onClose = () => setChaptersVisible(false);

    const dispatch = useAppDispatch()

    const handleDelete = (chapterId: number) => {
        dispatch(chapterDeleteAsync({ id: chapterId, accessToken }));
    };

    const confirm = (chapterId: number) => {
        handleDelete(chapterId);
    };

    const cancel = () => {
        console.log("Delete cancelled");
    };

    const renderChapterButton = (editChapter: ChapterVo, index: number) => (
        <li key={index}>
            <Button
                onClick={() => handleChangeSelectedEditChapterId(editChapter.id)}
                style={{
                    borderColor: border,
                    backgroundColor: selectedEditChapterId === editChapter.id ? border : "#fff"
                }}
                className='chapterButton aspect-[6/1] rounded-xl bg-white border-[1px] border-solid flex gap-5 items-center justify-between h-16 w-full px-6 cursor-pointer'
            >
                <div className='flex gap-3 items-center flex-1'>
                    <div className='flex gap-3'>
                        <span className='text-base' style={{ color: selectedEditChapterId === editChapter.id ? "#fff" : border }}>
                            {editChapter.chapter_prefix ? `第 ${editChapter.chapter_prefix} 章` : "新章節"}
                        </span>
                        <span className='text-base' style={{ color: selectedEditChapterId === editChapter.id ? "#fff" : "#102e24" }}>{editChapter.title}</span>
                    </div>
                </div>
                {editChapter.status === "DRAFT" && (
                    <span className='text-[#919190] bg-[#f1f1f1] rounded-full px-2'>草稿</span>
                )}
                <Popconfirm
                    title={<div>確認刪除章節嗎？</div>}
                    description={false}
                    onConfirm={() => confirm(editChapter.id)}
                    onCancel={cancel}
                    okText="確認"
                    cancelText="取消"
                    placement="left"
                    className='popconfirmDeleteChapter'
                >
                    <Button danger type="text" className='w-4 flex items-center deleteChapterButton' icon={<GoTrash className='text-base text-primary' />}></Button>
                </Popconfirm>
            </Button>
        </li>
    );

    const renderChapterList = () => (
        <ul className='flex flex-col gap-5' key={chapterListKey}>
            {editWorkLoading && <Spin className='my-20' />}
            {editWork?.chapters && editWork.chapters.map((editChapter, index) => editChapter && renderChapterButton(editChapter, index))}
            {!editWorkLoading && (
                <li
                    onClick={handleAddEditChapter}
                    className='aspect-[6/1] p-3 rounded-xl bg-white border-[1px] border-solid flex gap-5 items-center h-16 w-full px-6 cursor-pointer'
                >
                    <PlusSVG size={`${isDesktop ? "24" : isTablet ? "32" : "24"}`} />
                    <span className='text-primary text-base'>新增章節</span>
                </li>
            )}
        </ul>
    );

    return isDesktop ? (
        <section
            style={{ width: chaptersVisible ? '300px' : '0' }}
            className='overflow-hidden h-auto transition duration-700'
        >
            {renderChapterList()}
        </section>
    ) : (
        <Drawer
            placement={"left"}
            open={chaptersVisible}
            getContainer={false}
            onClose={onClose}
            className={'chapterListDrawer'}
            title={null}
            closeIcon={false}
        >
            <div className='flex w-full justify-center relative mb-6'>
                <BackButton onClickFunction={onClose} title='收起' className='absolute' />
                <div className='text-base'>章節列表</div>
            </div>
            {renderChapterList()}
        </Drawer>
    );
};

export default ChapterList;