import React, { memo } from 'react'

const MySpin: React.FC = memo(() => {
  return (
    <main className="flex justify-center items-center w-screen h-screen">
      <div className='animate-spin w-10 h-10 rounded-full border-dotted border-2 border-b-0 border-r-0 mb-36'></div>
    </main>
  )
})

export default MySpin