
import React, { memo } from 'react'

interface LeftSVGProps {
    size?: number
}

const LeftSVG: React.FC<LeftSVGProps> = memo(({ size = 24 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.3334 19.334L8.66675 12.334L15.3334 5.33398" stroke="#102E24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
})

export default LeftSVG