import { http } from "../request"

export interface AuthorLoginBody {
    email: string
    password: string
}

export interface AuthorLoginResponse {
    accessToken: string
}

export const authorLoginApi = async (body: AuthorLoginBody) => {
    return await http.post<AuthorLoginResponse>("/authors/login", body)
}
