import { Button, GetProp, Row, UploadFile, UploadProps } from "antd"
import { memo, useContext, useState } from "react"
import MyButton from "../../../components/my/MyButton"
import { WorkDetailForm } from "../../../pages/work/detail"
import Dragger from "antd/es/upload/Dragger"
import getBase64 from "../../../utils/GetBase64"
import BookCover from "../../../components/book/BookCover"
import { AntdProviderContext } from "../../../providers/AntdProvider"
import { useNavigate } from "react-router-dom"

interface WorkCustomCoverProps {
    form: WorkDetailForm
    type: any
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

export const WorkCustomCover: React.FC<WorkCustomCoverProps> = memo(({ form, type }) => {
    // router
    const navigate = useNavigate()

    // responsive data
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [customCover, setCustomCover] = useState<string>('')

    // context
    const { messageApi } = useContext(AntdProviderContext)

    // method
    const handleSave = () => {
        if(fileList.length <= 0) {
            return messageApi.info('请上載檔案')
        }
        navigate("/work/detail", { 
            state: {
                type,
                form: { ...form, cover: fileList[0], subCover: customCover, patternId: 0 } as WorkDetailForm
            } 
        })
    }

    // other data
    const props: UploadProps = {
        name: 'file',
        onChange(info) {
            getBase64(info.file as FileType, (url) => {
                setCustomCover(url)
            })
            setFileList(info.fileList)
        },
        onPreview: (file: UploadFile) => {
            if (file.url || file.preview) {
                setCustomCover(file.url || (file.preview as string))
            }
            getBase64(file.originFileObj as FileType, (url) => {
                setCustomCover(url)
            })
        },
        beforeUpload: async (file: UploadFile) => {
            setFileList([file])
            return false;
        },
        fileList
    }

    return (
        <main className='w-full flex flex-col justify-between gap-10'>
            <section className='w-full flex-col items-center'>
                <Row className='w-3/5 max-w-80 rounded-lg bg-[#ebeee8] mx-auto'>
                    { type === 'add' &&  <BookCover pattern={false} coverUrl={customCover || ((form.cover || '') && form.subCover)} /> }
                    { type === 'edit' && <BookCover main_tag_id={Number(form.mainTagId)} title={form.title} coverUrl={customCover || form.subCover} /> }
                </Row>
            </section>
            <Dragger className='flex px-5' {...props} maxCount={1} showUploadList={false}>
                <section className="flex flex-col items-center gap-5 py-5">
                    <img src={`${process.env.PUBLIC_URL}/svg/upload-cloud.svg`} alt="上传图标" />
                    <div className='w-full flex justify-center'>
                        <Button type='primary' className='w-36 h-12 flex gap-2 items-center justify-center'>
                            <span>上載檔案</span>
                        </Button>
                    </div>
                </section>
            </Dragger>
            <Row className="px-5">
                <MyButton
                    type='primary'
                    onClick={() => handleSave()}
                    className='w-full h-12 text-base'
                >儲存</MyButton>
            </Row>
        </main>
    )
})