import React, { createContext } from 'react';
import { Toolbar } from '@wangeditor/editor-for-react';
import { IToolbarConfig, IDomEditor } from '@wangeditor/editor';
import { CollectibleFormModal } from '../../components/modals/collectible/form';
import MyPopover from '../../components/my/MyPopover';
import DiamondSVG from '../../components/svgs/DiamondSVG';
import { EditContext } from '../../pages/work/edit';

interface ToolbarSectionProps {
    editor: IDomEditor | null;
    toolbarConfig: Partial<IToolbarConfig>;
    selectionText: string;
    selection: any;
    createQuote: () => void;
    clickQuote: () => void;
}

const ToolbarSection: React.FC<ToolbarSectionProps> = ({ editor, toolbarConfig, selectionText, selection, createQuote, clickQuote }) => {
    return (
        <div className='flex scale-150 toolbar'>

            <Toolbar mode="default" editor={editor} defaultConfig={toolbarConfig}></Toolbar>

            {/* 点击打开藏品發佈模态框 */}
            <EditContext.Provider value={{ selectionText, selection }}>
                <CollectibleFormModal createQuote={createQuote} className='flex'>
                    <MyPopover content='創建藏品'>
                        <button 
                            onMouseDown={clickQuote}
                            className='flex items-center cursor-pointer bg-transparent border-0 outline-none p-0' 
                        >
                            <DiamondSVG className="toolbarButton"/>
                        </button>
                    </MyPopover>
                </CollectibleFormModal>
            </EditContext.Provider>
        </div>
    );
};

export default ToolbarSection;