import React, { memo, useState, useEffect, createContext, ChangeEvent, useContext } from 'react'
import { ScrollToTopLink } from '../../components/base/ScrollToTopLink'
import LeftSVG from '../../components/svgs/LeftSVG'
import InvisibleSVG from '../../components/svgs/InvisibleSVG'
import { Button, Spin, Switch } from 'antd'
import Palette from '../../views/user/Palette'
import Background from '../../views/user/Background'
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import '@wangeditor/editor/dist/css/style.css'
import { IDomEditor, IEditorConfig, IToolbarConfig } from '@wangeditor/editor'
import PlusSVG from '../../components/svgs/PlusSVG'
import VisibleSVG from '../../components/svgs/VisibleSVG'
import DiamondSVG from '../../components/svgs/DiamondSVG'
import { CollectibleFormModal } from '../../components/modals/collectible/form'
import { useNavigate, useParams } from 'react-router-dom'
import { RootState, useAppDispatch, useAppSelector } from '../../redux'
import italicToCollectible from '../../utils/ItalicToCollectible'
import { chapterCreateAsync, chapterFindUniqueAsync, chapterPublishAsync, chapterUpdateAsync, setEditChapter, setEditWork, setEditorKey, workFindUniqueAsync } from '../../redux/modules/editSlice'
import { paragraphsToHtml } from '../../utils/ParagraphsToHtml'
import { ChapterUpdateBody } from '../../api/chapter/update'
import { ChapterCreateBody } from '../../api/chapter/create'
import { ChapterVo } from '../../vo/chapter.vo'
import { WorkVo } from '../../vo/work.vo'
import useAccessToken from '../../hooks/useAccessToken'
import MyPopover from '../../components/my/MyPopover'
import { AntdProviderContext } from '../../providers/AntdProvider'
import { ViewportProviderContext } from '../../providers/ViewportProvider'
import EditTitleBar from '../../components/Edit/EditTitleBar'
import ChapterList from '../../components/Edit/ChapterList'
import EditorSection from '../../components/Edit/EditorSection'
import { BiBookContent } from "react-icons/bi";
import MobileSaveMenu from '../../components/Edit/MobileSaveMenu'
import ToolbarSection from '../../components/Edit/ToolbarSection'

type EditContextType = {
    selectionText: string;
    selection: any;
};

export const EditContext = createContext<EditContextType>({
    selectionText: "",
    selection: null
});

const Edit: React.FC = memo(() => {
    // WangEditor
    const [editor, setEditor] = useState<IDomEditor | null>(null)
    const [html, setHtml] = useState('')
    // 编辑器配置
    const editorConfig: Partial<IEditorConfig> = {
        placeholder: '请输入正文',
    }
    editorConfig.customPaste = (editor: IDomEditor, event: ClipboardEvent): boolean => {
        event.preventDefault() // 阻止默认的粘贴行为
        const text = event?.clipboardData?.getData('text/plain') // 获取粘贴的纯文本
        editor.insertText(text || "")
        return false
    }
    editorConfig.onChange = (editor: IDomEditor) => {}
    // 工具栏配置
    const toolbarConfig: Partial<IToolbarConfig> = {
        toolbarKeys: [ "undo", "redo", "underline", "bold", "italic"]
    }
    // 及时销毁 editor
    useEffect(() => {
        return () => {
            if (editor == null) return
            editor.destroy()
        }
    }, [editor])

    // redux
    const dispatch = useAppDispatch()
    const { editorKey, editWork, editWorkLoading, editChapter, editChapterLoading } = useAppSelector((state: RootState) => state.editReducer)
    const { moods } = useAppSelector((state: RootState) => state.themeReducer)
    const { user } = useAppSelector((state: RootState) => state.authReducer)
  
    // hook
    const { accessToken } = useAccessToken()

    // router
    const params = useParams()
    const navigate = useNavigate()

    // context
    const { messageApi } = useContext(AntdProviderContext)
    const { isMobile, isDesktop, notDesktop } = useContext(ViewportProviderContext);
    

    
    // responsive data
    const [selection, setSelection] = useState(editor?.selection)
    const [selectionText, setSelectionText] = useState<string>(editor?.getSelectionText() || "")
    const [chaptersVisible, setChaptersVisible] = useState<boolean>((isDesktop)?true:false)
    const [savePanelVisible, setSavePanelVisible] = useState<boolean>(false)
    const [selectedEditChapterId, setSelectedEditChapterId] = useState(0)
    const [prefixInput, setPrefixInput] = useState('')
    const [titleInput, setTitleInput] = useState('')

    // other data
    const id = Number(params.id)
    const currentMood = moods.find(mood => mood.id === user?.mood_id)
    const border = `${currentMood?.border}`

    // mounted: 根据书籍 id 获取书籍及其各个章节信息
    useEffect(() => {
        dispatch(workFindUniqueAsync({ id, accessToken })).then(action => {
            const work = action.payload as WorkVo
            if(work && work.chapters && work.chapters.length > 0) {
                setSelectedEditChapterId(work.chapters[0].id)
            }
            if (work.creator_id !== user?.id) {
                messageApi.error("你沒有編輯作品權限")
                // redirect to home
                navigate('/')
            }
        })
    }, [dispatch, id, accessToken])

    // mounted: 如果当前编辑书籍暂无章节，提供一个默认的空章节进行编辑
    useEffect(() => {
        if (editor == null) return
        if(editWork && editWork.chapters && editWork.chapters.length === 0) {
            const newEditWork: WorkVo = JSON.parse(JSON.stringify(editWork))
            newEditWork.chapters && newEditWork.chapters.push({
                id: 0,
                chapter_prefix: "",
                title: "",
                created_at: new Date().toString(),
                updated_at: new Date().toString(),
                next_chapter_id: null,
                previous_chapter_id: null,
                status: "DRAFT",
                works_id: id,
                work: editWork as WorkVo
            })
            dispatch(setEditWork(newEditWork))
            newEditWork.chapters?.length && dispatch(setEditChapter(newEditWork.chapters[0]))
        }
    }, [editor, editWork, dispatch, id])

    // mounted: 根据选中章节 id 的变化，渲染相应的章节前缀、标题、内容
    useEffect(() => {
        if (editor == null) return
        if(selectedEditChapterId !== 0) {
            dispatch(chapterFindUniqueAsync(selectedEditChapterId)).then(action => {
                const payload = action.payload as ChapterVo
                if(payload) {
                    dispatch(setEditorKey("catnip-" + selectedEditChapterId))
                    setPrefixInput(payload.chapter_prefix)
                    setTitleInput(payload.title)
                    setTimeout(() => {
                        payload.paragraphs ? setHtml(paragraphsToHtml(payload.paragraphs)) : setHtml("<p></p>")
                        const ems = document.querySelectorAll('em')
                        Array.from(ems).forEach(em => em.setAttribute("contenteditable", "false"))
                    }, 200)
                }
            })
        } else {
            dispatch(setEditorKey("catnip-0"))
            setPrefixInput("")
            setTitleInput("")
            setTimeout(() => setHtml("<p></p>"), 200)
        }
    }, [editor, selectedEditChapterId, dispatch])

    // unmounted: 卸载处理
    useEffect(() => {
        return () => {
            dispatch(setEditorKey("catnip-0"))
            dispatch(setEditWork(null))
            dispatch(setEditChapter(null))
        }
    }, [dispatch])

    // method: 新增章节进行编辑
    const handleAddEditChapter = () => {
        const newEditWork: WorkVo = JSON.parse(JSON.stringify(editWork))
        if(!newEditWork.chapters?.find(chapter => chapter.id === 0)) {
            newEditWork.chapters?.push({
                id: 0,
                chapter_prefix: "",
                title: "",
                created_at: new Date().toString(),
                updated_at: new Date().toString(),
                next_chapter_id: null,
                previous_chapter_id: null,
                status: "DRAFT",
                works_id: id,
                work: editWork as WorkVo
            })
        } else {
            return messageApi.warning("您有尚未保存的草稿哦！")
        }
        dispatch(setEditWork(newEditWork))
        setSelectedEditChapterId(0)
    }

    const handleChangeSelectedEditChapterId = (id: number) => {
        if (!id) return;
        // Set the selectedEditChapterId state
        setSelectedEditChapterId(id);
        
        if (notDesktop){
            setChaptersVisible(false);
        }
    };
    // method: 监听章节标题/前缀输入
    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
        switch(e.target.name) {
            case 'title': return setTitleInput(e.target.value)
            case 'prefix': return setPrefixInput(e.target.value)
        }
    }

    // method: 监听藏品图标的点击，缓存当前选中文本的内容和位置
    const clickQuote = () => {
        if(editor) {
            setSelection(editor.selection)
            setSelectionText(editor.getSelectionText())
        }
    }

    // method: 监听是否展示藏品样式
    const handleShowCollectible = (value: boolean) => {
        let ems = Array.from(document.querySelectorAll("em"))
        ems.forEach(em => em.style.borderWidth = value ? "3px" : "0")
    }

    // method: 感官上创建藏品
    const createQuote = () => {
        if(!editor) return
        editor.select(selection as any)
        const selectionText = editor.getSelectionText()
        const selectionPosition = JSON.parse(JSON.stringify(editor.selection))
        editor.insertText(selectionText)
        selectionPosition && editor.select(selectionPosition)
        editor.addMark("italic", true)
    }

    // function: create chapter
    const createChapter = async (): Promise<number> => {
        const body: ChapterCreateBody = {
            works_id: id,
            chapter_prefix: prefixInput,
            title: titleInput,
            paragraphs: italicToCollectible(JSON.stringify(editor?.children)),
            next_chapter_id: null,
            previous_chapter_id: 
                (editWork?.chapters?.length && editWork?.chapters?.length >= 2) 
                ? editWork.chapters[editWork.chapters.length - 2].id 
                : null
        }
        return new Promise(async resolve => {
            await dispatch(chapterCreateAsync({
                body,
                accessToken
            })).then(action => {
                const chapter = action.payload as ChapterVo
                if(chapter) {
                    setSelectedEditChapterId(chapter.id)
                    resolve(chapter.id)
                }
            })
        })
    }

    // function: update chapter
    const updateChapter = async () => {
        if(!editChapter) return messageApi.error("當前沒有正在編輯的章節")
        const body: ChapterUpdateBody = {
            id: editChapter.id,
            chapter_prefix: prefixInput,
            title: titleInput,
            paragraphs: italicToCollectible(JSON.stringify(editor?.children))
        }
        await dispatch(chapterUpdateAsync({
            body,
            accessToken
        }))
    }

    // method: 儲存為草稿
    const handleUpdateChapter = async () => {
        // validate
        if(!editWork) return messageApi.error("當前沒有正在編輯的書籍")
        if(!editChapter) return messageApi.error("當前沒有正在編輯的章節")
        if(!prefixInput) return messageApi.error("當前前綴不允許爲空")
        if(!titleInput) return messageApi.error("當前標題不允許爲空")
        const paragraphs: any = editor?.children
        const firstParagraphChildren: any = paragraphs[0].children
        const firstText = firstParagraphChildren[0].text
        if(paragraphs.length === 1 && firstParagraphChildren.length === 1 && firstText === "") {
            return messageApi.error("當前內容不允許爲空")
        }
        // 情况1：当前编辑章节为新章节（直接创建）
        if(selectedEditChapterId === 0) {
            const chapterId = await createChapter() // 创建草稿
            setSelectedEditChapterId(chapterId)
        } 
        // 情况2：当前编辑章节为草稿（直接更新）
        else if(editChapter.status === "DRAFT") {
            await updateChapter() // 更新草稿
        }
        // 情况3：当前编辑章节已發佈（直接创建）
        else if(editChapter.status === "VERIFIED") {
            return messageApi.warning("當前章節已發佈")
        }
        return messageApi.success("成功儲存為草稿")
    }

    // function: publish chapter
    const publishChapter = async (id: number) => {
        if(!editChapter) return messageApi.warning("當前沒有正在編輯的章節")
        if(editChapter.status === "DRAFT" || selectedEditChapterId === 0) {
            dispatch(chapterPublishAsync({ id, accessToken }))
        } else {
            messageApi.warning("當前章節已發佈")
        }
    }

    const handleSaveButtonOnClick = () => {
        setSavePanelVisible(!savePanelVisible)
    }

    // method: 發佈章节
    const handlePublishChapter = async () => {
        // validate
        if(!editWork) return messageApi.error("當前沒有正在編輯的書籍")
        if(!editChapter) return messageApi.warning("當前沒有正在編輯的章節")
        if(!prefixInput) return messageApi.error("當前前綴不允許爲空")
        if(!titleInput) return messageApi.error("當前標題不允許爲空")
        const paragraphs: any = editor?.children
        const firstParagraphChildren: any = paragraphs[0].children
        const firstText = firstParagraphChildren[0].text
        if(paragraphs.length === 1 && firstParagraphChildren.length === 1 && firstText === "") {
            return messageApi.error("當前內容不允許爲空")
        }
        // 情况1: 当前编辑章节为新章节 (先创建、后發佈)
        if(selectedEditChapterId === 0) {
            const chapterId = await createChapter()
            setSelectedEditChapterId(chapterId)
            await publishChapter(chapterId)
        }
        // 情况2：当前编辑章节为草稿（直接發佈）
        else if(editChapter.status === "DRAFT") {
            await publishChapter(editChapter.id)
        }
        // 情况3：当前编辑章节已發佈（直接更新）
        else if(editChapter.status === "VERIFIED") {
            await updateChapter()
        }
        return messageApi.success("成功發佈")
    }

    // Set chaptersVisible to false if isMobile is true
    useEffect(() => {
        if (isMobile) {
            setChaptersVisible(false);
        }
    }, [isMobile]);

    // Handle screen size changes
    useEffect(() => {
        const handleResize = () => {
            if (isMobile) {
                setChaptersVisible(false);
            }
            setSavePanelVisible(false);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMobile]);

    return (
        <main className='w-full flex flex-col items-stretch'>
            {/* 背景光晕 */}
            <Background />
            <EditTitleBar editWorkTitle={editWork?.title} handlePublishChapter={handlePublishChapter} handleUpdateChapter={handleUpdateChapter} handleSaveButtonOnClick = {handleSaveButtonOnClick}/>
            <div className='relative'>

                <section className={`flex items-center justify-between ${isDesktop ? "px-10" : "px-[7%]" } w-full h-16 bg-primary select-none`}>
                    <div onClick={() => setChaptersVisible(!chaptersVisible)} className={`text-white flex items-center gap-3 cursor-pointer ${isDesktop? "w-32":"w-12"}`}>
                    {isDesktop?
                        <>
                            { chaptersVisible ? <InvisibleSVG /> : <VisibleSVG /> }
                            <span  className=' whitespace-nowrap'> { chaptersVisible ? "隱藏章節顯示": "顯示章節" } </span>
                        </>
                        :
                        <BiBookContent className='text-xl' />
                    }
                    </div>
                    
                    <ToolbarSection 
                        editor={editor} 
                        toolbarConfig={toolbarConfig} 
                        selectionText={selectionText} 
                        selection={selection} 
                        createQuote={createQuote} 
                        clickQuote={clickQuote} 
                    />
                    
                    <div className={`text-white flex items-center gap-6 ${isDesktop? "w-32":"w-12"}`}>
                        {isDesktop?
                        <span className=' whitespace-nowrap'>藏品顯示</span>
                        :
                        null}
                        <Switch className='collectableSwitch' defaultValue={true} onChange={handleShowCollectible}/>
                    </div>
                    
                    
                </section>
                {isDesktop?
                <section className='w-[calc(100%-5rem)] flex flex-col justify-center items-center gap-2 py-6 border-solid border-0 border-y-[1px] border-[#d4d4cc] mb-10'>
                    <Palette />
                </section>
                :
                null}
                
                <article className={`w-full h-auto flex gap-10 ${isDesktop ? 'px-10' : 'px-5 pt-5'} justify-center`}>
                    <MobileSaveMenu 
                        savePanelVisible = {savePanelVisible}
                        setSavePanelVisible = {setSavePanelVisible}
                        handleUpdateChapter = {handleUpdateChapter}
                        handlePublishChapter = {handlePublishChapter}
                    />
                        
                    <ChapterList
                        setChaptersVisible = {setChaptersVisible}
                        chaptersVisible = {chaptersVisible} 
                        editWorkLoading = {editWorkLoading}
                        editWork = {editWork}
                        selectedEditChapterId = {selectedEditChapterId}
                        border = {border}
                        handleChangeSelectedEditChapterId = {handleChangeSelectedEditChapterId}
                        handleAddEditChapter = {handleAddEditChapter}
                    />
                    <EditorSection 
                        editorKey = {editorKey}
                        html = {html}
                        setEditor = {setEditor}
                        editChapterLoading = {editChapterLoading}
                        border = {border}
                        prefixInput = {prefixInput}
                        titleInput = {titleInput}
                        handleInput = {handleInput}
                    />
                </article>
            </div>
            
            
        </main>
    )
})

export default Edit