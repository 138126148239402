import { Input } from "antd"
import { ChangeEvent, memo, useContext } from "react"
import MyButton from "../../../my/MyButton"
import { MyTabsRef } from "../../../my/MyTabs"
import MinusSignSVG from "../../../svgs/MinusSignSVG"
import PlusSignSVG from "../../../svgs/PlusSignSVG"
import { CollectibleFormContext, CollectibleFormContextType } from "."

interface CollectibleInfoProps {
    childRef: React.RefObject<MyTabsRef> 
}

export const CollectibleInfo: React.FC<CollectibleInfoProps> = memo(({ childRef }) => {
    // context
    const { price, setPrice, count, setCount } = useContext(CollectibleFormContext) as CollectibleFormContextType
    
    // method: 藏品数量减一
    const minusCount = () => {
        if(Number(count) <= 1) return
        setCount((Number(count) - 1).toString())
    }

    // method: 藏品数量加一
    const plusCount = () => {
        setCount((Number(count) + 1).toString())
    }

    // method: 监听藏品价格输入
    const handlePriceInput = (e: ChangeEvent<HTMLInputElement>) => {
        const num = Number(e.target.value)
        if(!isNaN(num)) {
            setPrice(e.target.value)
        }
    }

    return (
        <section className='flex flex-col flex-1 justify-between w-full'>
            <article className="flex flex-col gap-3 pb-10">
                <p className='text-base text-primary m-0 p-0 whitespace-nowrap'>價錢 (HKD)</p>
                <Input 
                    value={price}
                    placeholder=">= 4 HKD"
                    onChange={handlePriceInput}
                    className="h-14 text-lg text-center"
                ></Input>
                <p className='text-base text-primary m-0 p-0'>收藏品數量</p>
                <section className='relative'>
                    <MyButton
                        disabled={Number(count) <= 1}
                        onClick={minusCount}
                        className='z-10 absolute top-1/2 left-4 -translate-y-1/2 text-base flex items-center'
                    >
                        <MinusSignSVG />
                    </MyButton>
                    <Input
                        value={count}
                        onChange={(e) => Number.isInteger(Number(e.target.value)) && Number(e.target.value) > 0 && setCount(e.target.value)}
                        className="h-14 text-lg text-center"
                    ></Input>
                    <MyButton 
                        onClick={plusCount}
                        className='z-10 absolute top-1/2 right-4 -translate-y-1/2 text-base flex items-center'
                    >
                        <PlusSignSVG />
                    </MyButton>
                </section>
            </article>
            <MyButton 
                type='primary' 
                className={`h-12 text-base`}
                onClick={() => {
                    if(!childRef.current) return
                    if(childRef.current.tabs) {
                        const ids = childRef.current.tabs.map(tab => tab.id)
                        if(childRef.current.selectedTab) {
                            const index = ids.indexOf(childRef.current.selectedTab.id)
                            const next = childRef.current.tabs[index as number + 1]
                            childRef.current.setSelectedTab(next)
                        }
                    }
                }}
            >
                繼續
            </MyButton>
        </section>
    )
})