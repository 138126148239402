import React, { memo } from 'react'

interface HaloProps {
    color: string
    width?: string
    height?: string
}

// tip: 光晕组件
const Halo: React.FC<HaloProps> = memo(({ color, width = "100%", height = "100%" }) => {
  return (
    <section 
        style={{ 
            backgroundColor: color,
            width,
            height
        }} 
        className='w-full h-full opacity-85 blur-3xl rounded-full transition duration-500'
    ></section>
  )
})

export default Halo