import React, { createContext, memo, useCallback, useContext, useEffect, useState } from 'react'
import { RootState, useAppDispatch, useAppSelector } from '../redux'
import { accountInfoAsync, authorLoginAsync, collectorLoginAsync, setIsLogin, setUser } from '../redux/featrue/authSlice'
import { useLocation, useNavigate, matchRoutes } from 'react-router-dom'
import { routes } from '../router'
import { CollectorLoginResponse } from '../api/collector/login'
import { ErrorResponse } from '../api/request'
import useAccessToken from '../hooks/useAccessToken'
import { AntdProviderContext } from './AntdProvider'

interface AuthProviderProps {
  children: JSX.Element
}

type AuthProviderContextType = { 
  login: (email: string, password: string, roleKey: "author" | "collector", isAutoLogin: boolean) => Promise<boolean>
  logout: () => void
  redirect: string
}

export const AuthProviderContext = createContext<AuthProviderContextType>({} as any)

// Provider: 管理用户令牌和身份验证操作。
const AuthProvider: React.FC<AuthProviderProps> = memo(({ children }) => {
  // router
  const navigate = useNavigate()
  const { pathname, search } = useLocation()

  // redux
  const dispatch = useAppDispatch()
  const { isLogin, user } = useAppSelector((state: RootState) => state.authReducer)

  // responsive
  const [redirect, setRedirect] = useState("/")

  // hook
  const { accessToken, setAccessToken, deleteAccessToken } = useAccessToken()

  // context
  const { messageApi } = useContext(AntdProviderContext)

  // 手动登录
  const login = useCallback(async (email: string, password: string, roleKey: "author" | "collector", isAutoLogin: boolean): Promise<boolean> => {
    let accessToken: string = ""
    switch(roleKey) {
      // 作者登录
      case "author": {
        const action = await dispatch(authorLoginAsync({ email, password }))
        accessToken = (action.payload as CollectorLoginResponse).accessToken
        break
      }
      // 藏家登录
      case "collector": {
        const action = await dispatch(collectorLoginAsync({ email, password }))
        accessToken = (action.payload as CollectorLoginResponse).accessToken
        break
      }
    }
    if(accessToken) {
      return new Promise(async resolve => {
        const action = await dispatch(accountInfoAsync(accessToken))
        resolve(action)
      }).then((action: any) => {
        const payload = action.payload
        const error = payload.error as ErrorResponse
        if(!error) {
          dispatch(setIsLogin(true))
          deleteAccessToken()
          // 判断是否自动登录
          if(isAutoLogin) {
            setAccessToken(accessToken, 30)
          } else {
            setAccessToken(accessToken)
          }
          return true
        }
        else return false
      })
    }
    else return new Promise(resolve => resolve(false))
  }, [dispatch, setAccessToken, deleteAccessToken])
  
  // 退出登录
  const logout = useCallback(() => {
    dispatch(setIsLogin(false))
    dispatch(setUser(null))
    deleteAccessToken()
  }, [dispatch, deleteAccessToken])

  // 自动登录: 根据 accessToken 获取用户信息
  useEffect(() => {
    accessToken && dispatch(accountInfoAsync(accessToken)).then(action => {
      if(!(action.payload as any).error) {
        dispatch(setIsLogin(true))
        navigate(pathname + search)
      } 
      // 可能是 token 过期导致
      else {
        deleteAccessToken()
        return messageApi.error("請重新登錄")
      }
    })
  }, [dispatch, accessToken])

  // 路由守卫：根据当前路由根结点配置判断，是否需要检查登录态
  useEffect(() => {
    const match = matchRoutes(routes, pathname)
    const root = match && match[0].route
    const meta = root && root.meta
    // 检查登录态
    if(meta?.check) {
      setRedirect(pathname)
      // 已登陆
      if(isLogin) {
        // 已登陆，但当前角色不是作者
        if(meta?.author && !user?.author) {
          // messageApi.warning("您還沒有成為作者哦~")
          return navigate(-1)
        }
        navigate(pathname)
      }
      // 未登陆
      else {
        navigate("/auth/login")
      }
    }
    if(meta?.author) {
    
    }
  }, [isLogin, pathname, messageApi, navigate, user?.author])

  return (
    <AuthProviderContext.Provider value={{ login, logout, redirect }}>
      { children }
    </AuthProviderContext.Provider>
  )
})

export default AuthProvider