import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CollectibleProps, CollectibleVo } from "../../vo/collectible.vo";
import { collectibleFindApi } from "../../api/collectible/find";

interface MoreState {
    initialCollectibles: CollectibleVo[] | null
    collectibles: CollectibleProps[] | null
    collectiblesLoading: boolean
    workTypeId: number | "all"
    workTagId: number | "all"
}

const initialState: MoreState = {
    initialCollectibles: null,
    collectibles: null,
    collectiblesLoading: true,
    workTypeId: "all",
    workTagId: "all",
}

// 获取藏品列表
export const collectibleFindAllAsync = createAsyncThunk(
    "more/collectibleFindAllAsync",
    async (accessToken: string) => {
        return await collectibleFindApi({}, accessToken)
    }
)

export const moreSlice = createSlice({
    name: "more",
    initialState,
    reducers: {
        setWorkTypeId: (state, action: PayloadAction<number | "all">) => ({ ...state, workTypeId: action.payload }),
        setWorkTagId: (state, action: PayloadAction<number | "all">) => ({ ...state, workTagId: action.payload }),
        setCollectibles: (state, action: PayloadAction<CollectibleProps[]>) => ({ ...state, collectibles: action.payload }),
        setCollectiblesLoading: (state, action: PayloadAction<boolean>) => ({ ...state, collectiblesLoading: action.payload }),
    },
    extraReducers(builder) {
        builder
        // collectibleFindAllAsync
        .addCase(collectibleFindAllAsync.fulfilled, (state, action) => {
            if(action.payload) {
                state.initialCollectibles = action.payload
            }
        })
    }
})

export const {
    setWorkTypeId,
    setWorkTagId,
    setCollectibles,
    setCollectiblesLoading,
} = moreSlice.actions