import React, { memo } from 'react'

const MinusSignSVG: React.FC = memo(() => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.6686 8.50064L2.6478 8.50064" stroke="#102E24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
})

export default MinusSignSVG

