import { Route } from './'
import { lazy, Suspense } from 'react'
import Loading from "../components/my/MySpin"
import Reset from '../pages/auth/reset'
import RegisterSuccess from '../pages/auth/success'

const Auth = lazy(() => import('../pages/auth'))
const Login = lazy(() => import('../pages/auth/login'))
const Register = lazy(() => import('../pages/auth/register'))
const Forgot = lazy(() => import('../pages/auth/forgot'))
const Verify = lazy(() => import('../pages/auth/verify'))

export const auth: Route[] = [
    { 
        path: '/auth', 
        element: (
            <Suspense fallback={<Loading />}>
                <Auth/>
            </Suspense>
        ),
        meta: { flowers: false },
        children: [
            { 
                path: 'login', 
                element: (
                    <Suspense fallback={<Loading />}>
                        <Login/>
                    </Suspense>
                )
            },
            { 
                path: 'register', 
                element: (
                    <Suspense fallback={<Loading />}>
                        <Register />
                    </Suspense>
                )
            },
            { 
                path: 'forgot', 
                element: (
                    <Suspense fallback={<Loading />}>
                        <Forgot />
                    </Suspense>
                )
            },
            { 
                path: 'reset', 
                element: (
                    <Suspense fallback={<Loading />}>
                        <Reset />
                    </Suspense>
                )
            }
        ],
    },
    { 
        path: '/auth/verify', 
        element: (
            <Suspense fallback={<Loading />}>
                <Verify />
            </Suspense>
        ),
        meta: { flowers: false }
    },
    { 
        path: '/auth/success', 
        element: (
            <Suspense fallback={<Loading />}>
                <RegisterSuccess />
            </Suspense>
        ),
        meta: { flowers: false }
    },
]