import { Route } from "."
import { lazy, Suspense } from 'react'
import Loading from "../components/my/MySpin"

const Community = lazy(() => import('../pages/community'))
const Chat = lazy(() => import('../pages/community/chat'))

export const community: Route[] = [
    { 
        path: '/community', 
        element: (
            <Suspense fallback={<Loading />}>
                <Community />
            </Suspense>
        ),
        meta: { check: true }
    },
    { 
        path: '/community/chat', 
        element: (
            <Suspense fallback={<Loading />}>
                <Chat />
            </Suspense>
        ),
        meta: { check: true }
    },
]

