import { http } from "../request"

interface WorkAddShelfResponse {
    collector_id: number
    work_id: number
}

export const workAddShelfApi = async (id: number, accessToken: string) => {
    return await http.post<WorkAddShelfResponse>(`/work/shelf/${id}`, {}, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
}