import React, { memo } from 'react'

interface FlowerThreeSVGProps {
  color: string
}

const FlowerThreeSVG: React.FC<FlowerThreeSVGProps> = memo(({ color }) => {
  return (
    <svg width="217" height="390" viewBox="0 0 217 390" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_214_44805)">
<path fillRule="evenodd" clipRule="evenodd" d="M92.7475 41.5379C92.7475 41.5379 92.7172 41.461 92.709 41.4148C92.2465 41.3696 92.0876 40.8398 91.7872 40.5532C90.7462 39.3634 89.3131 38.6337 88.1156 37.6238C85.275 35.3252 83.1371 32.3258 81.6638 29.0059C80.7561 27.2503 79.8563 25.3139 80.166 23.2869C80.2327 21.3456 82.0221 18.3321 83.9928 20.4792C85.5035 22.4616 87.8995 23.5037 89.0089 25.7723C92.3653 29.6844 93.9102 33.0354 94.108 38.2883C94.0991 39.1703 94.4098 43.3028 92.7475 41.5379ZM89.6603 37.1546C90.7395 37.9642 91.9236 38.6635 92.7998 39.7108C92.9244 35.3283 91.8209 30.7376 88.6665 27.5653C87.877 26.5628 87.3758 25.3384 86.4079 24.4851C84.9179 23.5606 83.8956 22.1166 82.5688 21.0292C78.6423 24.7646 86.1779 34.832 89.6655 37.1427L89.6603 37.1546Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M94.0385 40.6218C93.3955 40.7957 92.9416 39.9179 93.4462 39.4924C94.2263 38.7119 94.7481 37.7269 95.2594 36.7657C97.3141 33.3715 97.1942 29.3219 96.1108 25.6118C95.2545 23.5596 94.8313 21.0508 93.2722 19.423C92.5607 19.9002 92.1969 20.7342 91.7146 21.4175C91.0632 22.3745 90.0494 23.1384 89.7825 24.3193C89.5361 24.9492 90.0755 26.0698 89.1405 26.2301C88.7903 26.2691 88.459 26.0045 88.4168 25.646C88.0009 23.3318 89.9006 21.805 91.023 20.0606C93.4273 15.8971 95.3045 19.3643 96.2717 22.2514C97.3221 24.6715 98.0183 27.221 98.1401 29.868C98.5764 33.5661 96.7467 38.1355 94.0352 40.6133L94.0385 40.6218Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M93.4037 40.8938C93.1733 39.78 95.1536 39.5641 95.8064 38.9125C98.0527 37.664 100.186 36.1539 101.702 34.0772C103.329 31.3546 105.49 28.4319 104.67 25.0917C104.399 24.2012 104.352 21.9692 103.033 22.2605C101.038 23.292 99.3174 24.5707 98.4147 26.6729C98.1219 27.0842 98.2408 27.7383 97.6609 27.8972C97.2367 28.0245 96.8055 27.6314 96.8269 27.2084C97.478 24.9188 99.1123 22.9435 101.211 21.8222C105.604 18.2686 106.709 25.9288 105.88 28.8708C104.182 33.9016 100.63 38.1116 95.8278 40.4243C95.1242 40.7207 93.9694 41.9053 93.4037 40.8938Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M108.568 37.517C106.073 38.4587 103.303 38.1755 100.895 39.389C99.5484 39.8593 94.3261 41.9324 93.4153 40.948C93.0158 40.1081 94.052 39.8784 94.6584 39.8373C97.4832 39.4571 100.047 38.2121 102.73 37.3448C105.551 36.7291 110.305 36.6565 111.981 34.033C112.051 31.2216 109.136 28.8338 106.384 29.8765C106.033 29.965 105.675 29.7309 105.59 29.3892C105.599 27.9543 108.258 28.3598 109.227 28.5119C111.573 29.0503 113.249 31.6292 113.322 33.95C113.08 36.276 110.404 36.8345 108.58 37.5222L108.568 37.517Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M103.274 37.9018C103.005 36.8135 104.661 36.8534 105.235 36.2525C106.448 35.4693 107.411 34.3997 108.427 33.3884C109.197 32.7596 110.791 30.9087 111.68 32.0892C111.957 32.8688 111.009 32.9949 110.543 33.3165C109.12 34.172 104.853 39.6304 103.266 37.9052L103.274 37.9018Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M101.353 23.0873C100.969 22.8634 100.967 22.3805 101.203 22.0508C102.322 19.6956 101.17 16.891 99.9963 14.7859C99.9743 14.7551 99.9556 14.7328 99.9251 14.7053C98.1211 16.5005 96.829 18.894 96.6082 21.4487C96.463 22.0093 96.9019 22.8238 96.2154 23.1135C92.8233 23.5589 99.1242 8.94925 101.486 14.7037C102.441 16.707 103.296 19.0066 102.867 21.2581C102.648 21.9564 102.394 23.5469 101.365 23.0925L101.353 23.0873Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M85.5003 22.7768C84.2845 22.5243 85.0554 20.7405 85.2397 19.9276C85.984 18.0259 87.5539 16.54 89.2695 15.5001C91.5042 14.197 94.3657 11.1962 96.6787 14.0379C97.6993 14.6731 98.3504 18.6915 96.3895 17.7251C95.9805 17.3134 96.4191 16.7954 96.347 16.3105C96.2465 15.1753 95.0341 13.8258 93.8142 14.2166C91.6588 15.4195 89.4376 16.7568 87.6596 18.4924C85.778 20.1405 86.6441 22.8206 85.5003 22.7768Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M93.6119 61.7806C92.2493 62.3361 92.7753 54.6269 92.4972 53.4925C92.2629 49.9815 92.2472 46.4734 92.3971 42.9594C92.4665 42.2313 92.2483 41.3497 92.7523 40.7467C93.2833 40.2122 94.1769 40.9269 93.7735 41.5594C93.5548 45.7026 93.587 49.8557 93.8429 53.9998C93.9376 56.3515 94.0237 58.7066 94.2088 61.0622C94.2424 61.4241 93.9741 61.7468 93.6238 61.7858L93.6119 61.7806Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M95.3138 61.3564C93.9751 61.3694 94.6549 58.0222 94.4083 57.0432C94.3914 51.7982 94.1542 46.5707 94.074 41.3407C93.8943 40.3058 95.4956 40.0809 95.4051 41.1826C95.305 44.371 95.5928 47.5154 95.6602 50.6774C95.6476 53.2588 95.634 55.9887 95.7617 58.5248C95.6094 59.3942 96.4377 61.023 95.3019 61.3512L95.3138 61.3564Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M92.5211 76.8955C91.6326 76.9981 89.406 77.2912 89.5014 75.9506C89.9721 74.9363 91.3197 75.976 92.1688 75.6224C93.8618 75.2035 93.4346 73.0616 92.4639 72.1007C89.0194 68.6181 94.6259 68.2003 96.1013 65.9716C97.3444 64.3081 95.713 61.4156 93.6542 61.6328C92.0633 61.8339 90.9711 63.6552 89.3231 63.4346C86.7994 62.344 89.7564 59.3847 88.919 57.5817C88.8578 57.5268 88.7967 57.4718 88.7967 57.4718C86.2944 56.2346 84.6548 60.1567 85.0692 62.1655C85.9674 63.7965 82.649 64.203 83.9579 59.641C84.4795 57.047 87.9575 54.3073 90.0584 56.9362C91.0074 58.646 89.6665 60.3874 89.6181 62.1241C89.6181 62.1241 89.6248 62.1412 89.6334 62.1378C89.6334 62.1378 89.6487 62.1516 89.6606 62.1568C92.8216 60.1438 95.8528 58.9816 97.6477 63.3202C98.8276 66.5975 95.4233 68.4692 92.9894 69.6927C92.1384 70.6196 94.1406 71.5401 94.3121 72.529C95.1351 74.1698 94.4397 76.4471 92.5349 76.8802L92.5211 76.8955Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M81.5196 76.3363C75.3394 78.1346 73.9112 70.029 79.6983 68.2865C76.2501 66.7796 74.063 63.4541 77.0062 60.2337C79.7364 56.3961 84.3726 60.4467 85.4333 63.6722C85.9538 64.2672 85.146 65.1277 84.5258 64.6312C83.5246 62.5373 80.767 58.1626 78.2303 60.8086C73.8468 66.0958 81.5656 66.1999 81.5435 68.6567C81.1519 69.5708 79.8416 69.4049 79.1362 69.9982C77.1268 71.0449 76.4977 73.9181 78.7392 75.0703C80.5303 76.1824 83.107 73.4121 83.7751 74.7091C84.0174 75.828 82.2495 75.9308 81.5282 76.3329L81.5196 76.3363Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M85.1895 70.488C84.4037 70.8267 83.8924 69.5766 84.6917 69.2722C85.0664 69.1446 85.4564 69.0307 85.7908 68.8005C86.7319 68.4798 87.2723 66.6879 88.2721 67.0944C89.491 68.234 86.1899 70.2428 85.1862 70.4795L85.1895 70.488Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M96.4875 92.415C91.081 91.4313 86.4546 87.4559 84.2196 82.5006C83.3529 80.1958 82.1772 77.3314 83.0988 74.9256C84.5694 73.2122 90.419 76.8054 91.5797 78.2246C94.2027 80.9247 95.2888 84.7424 96.517 88.2179C96.646 89.1741 97.7267 90.8419 97.2217 91.5933C97.3589 92.0428 96.9429 92.4926 96.4875 92.415ZM96.0028 91.0064C94.0815 84.4866 91.8365 77.018 84.1475 75.7078C83.829 82.7134 89.3511 89.2685 96.0114 91.003L96.0028 91.0064Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M94.0673 92.892C89.2949 93.7222 83.9596 92.2663 80.6716 88.6036C79.2012 87.0002 77.0899 84.3951 78.2307 82.1696C79.3537 81.4319 80.5878 82.8617 81.6961 83.117C83.514 83.7447 85.2381 84.6363 87.0546 85.235C87.8997 85.2482 87.8761 86.5704 87.019 86.552C84.3517 85.7746 81.7623 84.542 79.2133 83.4121C80.5762 89.3914 87.5818 92.4313 93.2839 91.7292C94.2223 91.5774 95.05 91.094 95.9076 90.737C96.2414 90.6058 96.6262 90.7802 96.754 91.1051C97.1008 92.1632 94.7745 92.5547 94.0587 92.8954L94.0673 92.892Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M97.3369 92.0104C96.7913 91.0502 100.915 89.853 101.634 88.8401C106.39 84.6009 106.457 77.1314 104.143 71.6246C100.424 74.3801 97.3289 78.1934 96.2094 82.7599C95.7304 83.7281 96.3483 86.5058 95.4632 86.6169C94.7895 86.7633 94.5686 86.0506 94.6129 85.51C94.6242 79.3063 98.8304 73.6939 103.798 70.3192C106.586 69.7954 106.777 77.4693 106.919 79.3879C106.976 84.2322 104.517 89.2365 100.071 91.4488C99.2784 91.7208 97.9604 93.0683 97.3317 92.0223L97.3369 92.0104Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M113.504 90.2553C111.399 90.9842 109.176 91.0094 106.99 91.3061C104.378 91.8001 101.817 92.8763 99.1099 92.7265C96.1054 92.901 96.9418 90.203 98.2786 91.316C101.217 91.8287 104.04 90.4623 106.889 89.9935C111.834 89.7274 116.121 88.6437 119.4 84.6693C115.068 83.678 109.986 81.8361 106.201 85.2788C105.925 85.5059 105.487 85.4216 105.28 85.1475C105.055 84.8511 105.136 84.454 105.412 84.2269C107.494 82.411 110.329 81.78 113.035 81.9503C114.866 82.0099 116.571 82.7513 118.339 83.1518C119.164 83.3901 120.205 83.2474 120.853 83.8908C121.873 85.6317 115.156 90.1092 113.504 90.2553Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M122.26 74.8782C119.865 75.573 117.331 75.7894 114.977 76.6363C114.181 76.9492 113.709 75.7233 114.496 75.4137C117.201 73.9749 126.673 74.6433 123.114 69.5373C118.471 64.4135 115.135 75.1033 114.264 70.4507C114.396 68.9773 115.251 67.5846 114.994 66.0766C114.534 62.9705 109.801 62.0673 109.781 65.8857C109.914 67.6302 110.165 72.7434 107.225 70.0398C105.795 68.4895 105.215 65.8843 102.752 65.7274C100.999 65.6665 99.4141 67.4151 99.5589 69.1153C100.149 70.5911 101.994 70.6849 102.963 71.6662C103.301 74.1096 98.5414 70.7494 98.3874 69.7043C97.4864 66.8602 100.819 63.6249 103.676 64.5842C106.219 65.0453 106.831 67.7565 108.416 69.3742C108.867 67.3804 107.843 65.0287 109.331 63.3085C110.555 61.3463 113.293 61.8494 114.809 63.1682C116.621 64.736 116.611 67.297 115.824 69.393C121.108 62.3994 129.942 71.9757 122.254 74.8901L122.26 74.8782Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M120.125 83.3176C119.267 83.4972 117.098 84.1131 116.887 82.8732C117.044 81.765 118.467 82.469 119.201 82.2001C123.435 81.3056 121.038 75.9399 117.568 76.0606C115.965 76.8588 115.722 73.8545 118.917 74.9676C122.889 75.6959 124.51 81.9291 120.122 83.3091L120.125 83.3176Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M113.227 79.6241C111.609 80.0827 108.117 76.1544 109.585 74.9358C110.309 72.3805 116.078 79.0359 113.227 79.6241ZM112.556 78.2196C112.119 77.3351 111.462 76.5176 110.646 75.95C110.964 76.9107 111.721 77.6792 112.556 78.2196Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M85.0155 71.2797C83.2966 70.929 84.6507 68.1656 85.6532 67.5739C86.9731 66.206 88.1638 67.1988 87.2175 68.0842C84.5678 69.4124 86.3344 70.8895 85.0155 71.2797Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M89.0995 76.2878C88.8082 76.1753 88.4867 76.2622 88.2076 76.1054C87.7158 75.8348 87.8268 75.0113 88.3892 74.9087C89.9569 74.5983 90.5968 76.3012 89.0995 76.2878Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M97.1941 78.4546C95.9468 78.5995 96.4207 74.8792 96.0799 73.9372C96.1163 73.0245 94.2935 65.2222 96.766 67.6655C96.4435 69.2829 98.7021 78.6711 97.1941 78.4546Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M94.2863 73.5952C93.0628 73.4741 93.5748 71.5847 93.2438 70.7178C93.1543 70.0127 92.7042 68.5411 93.8565 68.5816C94.7853 68.8581 94.3152 70.3262 94.6229 71.0838C94.5941 71.8651 95.3961 73.3266 94.2863 73.5952Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M95.0802 82.8783C93.8273 82.8083 94.4066 80.5371 94.0775 79.6498C93.8416 78.0446 93.6836 76.5371 93.2509 75.0094C93.0835 73.6537 94.6511 73.8962 94.6819 75.2562C94.7672 76.3776 96.4785 82.8912 95.0802 82.8783Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M101.677 108.447C100.469 108.843 100.53 102.916 100.147 101.892C99.7919 98.5272 98.6753 95.2349 98.5779 91.9465C98.8542 91.443 99.6749 91.5448 99.8011 92.117C99.9942 93.739 100.406 95.3639 100.64 96.9895C101.144 99.7035 101.581 102.424 101.909 105.168C101.809 106.095 102.886 108.13 101.668 108.451L101.677 108.447Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M99.7327 107.426C98.4591 107.68 98.543 104.074 98.1319 103.179C97.5766 100.762 97.3069 98.2916 96.8934 95.858C96.7642 94.3985 96.002 92.8621 96.3132 91.4169C96.4859 91.1022 96.915 91.0125 97.2127 91.1915C97.706 91.4912 97.4358 92.1107 97.6088 92.5758C98.1573 95.2524 98.5554 97.9488 98.9621 100.642C99.3475 102.652 99.7756 104.646 100.216 106.644C100.304 106.995 100.078 107.35 99.7361 107.435L99.7327 107.426Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M110.724 123.44C110.365 123.433 110.066 123.126 110.082 122.764C110.191 121.181 108.459 120.293 107.216 119.745C106.46 119.332 104.174 119.599 104.848 118.198C106.489 117.069 108.902 117.325 110.388 115.852C111.969 113.74 108.926 110.978 106.743 111.382C105.459 111.709 104.474 112.748 103.555 113.653C103.233 114.292 102.248 114.551 102.08 113.67C102.189 112.492 102.522 111.226 101.962 110.103C100.937 107.219 96.6431 108.74 97.0644 111.545C96.8926 112.541 98.6252 115.088 97.0823 115.26C96.0991 115.222 95.7477 111.866 95.7477 111.036C96.3709 105.56 103.786 106.148 103.591 111.783C109.398 105.896 117.089 116.86 107.677 118.507C109.459 119.218 111.545 120.629 111.385 122.785C111.369 123.147 111.067 123.433 110.708 123.426L110.724 123.44Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M95.2402 121.106C82.0357 122.606 89.0127 106.902 96.4421 113.457C97.0747 114.136 99.3615 115.705 98.0686 116.588C97.7262 116.723 97.3262 116.535 97.2088 116.186C96.0957 114.788 94.5987 113.066 92.6603 113.137C90.4205 113.347 88.7055 115.67 89.2757 117.874C90.0837 120.281 93.2716 119.718 95.2877 119.794C96.1413 119.804 96.1006 121.133 95.2522 121.111L95.2402 121.106Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M102.214 121.426C101.065 121.67 97.9665 121.003 98.4186 119.413C96.2134 118.178 100.498 117.263 101.449 117.748C103.362 118.062 104.758 120.859 102.222 121.422L102.214 121.426ZM102.162 120.064C102.214 120.043 102.267 120.003 102.308 119.957C101.842 118.847 100.325 118.782 99.3169 119.11C99.482 119.203 99.6006 119.353 99.6421 119.534C100.343 120.009 101.327 120.353 102.162 120.064Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M105.226 135.897C104.977 135.817 104.798 135.612 104.755 135.352C99.9721 133.995 95.3151 130.821 93.674 125.918C93.1478 124.052 92.6399 121.152 94.3387 119.793C96.8334 119.504 99.4436 120.965 101.322 122.526C103.976 124.928 105.139 128.439 105.687 131.866C105.695 132.742 106.783 136.213 105.218 135.901L105.226 135.897ZM103.97 133.657C104.207 133.731 104.43 133.821 104.648 133.923C104.607 133.317 104.517 132.711 104.401 132.115C104.408 132.132 104.414 132.149 104.413 132.17C103.836 126.908 100.594 121.579 94.9084 120.991C93.0269 126.736 98.8171 132.188 103.961 133.66L103.97 133.657Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M97.4064 139.958C92.1905 141.545 86.8434 137.32 84.6157 132.836C84.1812 131.605 81.4089 125.736 84.3519 126.385C85.782 127.056 87.1433 127.804 88.6324 128.325C92.1274 129.724 95.9993 129.821 99.358 131.452C99.8993 131.999 101.256 132.157 101.107 133.084C101.005 133.43 100.632 133.636 100.294 133.532C99.3695 133.165 98.6351 132.378 97.6601 132.109C93.1876 130.837 88.474 130.183 84.3702 127.839C85.2634 133.302 89.3998 138.645 95.2247 139.059C98.7216 138.78 101.429 136.343 104.544 134.99C105.701 134.742 105.706 136.211 104.629 136.388C102.199 137.521 100.047 139.285 97.4183 139.963L97.4064 139.958Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M94.5062 128.791C91.9691 127.114 88.7521 127.678 85.876 127.802C85.5985 127.95 85.225 127.88 85.0337 127.619C84.4096 126.384 86.2807 126.418 87.039 126.387C89.1 126.376 91.2291 126.111 93.2351 126.714C93.9167 126.94 94.5444 127.305 95.1689 127.662C95.8987 128.086 95.2223 129.23 94.4942 128.786L94.5062 128.791Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M87.6185 128.262C85.774 127.24 84.2284 125.647 83.6025 123.602C83.2951 122.243 82.0873 119.045 84.1251 118.649C84.9341 118.696 85.325 119.539 85.8272 120.062C87.5833 121.84 89.3157 123.607 90.684 125.704C91.2662 126.255 90.4424 127.201 89.8156 126.687C89.4439 126.32 89.1771 125.843 88.8553 125.426C87.6227 123.473 85.7394 122.101 84.3231 120.308C84.389 122.888 85.4776 125.481 87.776 126.828C88.9127 127.181 88.8934 128.639 87.6185 128.262Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M108.786 137.035C107.925 137.157 104.799 136.974 105.207 135.649C105.353 135.315 105.736 135.184 106.072 135.309C106.913 135.639 107.832 135.89 108.74 135.711C114.759 133.63 115.322 125.837 115.509 120.384C112.406 122.996 109.507 126.002 107.751 129.703C107.181 131.043 106.764 132.47 106.551 133.916C106.659 134.239 106.486 134.603 106.169 134.727C104.791 134.993 105.315 133.109 105.52 132.347C106.717 126.723 110.999 122.483 115.223 118.877C117.236 117.286 116.828 120.546 116.777 121.672C116.714 123.75 116.56 125.844 116.04 127.865C115.123 131.69 113.019 136.19 108.774 137.03L108.786 137.035Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M113.714 137.278C112.81 137.516 106.175 138.259 108.29 136.124C109.886 136.286 111.548 136.343 113.169 136.042C119.812 134.742 122.527 129.46 125.68 124.183C123.149 125.059 120.282 124.805 117.944 126.247C117.286 126.684 116.673 127.211 116.187 127.836C115.669 128.504 114.619 127.693 115.158 127.027C115.572 126.498 116.052 126.034 116.572 125.622C118.406 124.131 120.811 123.787 123.083 123.486C124.357 123.281 128.546 121.496 127.094 124.387C123.667 130.097 120.844 135.709 113.711 137.27L113.714 137.278ZM109.072 136.379C109.049 136.319 108.991 136.273 108.936 136.235C108.982 136.276 109.032 136.326 109.072 136.379Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M123.758 123.744C123.478 122.857 124.94 122.628 125.315 121.898C126.213 120.992 125.931 120.046 125.361 119.076C124.956 117.843 126.294 117.051 126.797 116.093C127.147 115.373 127.327 114.147 126.353 113.859C123.571 112.988 121.566 117.666 119.603 116.444C118.617 115.696 119.047 114.125 118.123 113.383C115.997 112.274 113.289 114.86 114.33 117.056C114.608 118.013 115.121 119.144 114.887 120.105C114.615 120.596 113.791 120.486 113.689 119.924C113.633 119.63 113.589 119.342 113.557 119.137C113.221 117.502 112.199 115.683 113.281 114.112C114.269 112.352 116.572 111.249 118.503 112.089C119.883 112.632 119.884 114.191 120.37 115.353C122.092 114.152 123.684 112.322 125.992 112.461C129.555 112.827 128.551 116.657 126.68 118.459C126.803 119.753 127.836 120.067 126.88 121.934C126.433 122.731 124.614 125.066 123.77 123.749L123.758 123.744Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M120.715 120.658C120.072 120.832 118.766 121.128 118.827 120.078C118.879 119.732 119.229 119.466 119.578 119.526C120.306 119.467 120.994 119.206 121.648 118.909C122.389 118.48 123.039 119.656 122.288 120.059C121.783 120.307 121.256 120.525 120.707 120.661L120.715 120.658Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M113.914 162.719C113.555 162.811 113.217 162.578 113.123 162.24C112.545 160.266 112.101 158.259 111.495 156.316C109.624 151.079 108.358 145.674 107.11 140.262C106.865 139.262 106.714 138.226 106.38 137.251C106.165 136.98 106.253 136.551 106.519 136.348C108.044 135.551 108.028 139.229 108.42 140.102C109.623 145.246 110.782 150.407 112.57 155.38C113.294 157.523 113.743 159.746 114.399 161.916C114.496 162.263 114.253 162.625 113.911 162.71L113.914 162.719Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M111.813 162.578C110.731 162.717 110.902 161.09 110.566 160.462C110.172 159.235 109.707 158.026 109.322 156.795C107.652 151.342 105.812 145.935 104.65 140.34C104.617 139.376 103.052 135.747 104.238 135.547C105.367 135.103 105.48 138.958 105.863 139.755C107.05 145.488 108.922 151.03 110.634 156.615C110.779 157.713 113.203 162.268 111.804 162.581L111.813 162.578Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M113.459 185.809C108.779 186.899 108.569 181.867 109.88 178.716C108.394 182.904 100.972 185.842 99.7275 180.013C99.0182 175.722 103.27 175.126 106.26 173.96C105.015 171.774 102.066 172.766 100.031 172.392C94.6154 169.299 105.414 160.473 109.963 169.631C110.75 170.023 110.107 171.204 109.338 170.805C108.559 170.233 108.3 169.17 107.609 168.494C105.5 165.769 100.459 166.745 99.8941 170.234C99.8128 172.063 103.474 170.693 104.69 171.349C106.04 171.717 108.155 172.948 107.416 174.611C106.2 175.968 104.063 175.861 102.535 176.698C100.667 177.551 100.523 180.253 101.98 181.546C104.064 182.75 107.127 181.141 108.118 179.113C108.561 177.952 110.715 175.387 111.459 177.582C111.436 178.904 110.607 180.089 110.537 181.419C110.574 182.392 110.301 183.783 111.244 184.399C112.897 184.983 114.913 184.279 116.034 182.96C116.265 182.691 116.671 182.62 116.96 182.852C118.014 184.225 114.535 185.681 113.471 185.814L113.459 185.809Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M114.834 176.038C113.848 176.445 109.648 175.264 111.347 173.905C111.604 173.804 111.902 173.884 112.078 174.081C113.11 174.87 114.931 175.23 115.709 173.917C114.877 173.533 113.848 173.681 113.016 174.028C112.682 174.159 112.298 173.985 112.17 173.66C112.005 172.234 114.38 172.396 115.346 172.441C118.177 172.779 116.949 175.661 114.837 176.046L114.834 176.038Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M117.814 191.985C116.348 192.403 115.766 184.893 115.985 183.79C116.787 180.276 118.991 180.555 119.679 176.197C120.583 174.776 122.551 174.831 123.76 173.784C125.663 171.89 124.867 168.333 123.156 166.568C120.721 164.524 117.637 168.442 116.24 170.215C115.507 170.79 114.796 169.885 115.068 169.146C115.35 167.801 115.958 166.457 115.753 165.056C115.679 163.536 114.022 161.936 112.652 163.225C110.591 165.171 109.967 168.486 111.056 170.98C110.415 172.239 109.395 170.725 109.299 169.904C108.91 168.211 109.384 166.425 109.981 164.828C112.258 158.908 118.371 161.588 116.905 167.308C124.164 159.607 128.836 171.037 124.49 174.888C123.332 176.064 120.546 175.788 120.606 177.876C120.444 180.102 118.293 181.343 117.48 183.271C116.927 184.93 117.451 186.817 117.666 188.469C117.601 189.433 119.003 191.567 117.834 191.987L117.814 191.985Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M122.265 194.913C121.294 194.328 123.333 191.976 123.442 190.995C123.881 189.776 124.074 188.407 124.178 187.162C124.494 183.82 123.757 180.487 122.972 177.261C121.733 178.104 120.479 179.061 119.581 180.273C119.54 180.546 119.285 180.774 119.01 180.804C117.66 180.713 118.562 179.213 119.164 178.709C121.761 176.366 124.117 173.613 124.735 179.056C125.737 183.538 126.13 188.458 124.136 192.736C123.616 193.474 123.432 195.343 122.256 194.917L122.265 194.913Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M116.819 201.695C108.952 204.028 100.291 198.085 97.555 190.8C96.8943 188.315 104.893 188.201 106.637 187.811C110.623 187.596 114.989 188.219 118.009 191.05C119.441 192.254 120.857 193.493 122.22 194.774C122.621 195.317 123.529 195.69 123.518 196.415C123.849 197.332 122.562 198.005 122.088 198.685C120.633 200.136 118.729 201.023 116.826 201.712L116.819 201.695ZM117.81 199.893C119.522 199.171 121.049 198.077 122.192 196.611C119.618 194.237 117.12 191.429 113.892 190.003C109.054 188.381 103.618 189.156 98.8406 190.551C101.625 197.482 110.432 203.318 117.81 199.893Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M100.755 189.691C98.3611 188.529 96.0392 187.122 94.6125 184.801C94.0725 183.779 92.537 182.211 93.5478 181.113C94.4489 180.64 95.536 180.992 96.5135 180.865C97.9246 180.734 99.3486 180.737 100.726 181.074C101.571 181.186 101.373 182.518 100.527 182.376C98.5836 181.857 96.6021 182.271 94.6236 182.19C95.4375 183.933 96.6261 185.549 98.164 186.721C98.8814 187.515 102.037 188.229 101.622 189.359C101.473 189.684 101.08 189.839 100.758 189.699L100.755 189.691Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M109.062 184.151C108.216 183.407 104.44 183.253 105.105 181.827C105.401 180.971 108.866 182.698 109.599 182.953C110.377 183.298 109.835 184.508 109.062 184.151Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M127.897 179.48C126.628 179.595 124.41 179.459 123.913 178.095C123.892 177.738 124.172 177.421 124.529 177.399C127.817 179.354 130.635 177.673 131.002 173.906C130.994 172.379 131.678 169.948 133.641 171.171C134.337 171.608 134.521 172.503 135.191 172.95C136.373 173.393 137.572 172.271 138.272 171.413C139.485 169.949 139.297 167.713 138.13 166.276C136.729 164.399 134.101 164.573 132.263 163.519C131.265 162.312 132.455 160.64 132.303 159.249C132.354 157.343 130.575 155.131 128.526 155.699C124.645 157.038 126.899 160.732 125.455 162.287C123.926 163.224 123.745 160.629 122.735 160.069C121.033 158.932 118.687 160.733 119.41 162.67C119.66 163.884 121.013 164.31 121.564 165.258C121.7 165.807 121.006 166.277 120.554 165.932C114.283 162.021 121.015 154.842 124.671 160.394C123.976 154.833 130.606 151.614 133.224 157.268C134.03 158.965 133.359 160.828 133.179 162.557C133.824 162.866 134.627 162.846 135.303 163.133C139.547 164.051 142.184 168.947 139.071 172.49C138.046 173.703 136.244 174.875 134.635 174.126C133.79 173.935 133.231 172.013 132.622 172.223C132.157 173.679 132.314 175.335 131.553 176.74C130.846 178.133 129.521 179.365 127.902 179.469L127.897 179.48Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M130.338 169.657C128.886 170.061 126.164 167.814 127.039 166.344C126.77 165.434 128.035 165.133 128.71 165.066C131.196 164.828 133.14 168.566 130.338 169.657ZM129.98 168.396C131.36 167.508 129.129 165.779 128.12 166.581C128.144 166.641 128.159 166.704 128.165 166.77C128.165 166.82 128.165 166.869 128.161 166.91C128.524 167.607 129.156 168.335 129.989 168.393L129.98 168.396Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M123.903 196.44C123.102 196.715 122.67 195.464 123.474 195.197C123.929 195.048 124.381 194.89 124.814 194.71C130.992 192.655 134.597 186.519 133.775 180.13C131.469 183.06 128.147 185.067 126.242 188.314C125.986 188.77 125.714 189.232 125.598 189.742C125.587 190.092 125.344 190.404 124.979 190.43C123.367 190.175 124.975 187.933 125.407 187.171C126.637 185.098 128.554 183.614 130.266 181.964C131.342 181.205 133.065 178.149 134.294 178.485C135.639 179.318 135.015 182.002 135.076 183.389C134.991 185.486 134.344 187.507 133.318 189.322C131.376 192.929 127.711 195.268 123.886 196.446L123.903 196.44Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M126.491 196.311C125.65 196.385 123.795 197.095 123.653 195.828C123.686 195.361 124.147 195.15 124.573 195.229C128.205 194.917 131.68 192.998 134.609 190.948C138.266 188.463 140.583 184.453 140.755 180.041C139.348 180.407 137.968 180.92 136.699 181.636C135.961 181.897 135.254 183.29 134.444 182.562C133.783 181.559 135.624 180.864 136.271 180.373C137.601 179.811 142.417 177.029 142.061 180.17C141.783 182.855 141.219 185.594 139.58 187.808C136.463 192.144 131.515 194.839 126.487 196.303L126.491 196.311Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M140.418 244.823C137.452 240.096 135.477 234.594 133.081 229.505C129.761 221.817 126.507 214.094 123.987 206.102C123.236 203.337 121.797 200.606 121.62 197.744C122.11 196.88 123.013 197.492 122.949 198.336C126.508 211.004 131.609 223.174 137.038 235.146C138.185 237.587 139.281 240.048 140.489 242.465C140.865 243.196 142.102 245.01 140.413 244.835L140.418 244.823Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M146.42 251.27C142.364 243.694 138.313 236.106 135.083 228.117C131.209 217.863 127.543 207.558 124.056 197.182C123.479 196.293 124.445 195.232 125.124 196.229C125.322 196.733 125.46 197.261 125.633 197.776C130.038 210.21 133.962 222.852 139.496 234.842C141.648 239.662 144.198 244.286 146.704 248.927C147.027 249.521 147.332 250.121 147.632 250.734C147.978 251.487 146.779 252.057 146.429 251.266L146.42 251.27Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M150.672 259.646C150.325 259.743 149.959 259.541 149.862 259.194C149.698 258.627 149.546 258.065 149.408 257.487C147.648 255.297 147.2 252.097 146.349 249.43C144.953 244.471 144.764 239.294 145.431 234.204C146.777 224.86 147.952 215.484 149.55 206.179C150.537 200.924 155.804 171.321 163.469 186.115C166.857 198.475 155.804 220.175 152.107 232.586C150.321 238.372 149.044 244.472 149.576 250.551C149.715 252.135 149.929 253.709 150.239 255.176C150.206 256.275 151.834 259.208 150.681 259.642L150.672 259.646ZM148.366 251.796C146.994 239.937 152.044 228.682 156.067 217.802C158.621 209.848 166.513 191.217 160.879 184.252C156.731 180.187 153.865 193.505 153.067 196.099C150.882 204.539 149.696 213.159 148.572 221.785C146.924 231.692 144.855 242.062 148.363 251.788L148.366 251.796Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M149.851 261.724C143.842 251.244 132.111 246.163 121.454 241.627C114.538 238.562 108.138 234.524 101.868 230.316C96.8363 227.042 92.2567 222.96 88.8813 217.969C86.4877 213.54 84.1225 208.656 84.016 203.535C85.2227 196.476 91.891 204.614 94.1392 206.889C98.0543 210.74 101.672 214.914 105.957 218.372C111.735 223.366 118.508 226.971 124.971 230.945C133.543 235.807 140.979 242.755 146.455 250.927C147.033 252.122 151.249 256.712 150.402 257.421C149.281 258.316 148.409 255.569 147.712 254.954C145.542 251.948 143.507 248.859 141.145 246.017C134.209 237.55 124.699 232.187 115.426 226.722C109.093 222.855 103.14 218.295 98.156 212.782C95.931 210.617 93.7507 208.414 91.6063 206.177C90.0549 204.695 88.6649 202.793 86.5744 202.075C86.2623 202.01 85.7219 202.144 85.6073 202.455C84.7636 204.959 85.9231 207.681 86.6954 210.073C88.1794 214.425 90.3395 218.511 93.6043 221.788C97.113 225.66 101.48 228.573 105.843 231.378C110.765 234.646 115.851 237.631 121.216 240.092C131.466 244.521 142.652 249.146 149.339 258.539C149.75 259.484 151.687 261.091 150.739 261.948C150.437 262.135 150.025 262.041 149.833 261.731L149.851 261.724Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M133.913 231.448C131.426 227.638 129.477 223.459 126.586 219.926C124.368 217.403 121.747 215.008 118.587 213.832C111.621 212.149 116.618 219.305 118.966 221.432C121.65 224.335 123.823 227.627 125.909 230.972C126.366 231.681 125.224 232.396 124.787 231.66C123.036 228.943 121.368 226.183 119.237 223.754C117.108 221.027 113.651 218.468 113.695 214.709C115.583 209.209 122.415 214.044 125.103 216.482C127.879 219.043 129.999 222.197 131.839 225.472C132.907 227.283 133.97 229.106 135.094 230.885C135.424 231.624 134.292 232.187 133.916 231.457L133.913 231.448Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M192.628 338.102C174.506 315.435 162.049 289.031 150.197 262.734C149.865 261.739 147.913 259.387 149.538 258.995C149.889 258.985 150.154 259.256 150.2 259.574C150.457 260.48 150.975 261.293 151.369 262.145C159.355 279.715 167.436 297.327 177.415 313.887C181.484 320.539 185.919 326.969 190.512 333.248C191.525 334.617 192.574 335.952 193.648 337.276C194.173 337.909 193.174 338.786 192.628 338.102Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M190.638 332.133C178.687 316.467 169.599 298.58 161.232 280.745C157.329 273.03 153.238 265.389 149.891 257.406C149.669 256.842 149.092 256.052 149.849 255.666C150.179 255.526 150.575 255.676 150.714 256.006C151.356 257.462 151.98 258.924 152.625 260.388C156.13 268.348 160.429 275.938 164.159 283.79C167.899 292.348 172.426 300.519 176.933 308.687C180.405 314.755 184.065 320.72 188.071 326.46C188.841 327.588 189.616 328.705 190.408 329.815C190.892 330.493 192.304 332.1 190.629 332.137L190.638 332.133Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M210.795 359.171C200.996 349.53 193.662 337.715 187.278 325.625C184.173 319.186 181.198 312.675 178.446 306.077C174.349 294.352 171.799 282.087 170.795 269.708C169.619 253.725 168.407 237.598 169.915 221.605C170.633 214.511 170.714 207.253 172.949 200.422C173.937 196.677 182.167 173.906 185.51 185.7C186.823 192.104 186.083 198.742 185.403 205.18C184.438 213.733 183.435 222.291 183.242 230.896C182.335 244.598 182.263 258.388 183.069 272.098C183.922 280.499 186 288.724 187.632 296.997C191.469 319.999 198.355 339.196 211.783 358.318C212.241 358.928 211.344 359.715 210.795 359.171ZM202.579 347.851C203.163 348.608 203.761 349.351 204.368 350.089C205.071 350.948 205.774 351.807 206.491 352.65C195.814 336.276 189.776 317.427 186.54 298.265C184.858 289.539 182.591 280.884 181.743 272.017C180.651 254.54 181.377 237.009 182.537 219.554C183.338 208.548 186.037 197.418 184.312 186.398C184.066 185.419 183.8 184.142 182.916 183.601C182.408 183.742 182.092 184.27 181.742 184.635C178.337 188.945 176.421 194.2 174.653 199.328C172.599 205.061 172.129 211.129 171.602 217.151C169.875 232.134 170.584 247.205 171.516 262.218C172.318 276.849 174.692 291.404 179.535 305.254C185.657 320.221 192.86 334.871 202.582 347.86L202.579 347.851Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M197.979 344.873C187.813 334.952 174.951 328.025 161.445 323.809C148.245 319.464 134.211 317.272 122.055 310.256C110.663 303.324 100.337 294.275 93.4606 282.744C90.9642 278.632 88.9056 274.15 87.7942 269.464C87.3471 267.221 87.4304 262.055 90.9875 263.914C95.693 267.236 99.1668 272.05 103.962 275.298C109.53 279.307 115.392 282.955 121.545 286.014C125.968 287.888 130.447 289.631 134.868 291.526C143.534 295.798 151.849 300.811 160.301 305.494C165.436 308.677 170.097 312.589 174.614 316.588C178.741 320.651 182.283 325.261 186.18 329.543C189.3 332.778 192.469 335.964 195.653 339.134C196.281 339.776 198.086 341.05 196.612 341.65C197.134 342.372 199.692 343.953 198.92 344.829C198.683 345.08 198.247 345.103 197.992 344.858L197.979 344.873ZM165.961 323.959C172.183 326.162 178.234 328.936 183.9 332.314C187.683 334.697 191.174 337.519 194.786 340.146C191.141 336.506 187.465 332.887 183.978 329.095C180.106 324.725 176.549 320.003 172.139 316.15C168.399 312.971 164.606 309.832 160.49 307.157C155.301 303.985 149.823 301.312 144.631 298.161C141.53 296.507 138.485 294.743 135.339 293.177C129.879 290.674 124.126 288.81 118.777 286.086C110.146 281.375 101.663 276.113 94.8286 268.958C93.459 267.888 91.0635 264.585 89.3794 264.951C87.9874 268.598 90.1075 272.532 91.4216 275.925C98.5801 291.718 112.376 304.111 127.776 311.688C139.958 317.313 153.419 319.305 165.966 323.947L165.961 323.959Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M208.451 355.295C201.968 341.22 204.764 330.614 203.863 316.033C203.405 311.277 202.479 306.558 202.375 301.771C202.287 294.61 202.872 287.479 203.634 280.369C204.42 272.014 204.421 263.574 206.301 255.362C207.859 246.635 211.49 238.504 215.163 230.505C209.481 235.78 205.838 242.75 202.464 249.625C195.753 264.85 194.169 281.681 194.323 298.156C194.79 310.351 195.005 322.734 198.272 334.559C198.482 334.97 198.723 335.507 198.221 335.813C196.821 336.274 196.884 333.92 196.527 333.063C195.646 329.59 194.987 326.206 194.539 322.68C194.02 317.741 193.771 312.785 193.389 307.842C192.233 286.332 193.292 263.567 203.732 244.23C206.001 240.05 208.382 235.876 211.594 232.333C212.698 231.346 214.952 227.933 216.402 228.755C217.793 229.955 213.891 235.99 213.34 237.678C210.407 244.32 208.267 251.341 207.027 258.492C205.676 265.558 205.697 272.776 204.99 279.924C204.4 285.961 203.751 292.001 203.706 298.069C203.423 303.857 204.454 309.572 205.105 315.298C205.44 318.838 205.365 322.392 205.389 325.946C205.406 332.8 204.811 339.757 206.744 346.43C207.474 349.292 208.508 352.074 209.647 354.795C209.994 355.527 208.78 356.133 208.443 355.327L208.451 355.295Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M91.9584 39.9312C90.6926 37.5663 88.9346 35.533 87.2279 33.4795C84.1411 29.8759 82.0381 25.9349 81.8307 21.1103C81.8408 21.1359 81.8423 21.165 81.8524 21.1906C81.7149 20.7906 82.3021 20.5498 82.4601 20.9517C82.556 21.7234 82.5887 22.5102 82.7427 23.2788C82.9829 24.7441 83.4367 26.1747 84.0498 27.5328C85.0647 29.6115 86.419 31.4975 87.9123 33.2597C89.5936 35.2738 91.2953 37.2898 92.541 39.6035C92.7436 39.968 92.1849 40.3062 91.967 39.9279L91.9584 39.9312Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M93.5636 39.9916C93.5064 39.8462 93.5483 39.7014 93.5935 39.5652C93.7604 39.085 93.9392 38.6099 94.0942 38.1245C94.498 36.8897 94.8196 35.6972 94.9181 34.4146C95.1168 32.3819 94.7401 30.3682 94.4571 28.367C94.2723 27.0675 94.0856 25.7885 93.8628 24.5435C93.6984 23.5222 93.4383 22.509 93.3748 21.4679C93.3568 21.0702 93.3626 20.6829 93.3975 20.2941C93.4822 19.8561 94.1353 19.9842 94.0454 20.4342C93.9207 21.8258 94.3167 23.21 94.5303 24.5574C94.8793 26.6511 95.1787 28.7446 95.4644 30.8534C95.9093 33.7188 95.4183 36.6167 94.3806 39.3051C94.3231 39.4856 94.2537 39.661 94.1962 39.8415C94.2124 39.9832 94.1296 40.1244 93.9927 40.1782C93.8301 40.2422 93.6308 40.1626 93.5636 39.9916Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M93.8526 20.0462C93.499 20.0767 93.4654 19.7148 93.4773 19.4436C93.4196 19.1208 93.7118 18.8084 94.0229 19.0217C94.1504 19.1197 94.1852 19.2837 94.1314 19.4233C94.1589 19.6691 94.1653 20.0121 93.8526 20.0462Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M97.9611 25.8433C97.914 25.7236 97.9422 25.5941 98.0247 25.5025C98.3322 24.9275 98.5648 24.3128 98.7803 23.7049C99.2844 22.2727 99.613 20.7714 99.6464 19.2479C99.6816 17.704 99.3702 16.1581 99.5864 14.622C99.6573 14.1993 100.297 14.2932 100.238 14.7212C100.068 15.9726 100.265 17.2278 100.302 18.4769C100.374 20.2449 100.048 22.0022 99.4891 23.673C99.244 24.3814 98.9716 25.0709 98.6396 25.7345C98.5336 26.0427 98.1213 26.1752 97.9663 25.8314L97.9611 25.8433Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M93.9225 39.5736C93.7981 39.2573 94.1318 39.126 94.3596 39.0266C95.7197 38.3141 97.0517 37.4547 98.1258 36.3414C99.2338 34.9877 99.9812 33.4204 100.681 31.8323C101.624 29.7833 102.576 27.7308 103.317 25.5936C103.603 24.8394 103.75 24.0314 103.719 23.2242C103.689 23.0483 103.819 22.8494 104.013 22.8419C104.184 22.8241 104.366 22.9599 104.375 23.134C104.412 23.8303 104.324 24.5362 104.144 25.2092C103.49 27.1643 102.708 29.0709 101.819 30.9309C100.751 33.2165 99.8558 35.7401 97.9225 37.4578C96.9683 38.2476 95.9223 38.9551 94.8259 39.5342C94.5502 39.6624 94.1129 39.9824 93.9225 39.5736Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M99.2339 39.0837C98.9637 39.1504 98.692 39.1882 98.4319 39.2806C98.2794 39.3701 98.0749 39.3025 97.9854 39.1501C97.8925 38.9892 97.9551 38.7968 98.1162 38.7038C98.6639 38.4884 99.2755 38.4355 99.83 38.2373C100.84 37.8897 101.862 37.3988 102.688 36.7089C104.116 35.4167 105.202 33.8052 106.627 32.5044C107.698 31.6095 108.828 30.6619 110.196 30.2719C110.384 30.1979 110.602 30.2998 110.642 30.5013C110.709 30.7713 110.457 30.9098 110.226 30.9512C109.112 31.3102 108.175 32.0932 107.272 32.8134C104.381 35.4608 103.488 38.092 99.2425 39.0803L99.2339 39.0837Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M96.1458 91.721C94.359 90.7454 92.9736 89.2073 91.6444 87.6865C90.2287 86.0715 88.7755 84.4613 87.6309 82.631C87.0169 81.6977 86.5759 80.6767 86.0408 79.6926C85.2703 78.3865 84.4368 77.046 83.3197 76.0144C83.1021 75.863 82.9197 75.5498 83.1856 75.3465C83.5029 75.123 83.7223 75.5303 83.9466 75.6988C85.5808 77.3168 86.6368 79.3991 87.6725 81.4301C88.4763 82.8711 89.4772 84.1853 90.5451 85.4436C91.771 86.8273 92.9367 88.284 94.2845 89.5507C94.952 90.1174 95.6125 90.7165 96.4056 91.1253C96.7904 91.2997 96.5425 91.9007 96.1406 91.7329L96.1458 91.721Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M95.0857 91.6625C90.9378 91.7436 86.3473 90.2219 83.3261 87.3131C82.3252 86.2753 81.7241 84.9224 80.6562 83.9405C80.3695 83.6387 80.8197 83.1754 81.1269 83.4791C82.3204 84.5793 82.9561 86.1457 84.1771 87.2155C86.8401 89.5148 90.2853 90.6873 93.7513 90.9828C94.1284 91.0122 94.5123 91.0587 94.895 91.0267C95.0781 90.9646 95.2789 91.0732 95.3238 91.2628C95.362 91.4354 95.255 91.6156 95.0857 91.6625Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M96.7827 91.379C96.7188 91.2166 96.802 91.026 96.9612 90.9535C97.984 90.1367 98.9064 89.241 99.8087 88.2939C101.026 86.8674 101.727 85.0814 102.438 83.3706C103.334 80.6491 103.939 77.815 104.013 74.9626C103.994 73.9336 104.103 72.8542 103.667 71.9202C103.559 71.7456 103.536 71.4878 103.749 71.3747C104.449 71.0202 104.654 73.0722 104.656 73.5056C104.812 77.0179 104.112 80.5407 102.973 83.8513C102.297 85.4497 101.621 87.0976 100.54 88.4702C99.8521 89.2837 99.0656 89.9977 98.2825 90.7202C97.931 90.9571 97.1339 92.0208 96.8032 91.3809L96.7827 91.379Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M117.751 85.198C116.567 85.3775 115.374 85.4613 114.197 85.6578C112.116 86.0217 110.215 86.9667 108.355 87.9153C107.098 88.5379 105.932 89.3419 104.7 90.0039C103.505 90.5826 102.208 90.9542 100.929 91.319C100.306 91.4948 99.6819 91.6414 99.0661 91.8343C98.736 91.9246 98.4309 92.1037 98.0929 92.0985C97.6818 92.0331 97.7539 91.4124 98.1721 91.4454C98.1516 91.4436 98.1225 91.4451 98.1021 91.4433C98.1277 91.4332 98.2115 91.42 98.2029 91.4234C100.166 90.7603 102.222 90.3861 104.117 89.552C107.792 87.5146 111.46 85.0558 115.773 84.7912C116.41 84.7282 117.037 84.6396 117.673 84.5475C117.853 84.5263 118.017 84.6689 118.035 84.8396C118.056 85.0189 117.91 85.175 117.742 85.2014L117.751 85.198Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M119.641 85.0792C119.113 85.1684 118.569 85.1159 118.039 85.1761C117.856 85.1888 117.698 85.0633 117.677 84.8841C117.665 84.3755 118.332 84.5379 118.667 84.4852C118.962 84.4578 119.262 84.5175 119.539 84.4183C119.711 84.351 119.901 84.434 119.969 84.605C120.063 84.8444 119.863 85.0412 119.633 85.0826L119.641 85.0792Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M103.739 136.463C101.683 136.738 99.6271 137.063 97.5551 137.197C96.1531 137.225 94.7664 136.99 93.439 136.555C89.8116 135.572 87.84 132.468 85.7251 129.627C85.2507 129.024 84.8015 128.51 84.2015 128.065C84.0808 127.984 83.9084 127.973 83.8531 127.807C83.8161 127.713 83.8219 127.602 83.8754 127.512C84.4901 126.688 86.631 129.745 87.0069 130.249C88.4089 132.156 89.8594 134.211 92.0245 135.294C93.8104 136.142 95.7659 136.617 97.7419 136.541C98.7667 136.483 99.7701 136.296 100.79 136.201C101.75 136.08 102.699 135.954 103.661 135.813C104.078 135.767 104.161 136.406 103.73 136.467L103.739 136.463Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M105.209 135.45C103.154 133.642 102.172 130.968 100.889 128.609C99.2714 125.879 97.3053 123.266 94.8025 121.299C94.4712 121.034 94.8603 120.516 95.2121 120.782C97.6437 122.669 99.52 125.179 101.167 127.76C102.138 129.451 102.883 131.27 103.945 132.916C104.433 133.603 104.92 134.339 105.552 134.89C105.912 135.104 105.593 135.674 105.212 135.458L105.209 135.45Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M106.374 135.297C106.17 135.357 105.909 135.42 105.753 135.225C105.641 135.091 105.682 134.868 105.819 134.765C107.401 134.39 108.715 133.359 109.62 132.017C111.125 129.865 111.879 127.357 112.793 124.935C112.793 124.935 112.793 124.935 112.788 124.946C113.353 123.392 113.73 121.763 114.446 120.267C114.621 119.882 115.222 120.13 115.051 120.523C114.335 122.019 113.976 123.62 113.41 125.146C111.944 128.931 110.699 133.882 106.381 135.264L106.374 135.297Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M111.861 134.829C111.471 134.943 111.255 134.366 111.648 134.212C111.84 134.146 112.042 134.057 112.193 133.988C114.356 132.831 115.908 130.898 117.903 129.511C120.32 127.643 123.15 126.521 125.499 124.58C125.832 124.321 126.244 124.84 125.894 125.106C123.584 127.021 120.766 128.149 118.39 129.971C117.114 130.898 115.956 131.975 114.763 132.987C113.888 133.726 112.955 134.468 111.861 134.829Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M121.719 194.929C121.539 194.901 121.423 194.73 121.443 194.554C121.487 194.063 121.563 193.579 121.607 193.088C121.903 190.296 121.666 187.457 121.572 184.681C121.556 182.93 121.72 181.187 121.958 179.455C122.109 178.833 122.131 176.979 122.725 176.755C123.084 176.614 123.344 177.124 123.023 177.339C122.522 179.382 122.347 181.524 122.245 183.627C122.184 185.734 122.429 187.789 122.414 189.888C122.409 191.232 122.338 192.583 122.184 193.927C122.091 194.269 122.253 194.956 121.722 194.938L121.719 194.929Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M106.518 188.439C104.104 187.098 101.406 186.47 98.919 185.296C97.668 184.653 96.5017 183.848 95.4076 182.975C95.1086 182.718 94.755 182.472 94.59 182.102C94.4338 181.68 95.0806 181.465 95.2111 181.898C96.6436 183.279 98.3613 184.38 100.19 185.162C102.425 186.02 104.671 186.707 106.778 187.843C107.163 188.017 106.915 188.618 106.513 188.451L106.518 188.439Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M118.411 199.765C115.615 200.845 112.552 200.016 110.035 198.616C108.786 197.953 107.525 197.284 106.393 196.416C105.297 195.563 104.261 194.638 103.176 193.762C101.746 192.537 100.145 191.508 98.4373 190.758C98.3083 190.631 98.3148 190.422 98.4419 190.293C98.5553 190.179 98.7243 190.182 98.8026 190.23C99.0853 190.346 99.3799 190.467 99.6523 190.606C102.565 192.008 104.775 194.436 107.36 196.312C108.37 196.971 109.483 197.59 110.537 198.133C111.849 198.782 113.168 199.398 114.605 199.534C117.156 199.864 119.87 198.826 121.554 196.901C121.819 196.569 122.341 196.966 122.07 197.309C121.129 198.459 119.767 199.242 118.408 199.756L118.411 199.765Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M122.64 196.393C122.543 196.047 122.859 195.745 123.04 195.476C124.333 193.813 126.336 192.877 127.82 191.425C130.438 188.707 132.494 184.74 133.4 181.087C133.514 180.775 133.416 180.172 133.892 180.202C134.071 180.231 134.196 180.398 134.168 180.577C133.522 184.01 131.88 187.173 129.883 190.011C128.704 191.788 126.986 192.997 125.29 194.236C124.595 194.806 123.929 195.324 123.421 196.067C123.274 196.223 123.292 196.493 123.078 196.577C122.906 196.644 122.716 196.561 122.648 196.39L122.64 196.393Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M130.938 191.888C130.821 191.767 130.803 191.546 130.942 191.423C131.591 190.861 132.376 190.345 133.008 189.791C135.993 187.028 139.254 184.087 140.36 180.04C140.478 179.638 141.107 179.805 140.995 180.224C140.061 183.652 137.635 186.353 135.101 188.731C134.142 189.71 133.116 190.617 131.999 191.422C131.653 191.597 131.318 192.203 130.946 191.885L130.938 191.888Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M97.6614 218.941C95.7536 217.105 93.7398 215.3 92.2322 213.099C89.9253 209.545 88.2481 205.505 85.262 202.435C85.1704 202.352 85.0493 202.321 85.0023 202.201C84.9384 202.039 85.0062 201.834 85.1774 201.767C85.3914 201.683 85.5799 201.836 85.7294 201.965C89.0076 205.276 90.6478 209.774 93.348 213.5C94.6201 215.152 96.1565 216.571 97.6535 218.016C97.8739 218.275 98.4442 218.544 98.1293 218.925C98.0108 219.05 97.7956 219.056 97.67 218.937L97.6614 218.941Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M105.41 225.528C103.577 224.057 101.647 222.694 99.9929 221.024C99.7337 220.692 100.211 220.297 100.498 220.599C101.914 222.016 103.543 223.192 105.094 224.448C105.342 224.726 106.154 225.008 105.866 225.457C105.763 225.596 105.553 225.64 105.41 225.528Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M148.594 257.728C147.573 256.412 146.651 254.948 145.527 253.722C142.896 250.827 140.031 248.163 137.028 245.652C134.955 243.95 132.866 242.254 130.709 240.664C125.752 237.154 120.433 234.181 114.997 231.491C113.598 230.748 112.241 229.91 110.901 229.065C110.409 228.745 109.899 228.452 109.47 228.038C109.19 227.753 109.58 227.264 109.925 227.563C110.244 227.872 110.656 228.115 110.991 228.338C112.588 229.359 114.212 230.349 115.897 231.217C120.769 233.605 125.485 236.301 129.972 239.344C134.367 242.453 138.524 245.913 142.459 249.588C144.257 251.447 146.17 253.222 147.673 255.336C148.151 255.997 148.602 256.668 149.107 257.299C149.214 257.445 149.196 257.649 149.053 257.764C148.919 257.876 148.703 257.853 148.587 257.711L148.594 257.728Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M149.336 258.936C147.527 256.646 145.377 254.698 143.191 252.783C139.971 249.745 136.323 247.251 132.786 244.614C129.018 241.969 124.865 239.95 121.011 237.438C119.145 236.335 117.199 235.383 115.245 234.433C113.119 233.473 110.964 232.543 108.981 231.319C106.261 229.704 103.641 227.891 101.205 225.867C99.3804 224.393 97.5197 222.953 95.9099 221.247C93.7314 219.023 91.8533 216.534 90.1757 213.926C89.3353 212.618 88.5 211.298 87.8719 209.876C87.4221 208.908 84.8821 203.027 85.9396 202.927C86.119 202.905 86.2856 203.027 86.3067 203.207C86.4069 203.839 86.5127 204.409 86.6813 205.014C87.42 207.32 88.3518 209.589 89.5544 211.693C91.6736 215.223 94.0875 218.597 97.0457 221.471C98.1827 222.604 99.4263 223.605 100.678 224.604C103.593 227.041 106.684 229.271 109.995 231.158C112.901 232.818 116.059 233.965 118.986 235.579C121.798 237 124.351 238.869 127.133 240.341C129.199 241.523 131.262 242.726 133.202 244.115C134.489 245.05 135.76 246.021 137.05 246.965C139.61 248.752 141.98 250.782 144.28 252.888C146.288 254.626 148.222 256.481 149.873 258.567C149.971 258.716 149.906 258.928 149.762 259.015C149.61 259.104 149.424 259.059 149.317 258.913L149.327 258.939L149.336 258.936Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M98.2994 215.738C96.2685 213.664 94.2394 211.57 92.5792 209.182C90.6761 206.604 88.8533 203.727 85.8691 202.295C85.59 202.138 85.2181 202.096 85.0267 201.836C84.9372 201.684 84.9846 201.478 85.1371 201.388C85.2639 201.308 85.4345 201.34 85.5415 201.436C89.66 203.065 91.7804 207.226 94.4082 210.516C95.6753 212.131 97.1313 213.572 98.5427 215.05C98.7295 215.224 98.9994 215.483 98.7622 215.734C98.6436 215.86 98.4198 215.869 98.3028 215.747L98.2994 215.738Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M136.323 239.512C134.337 237.451 132.858 234.972 131.289 232.588C129.001 229.183 126.99 225.601 124.517 222.329C122.77 219.995 120.665 217.96 118.406 216.134C117.585 215.479 116.709 214.886 115.815 214.319C115.606 214.165 115.27 214.04 115.26 213.738C115.247 213.378 115.752 213.229 115.901 213.584C118.922 215.486 121.648 217.87 123.968 220.58C127.159 224.449 129.544 228.881 132.356 233.017C133.341 234.515 134.303 236.032 135.406 237.455C135.777 237.921 136.182 238.374 136.55 238.782C136.735 238.975 137.042 239.23 136.791 239.496C136.673 239.622 136.449 239.631 136.332 239.509L136.323 239.512Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M138.721 241.916C137.248 240.708 135.903 239.322 134.353 238.194C132.623 236.91 130.996 235.487 129.657 233.792C127.685 231.29 126.271 228.421 124.602 225.711C123.769 224.37 122.76 223.138 121.778 221.895C121.663 221.753 121.69 221.545 121.832 221.43C121.974 221.314 122.183 221.341 122.298 221.483C122.762 222.061 123.221 222.65 123.68 223.24C126.131 226.383 127.631 230.146 130.096 233.274C130.972 234.371 131.99 235.451 133.046 236.329C135.046 237.872 137.019 239.446 138.849 241.185C139.042 241.325 139.32 241.503 139.171 241.778C139.092 241.928 138.874 242.004 138.726 241.904L138.721 241.916Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M136.544 238.664C134.572 235.106 132.899 231.38 130.727 227.941C130.515 227.579 131.062 227.236 131.294 227.599C133.074 230.443 134.561 233.471 136.107 236.447C136.434 237.078 136.744 237.716 137.111 238.322C137.326 238.692 136.775 239.027 136.552 238.66L136.544 238.664Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M128.677 224.691C128.584 224.678 128.517 224.635 128.461 224.569C128.17 224.229 127.945 223.834 127.69 223.46C127.591 223.311 127.656 223.098 127.8 223.012C128.269 222.798 128.425 223.497 128.666 223.758C128.761 223.898 128.856 224.038 128.973 224.16C129.164 224.371 128.954 224.74 128.677 224.691Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M148.31 253.159C147.343 253.539 147.447 248.602 147.399 247.95C147.347 245.305 147.575 242.668 147.912 240.058C148.334 237.059 148.767 234.065 149.404 231.11C150.126 228.122 150.974 225.152 151.909 222.228C152.938 218.99 154.538 216.022 155.582 212.797C156.167 211.117 156.69 209.331 157.115 207.624C157.27 207.011 157.412 206.392 157.551 205.765C157.591 205.591 157.75 205.469 157.93 205.498C158.1 205.529 158.237 205.702 158.197 205.876C157.814 207.616 157.369 209.351 156.845 211.057C155.941 214.137 154.62 217.085 153.398 220.033C152.747 221.721 152.177 223.436 151.694 225.175C150.707 228.544 149.764 231.926 149.275 235.406C148.602 239.55 147.983 243.732 148.047 247.941C148.079 249.479 148.059 251.037 148.432 252.538C148.459 252.607 148.486 252.675 148.513 252.744C148.577 252.906 148.476 253.103 148.314 253.167L148.31 253.159Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M158.372 203.41C158.258 203.02 158.494 202.563 158.522 202.157C158.601 201.681 158.669 201.2 158.72 200.726C158.727 200.644 158.764 200.561 158.827 200.497C159.027 200.3 159.408 200.466 159.382 200.752C159.328 201.218 159.271 201.675 159.193 202.13C159.139 202.496 159.053 202.856 158.997 203.214C159.009 203.347 158.935 203.485 158.819 203.54C158.654 203.625 158.47 203.559 158.377 203.398L158.372 203.41Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M147.5 252.631C146.979 250.605 146.906 248.482 146.633 246.407C146.384 244.215 146.259 242.013 146.329 239.804C146.387 237.639 146.664 235.477 146.947 233.332C147.297 230.579 147.729 227.832 148.485 225.156C149.484 221.466 150.801 217.878 151.993 214.251C153.3 210.035 154.547 205.793 155.414 201.464C155.742 199.835 156.053 198.163 156.258 196.523C156.338 195.721 156.397 194.918 156.433 194.104C156.441 193.923 156.603 193.781 156.775 193.792C156.956 193.8 157.095 193.953 157.087 194.133C157.039 195.268 156.95 196.399 156.779 197.522C156.407 199.968 155.919 202.421 155.34 204.82C154.564 208.047 153.61 211.226 152.641 214.391C151.291 218.495 149.787 222.561 148.741 226.763C148.241 228.885 147.907 231.05 147.622 233.215C147.237 236.031 146.904 238.927 146.963 241.766C146.997 243.964 147.255 246.153 147.501 248.336C147.661 249.724 147.771 251.034 148.089 252.37C148.089 252.37 148.089 252.37 148.085 252.361C148.085 252.361 148.085 252.361 148.082 252.353C148.242 252.734 147.685 253.002 147.491 252.634L147.5 252.631Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M157.236 191.547C156.931 191.45 157.01 191.123 157.085 190.886C157.185 190.462 157.24 190.025 157.282 189.604C157.312 189.177 157.957 189.21 157.936 189.633C157.873 190.201 157.804 190.781 157.644 191.327C157.591 191.496 157.401 191.591 157.233 191.538L157.236 191.547Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M177.614 212.438C177.435 212.409 177.318 212.238 177.338 212.062C177.626 209.175 177.898 206.275 178.239 203.396C178.65 199.415 179.647 195.518 180.585 191.645C181.067 189.126 181.836 186.711 182.768 184.331C182.757 184.127 182.907 183.98 183.091 183.947C183.481 183.932 183.516 184.323 183.371 184.607C182.451 186.943 181.7 189.331 181.229 191.777C180.515 194.91 179.627 198.004 179.204 201.201C178.774 204.055 178.521 206.928 178.223 209.789C178.14 210.582 178.065 211.372 177.993 212.17C177.973 212.346 177.785 212.469 177.618 212.446L177.614 212.438Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M177.2 218.522C177.023 218.523 176.872 218.365 176.88 218.185C176.89 217.707 176.903 217.238 176.924 216.765C176.943 216.334 176.964 215.911 176.994 215.484C177.002 215.303 177.143 215.159 177.332 215.164C177.501 215.166 177.669 215.317 177.653 215.502C177.623 215.928 177.593 216.354 177.583 216.783C177.562 217.255 177.552 217.733 177.539 218.202C177.531 218.383 177.381 218.53 177.2 218.522Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M178.168 268.93C177.997 268.948 177.819 268.821 177.806 268.638C177.654 265.461 177.472 262.284 177.316 259.098C177.141 255.939 177.234 252.783 177.289 249.632C177.348 246.489 177.441 243.333 177.731 240.199C178.18 235.461 178.761 230.732 179.28 225.996C179.658 222.886 180.297 219.812 180.728 216.711C181.062 214.368 181.375 212.024 181.791 209.689C182.912 203.996 184.063 198.302 184.512 192.508C184.67 190.649 184.749 188.763 184.454 186.934C184.429 186.569 184.096 186.147 184.558 185.965C185.602 185.604 185.282 191.929 185.162 192.628C184.716 198.104 183.699 203.509 182.614 208.891C182.012 212.01 181.634 215.17 181.159 218.308C180.33 222.987 179.683 227.674 179.23 232.403C178.541 238.064 177.986 243.662 177.962 249.357C177.905 252.479 177.81 255.656 177.965 258.764C178.122 261.901 178.298 265.039 178.446 268.18C178.458 268.461 178.549 268.869 178.177 268.927L178.168 268.93Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M205.162 350.351C201.888 346.347 198.855 342.101 196.515 337.483C194.251 332.805 192.078 328.083 190.261 323.21C187.988 317.529 186.069 311.719 184.629 305.77C182.576 297.357 181.368 288.78 179.68 280.293C179.206 277.854 178.739 275.411 178.415 272.953C178.379 272.71 178.368 272.458 178.315 272.222C178.268 272.053 178.384 271.869 178.553 271.822C179.039 271.75 178.994 272.34 179.043 272.666C179.661 277.428 180.777 282.102 181.571 286.835C183.038 295.241 184.473 303.64 186.936 311.813C188.457 317.039 190.499 322.08 192.497 327.138C193.726 330.238 195.239 333.207 196.61 336.241C198.679 340.522 201.348 344.496 204.288 348.227C204.743 348.808 205.208 349.386 205.672 349.963C205.787 350.105 205.743 350.32 205.606 350.424C205.46 350.53 205.26 350.5 205.145 350.358L205.162 350.351Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M199.747 345.174C196.748 340.964 194.473 336.31 192.118 331.728C190.576 328.81 189.042 325.889 187.749 322.854C185.662 318.048 183.785 313.149 182.35 308.116C180.909 303.096 179.664 298.018 178.594 292.91C178.305 291.395 178.079 289.865 177.798 288.347C177.768 288.171 177.919 288.003 178.09 287.985C178.27 287.964 178.431 288.098 178.452 288.277C178.695 289.623 178.912 290.979 179.146 292.328C180.168 297.415 181.429 302.457 182.83 307.454C184.485 313.269 186.678 318.922 189.169 324.428C190.464 327.295 191.992 330.05 193.435 332.839C194.863 335.644 196.292 338.448 197.902 341.161C198.653 342.416 199.475 343.603 200.296 344.81C200.394 344.959 200.364 345.159 200.209 345.269C200.069 345.364 199.861 345.337 199.757 345.2L199.747 345.174Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M177.715 286C177.542 286.038 177.362 285.932 177.315 285.763C176.924 284.416 176.719 283.016 176.576 281.621C176.522 281.385 176.543 281.061 176.839 281.034C177.018 281.012 177.18 281.146 177.201 281.326C177.293 282.188 177.402 283.043 177.545 283.885C177.654 284.464 177.797 285.03 177.953 285.601C177.999 285.77 177.884 285.953 177.715 286Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M172.861 232.57C172.681 232.563 172.541 232.41 172.541 232.232C172.537 230.388 172.542 228.54 172.55 226.701C172.557 223.075 172.709 219.442 172.926 215.823C173.275 209.447 174.327 203.1 176.059 196.95C176.999 193.461 177.884 189.905 179.604 186.692C180.017 185.907 180.469 185.147 180.906 184.373C181.138 184.085 181.208 183.406 181.676 183.567C182.151 183.844 181.594 184.389 181.461 184.728C181.008 185.538 180.506 186.396 180.088 187.193C178.669 189.893 177.847 192.852 177.052 195.78C175.392 201.487 174.285 207.342 173.746 213.259C173.344 218.719 173.171 224.186 173.189 229.658C173.193 230.525 173.186 231.386 173.191 232.253C173.192 232.431 173.03 232.573 172.853 232.574L172.861 232.57Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M172.857 238.889C172.676 238.881 172.537 238.729 172.536 238.551C172.534 237.792 172.544 237.038 172.608 236.272C172.628 236.096 172.757 235.947 172.947 235.951C173.116 235.954 173.284 236.105 173.267 236.289C173.208 237.043 173.193 237.809 173.192 238.56C173.192 238.737 173.034 238.888 172.853 238.88L172.857 238.889Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M201.126 291.257C200.945 291.25 200.806 291.097 200.805 290.919C200.777 288.66 200.731 286.408 200.694 284.152C200.585 279.654 201.012 275.162 201.5 270.696C202.057 266.183 202.758 261.682 203.868 257.268C204.677 253.998 205.486 250.728 206.677 247.575C208.262 243.388 210.174 239.329 212.176 235.324C212.932 233.753 213.672 232.169 214.405 230.588C214.533 230.31 214.664 230.042 214.796 229.773C214.88 229.611 214.982 229.393 215.179 229.365C215.355 229.336 215.528 229.425 215.579 229.603C215.609 229.729 215.55 229.881 215.443 229.962C214.408 232.028 213.491 234.145 212.467 236.216C210.448 240.228 208.562 244.326 207.007 248.541C204.133 257.064 202.488 265.974 201.716 274.915C201.427 278.276 201.237 281.638 201.347 285.01C201.366 286.138 201.39 287.254 201.408 288.382C201.418 289.237 201.437 290.089 201.447 290.944C201.448 291.121 201.286 291.263 201.109 291.264L201.126 291.257Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M201.018 300.43C200.847 300.448 200.669 300.321 200.656 300.138C200.58 298.737 200.506 297.344 200.446 295.986C200.43 295.617 200.426 295.204 200.421 294.841C200.421 294.663 200.532 294.521 200.712 294.5C201.164 294.47 201.076 294.998 201.086 295.3C201.098 295.631 201.109 295.962 201.124 296.252C201.18 297.524 201.255 298.797 201.319 300.065C201.332 300.248 201.206 300.406 201.027 300.427L201.018 300.43Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M206.528 351.235C204.476 347.826 203.32 343.957 202.169 340.175C201.381 337.543 200.688 334.874 200.308 332.15C199.736 328.081 199.306 323.996 199.026 319.891C198.251 310.709 197.49 301.512 197.276 292.297C197.221 290.246 197.273 288.192 197.456 286.145C197.503 285.811 197.409 285.295 197.881 285.317C198.246 285.39 198.15 285.774 198.126 286.04C198.01 287.379 197.939 288.73 197.925 290.077C197.979 298.733 198.673 307.335 199.363 315.948C199.956 322.635 200.262 329.395 201.7 335.966C202.714 339.901 203.857 343.815 205.412 347.567C205.925 348.747 206.426 349.844 207.102 350.91C207.207 351.076 207.111 351.311 206.932 351.382C206.778 351.443 206.614 351.379 206.528 351.235Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M198.07 282.38C197.892 282.381 197.741 282.223 197.749 282.042C197.775 281.153 197.859 280.262 197.906 279.375C197.914 279.194 198.055 279.049 198.244 279.054C198.422 279.053 198.573 279.211 198.565 279.392C198.518 280.279 198.443 281.167 198.408 282.059C198.4 282.24 198.251 282.388 198.07 282.38Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M206.122 350.228C205.953 350.274 205.769 350.159 205.723 349.99C205.642 349.686 205.545 349.388 205.448 349.091C205.12 348.105 204.783 347.072 204.466 346.091C203.225 342.157 202 338.186 201.426 334.088C200.463 327.34 201.004 320.523 200.873 313.733C200.869 312.995 200.852 312.271 200.819 311.583C200.841 311.239 200.611 310.678 201.077 310.583C201.632 310.562 201.411 311.38 201.485 311.696C201.562 313.324 201.528 314.947 201.535 316.573C201.613 323.028 201.147 329.558 202.409 335.933C203.06 339.448 204.16 342.875 205.225 346.286C205.535 347.25 205.852 348.182 206.161 349.146C206.229 349.495 206.617 350.053 206.126 350.236L206.122 350.228Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M194.222 339.993C188.851 336.261 183.716 332.199 178.528 328.227C171.513 322.901 163.924 318.402 156.512 313.676C150.628 310.195 144.72 306.704 138.548 303.75C133.224 301.342 127.886 298.95 122.775 296.103C118.664 293.968 114.763 291.484 110.987 288.793C108.179 286.827 105.44 284.736 102.937 282.393C99.7025 279.242 96.9571 275.653 94.1566 272.124C92.4829 269.979 90.8112 267.813 88.959 265.817C88.7417 265.616 88.4535 265.285 88.7488 265.03C88.8826 264.919 89.0994 264.942 89.2112 265.076C89.2552 265.137 89.2922 265.231 89.3466 265.269C94.3853 270.771 98.3868 277.203 103.834 282.336C108.205 286.264 113.078 289.56 118.027 292.698C121.984 295.022 126.053 297.153 130.164 299.189C135.418 301.694 140.834 303.858 145.866 306.806C151.931 310.117 157.873 313.644 163.666 317.418C167.485 319.894 171.374 322.245 175.083 324.893C180.189 328.531 185.033 332.53 190.106 336.211C191.578 337.34 193.079 338.418 194.608 339.466C194.755 339.566 194.79 339.779 194.687 339.919C194.587 340.066 194.382 340.098 194.234 339.998L194.222 339.993Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M196.003 341.08C194.65 340.23 193.441 339.165 192.193 338.176C189.233 335.726 186.371 333.17 183.521 330.598C180.808 328.171 178.288 325.43 175.43 323.188C170.189 319.108 164.921 315.059 159.417 311.34C156.615 309.54 153.9 307.586 150.942 306.045C150.008 305.553 149.072 305.082 148.127 304.615C147.969 304.539 147.905 304.327 147.993 304.174C148.078 304.012 148.273 303.955 148.434 304.04C149.391 304.513 150.343 304.997 151.289 305.494C154.647 307.273 157.728 309.507 160.922 311.548C166.345 315.388 171.713 319.338 176.869 323.53C178.591 324.946 180.168 326.517 181.767 328.07C184.148 330.332 186.612 332.502 189.081 334.661C190.399 335.801 191.717 336.941 193.076 338.036C194.097 338.848 195.123 339.698 196.189 340.424C196.375 340.519 196.511 340.663 196.438 340.88C196.373 341.043 196.175 341.141 196.011 341.077L196.003 341.08Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M143.395 302C142.141 301.476 140.931 300.837 139.693 300.277C139.535 300.201 139.48 299.986 139.56 299.836C139.644 299.675 139.843 299.626 140.001 299.702C140.583 299.977 141.165 300.251 141.756 300.522C142.383 300.809 142.98 301.147 143.627 301.386C144.023 301.536 143.799 302.148 143.395 302Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M117.589 300.304C111.214 296.078 104.86 291.528 100.198 285.375C98.5018 283.248 97.0238 280.947 95.6176 278.627C93.709 275.558 91.7352 272.523 89.9192 269.387C89.4562 268.612 89.0051 267.842 88.5026 267.092C88.3559 266.794 87.8969 266.481 88.2267 266.164C88.6882 265.804 89.0693 266.849 89.305 267.122C90.0473 268.281 90.7279 269.484 91.4275 270.66C93.838 274.579 96.2533 278.535 98.7787 282.37C101.334 286.054 104.316 289.442 107.775 292.307C109.625 293.87 111.523 295.354 113.508 296.735C114.986 297.754 116.463 298.772 117.963 299.772C118.293 300.007 117.958 300.563 117.581 300.307L117.589 300.304Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M126.066 305.803C124.785 305.083 123.495 304.366 122.219 303.634C121.731 303.322 121.179 303.075 120.794 302.624C120.514 302.212 121.118 301.865 121.374 302.288C122.936 303.421 124.725 304.228 126.382 305.225C126.76 305.432 126.461 306.003 126.078 305.808L126.066 305.803Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M190.786 336.959C185.726 333.49 180.589 330.1 175.162 327.229C169.132 324.082 162.804 321.585 156.724 318.537C155.652 318.02 154.55 317.555 153.474 317.031C153.312 316.946 153.252 316.743 153.34 316.59C153.425 316.428 153.62 316.371 153.781 316.456C154.425 316.785 155.089 317.067 155.751 317.369C158.583 318.665 161.381 320.101 164.223 321.373C166.915 322.565 169.573 323.721 172.206 325.015C177.162 327.391 181.877 330.235 186.457 333.262C188.029 334.322 189.602 335.381 191.166 336.444C191.496 336.68 191.15 337.231 190.784 336.98L190.786 336.959Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M150.641 315.708C149.03 315.128 147.42 314.527 145.822 313.882C145.424 313.722 145.651 313.12 146.055 313.267C147.649 313.904 149.254 314.516 150.874 315.094C151.273 315.253 151.045 315.856 150.641 315.708Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M83.8252 68.0744C83.1067 67.2019 82.2457 66.4447 81.2986 65.8201C80.3502 65.117 79.0757 64.6903 78.4745 63.6137C78.2771 63.2373 78.834 62.9196 79.0485 63.2893C79.2206 63.6264 79.5076 63.8788 79.8187 64.0921C80.8646 64.7667 81.9484 65.3869 82.9081 66.1942C83.4295 66.6407 83.9045 67.1449 84.3519 67.6797C84.4638 67.8134 84.4231 68.0367 84.2859 68.14C84.1401 68.2467 83.9405 68.2166 83.8252 68.0744Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M85.401 68.2651C84.5654 66.9945 83.6757 65.6365 82.3111 64.8799C81.9055 64.7531 82.0852 64.1297 82.496 64.2445C82.6443 64.2948 82.7855 64.3775 82.9181 64.4636C84.2326 65.3188 85.1465 66.6377 85.975 67.9407C86.181 68.3138 85.6241 68.6315 85.401 68.2651Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M86.8193 68.4273C86.231 66.6547 86.4343 64.709 86.6267 62.8861C86.7016 62.373 86.7798 61.8684 86.9076 61.364C86.948 61.1902 87.1466 61.0924 87.3154 61.1445C87.5727 61.221 87.5813 61.494 87.4996 61.7137C87.1441 63.423 87.0258 65.1576 87.155 66.8935C87.1919 67.264 87.246 67.6277 87.3582 67.9883C87.4627 68.2039 87.5342 68.5114 87.2518 68.6225C87.0806 68.6898 86.8899 68.6068 86.8227 68.4358L86.8193 68.4273Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M87.7454 67.4904C87.2181 67.4311 87.4868 66.7826 87.5776 66.4606C87.8246 65.7317 88.1107 65.0269 88.3935 64.3136C88.4577 64.1501 88.6664 64.0779 88.8214 64.1453C88.9763 64.2127 89.0536 64.4093 88.9928 64.5813C88.7639 65.1551 88.5349 65.7288 88.3179 66.3077C88.2222 66.5921 88.106 66.8747 88.0752 67.1731C88.0672 67.3539 87.9177 67.5015 87.7368 67.4937L87.7454 67.4904Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M91.9492 72.1751C90.616 70.7447 89.1089 69.2742 87.1375 68.8845C87.0256 69.0272 86.8309 69.0347 86.683 68.9349C86.3007 68.641 86.6366 68.1634 87.0648 68.2221C87.6143 68.2627 88.1388 68.4908 88.6499 68.6847C90.1243 69.3685 91.3242 70.5354 92.4199 71.7136C92.7099 72.024 92.2563 72.4788 91.9492 72.1751Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M90.0973 76.4368C88.1413 75.508 87.2388 73.7405 86.5337 71.7966C86.2003 71.0492 85.9136 70.1945 85.2767 69.6552C85.1287 69.5554 85.1199 69.3319 85.2232 69.1925C85.4726 68.897 85.8122 69.2077 85.9867 69.4254C87.0631 70.6803 87.3033 72.4219 88.1655 73.8105C88.6994 74.716 89.5011 75.3978 90.4372 75.8688C90.8013 76.0909 90.4751 76.6436 90.0973 76.4368Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M92.9385 75.5971C89.7849 75.0405 88.443 71.7282 86.4395 69.6236C86.1856 69.2792 86.6951 68.8913 86.9662 69.2289C88.6184 70.9682 89.7034 73.3253 91.83 74.5621C92.1705 74.7244 92.5846 74.8478 92.9205 74.9231C93.1339 74.9379 93.3567 75.0773 93.3142 75.3211C93.291 75.4882 93.1124 75.6373 92.9385 75.5971Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M79.9558 75.2229C79.8131 75.1112 79.7905 74.9029 79.9023 74.7602C80.4568 74.0091 81.1674 73.4039 81.8136 72.7352C82.4699 72.0922 83.0427 71.4129 83.5629 70.6753C83.8145 70.3098 84.0388 69.9254 84.1797 69.5048C84.2439 69.3413 84.4527 69.2691 84.6162 69.3332C85.1593 69.5836 84.3157 70.705 84.1173 71.0298C83.2126 72.3727 82.0278 73.4308 80.9024 74.5644C80.6175 74.7948 80.4014 75.5017 79.9524 75.2143L79.9558 75.2229Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M83.1561 75.4447C82.6539 75.1979 83.3026 74.4098 83.3988 74.0264C83.9845 72.6511 84.3746 71.1551 84.6739 69.7048C84.7057 69.5344 84.8704 69.4005 85.0495 69.4288C85.2201 69.4604 85.3539 69.625 85.3255 69.804C85.1827 70.5215 85.0109 71.2406 84.8153 71.9493C84.5301 73.0584 84.0881 74.1207 83.6308 75.1692C83.6192 75.3909 83.3621 75.5413 83.1647 75.4413L83.1561 75.4447Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M89.0505 23.7412C88.6691 22.4696 88.2828 21.1606 88.2211 19.8225C88.2205 19.6451 88.3819 19.5027 88.5595 19.5019C88.7404 19.5098 88.8795 19.6624 88.8801 19.8398C88.8834 20.1248 88.9244 20.4048 88.9653 20.6849C89.1404 21.6328 89.365 22.581 89.6667 23.4989C89.8161 23.9041 89.217 24.1397 89.059 23.7378L89.0505 23.7412Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M90.478 21.314C90.2986 21.3352 90.1372 21.2012 90.1162 21.022C90.0492 20.5251 90.0147 20.0352 90.0178 19.5404C90.0172 19.363 90.1786 19.2205 90.3562 19.2198C90.718 19.2355 90.6832 19.6243 90.6798 19.8921C90.6929 20.2522 90.7232 20.6056 90.7706 20.9522C90.7916 21.1315 90.6489 21.2962 90.478 21.314Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M93.6134 41.6017C93.4407 41.64 93.2604 41.5333 93.2136 41.3641C92.9734 40.4516 92.7121 39.6363 92.4221 38.7731C91.692 36.7404 90.7583 34.7679 89.7696 32.8566C89.7024 32.6856 89.7856 32.495 89.9567 32.4277C90.1279 32.3604 90.3186 32.4433 90.3858 32.6143C90.7303 33.239 91.0606 33.9284 91.3522 34.5443C92.3821 36.6862 93.2819 38.899 93.8634 41.2073C93.9102 41.3765 93.7946 41.5601 93.6253 41.6069L93.6134 41.6017Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M96.293 91.4142C96.2325 91.2603 96.2967 91.0968 96.4321 91.014C97.3592 89.9288 98.0876 88.6652 98.7561 87.425C99.2574 86.4382 99.74 85.4291 100.182 84.4163C100.254 84.2495 100.451 84.1721 100.618 84.2447C100.785 84.3173 100.862 84.5139 100.79 84.6807C100.499 85.348 100.184 86.0048 99.869 86.6617C99.1647 88.1627 98.3293 89.6066 97.3546 90.9474C97.1256 91.2448 96.6411 91.9979 96.2998 91.4313L96.293 91.4142Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M107.159 83.6189C106.703 83.3639 107.206 82.8599 107.275 82.5071C107.839 80.775 108.197 78.9462 107.927 77.1276C107.902 76.9892 107.874 76.8423 107.829 76.7022C107.742 76.4304 108.081 76.1883 108.319 76.3415C108.623 76.6366 108.569 77.1516 108.636 77.5495C108.718 78.613 108.671 79.6978 108.428 80.7407C108.206 81.6574 108.007 82.5845 107.592 83.4387C107.516 83.597 107.331 83.6796 107.156 83.6103L107.159 83.6189Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M107.666 83.7728C107.481 83.579 107.614 83.3391 107.784 83.1933C108.032 82.9183 108.264 82.6295 108.488 82.3441C109.008 81.656 109.55 80.9492 109.794 80.1128C109.931 79.5556 110.646 79.8667 110.397 80.3891C109.938 81.685 109.028 82.7634 108.132 83.7771C108.014 83.9027 107.783 83.8946 107.666 83.7728Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M112.655 75.7806C111.975 75.3571 113.844 74.0297 114.183 73.7091C114.98 73.0993 115.898 72.6693 116.845 72.3367C117.01 72.2813 117.206 72.3523 117.274 72.5233C117.338 72.6858 117.261 72.8935 117.087 72.9523C116.703 73.0832 116.33 73.2399 115.96 73.405C114.886 73.9161 113.919 74.6717 113.16 75.582C113.089 75.7778 112.853 75.8806 112.664 75.7772L112.655 75.7806Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M117.389 75.0049C116.569 75.13 115.727 75.3523 115.011 75.7425C114.666 75.9965 114.275 75.4791 114.617 75.2165C115.436 74.7365 116.387 74.4909 117.316 74.3425C117.493 74.3127 117.661 74.4638 117.678 74.6345C117.699 74.8138 117.562 74.9666 117.386 74.9964L117.389 75.0049Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M110.717 75.2017C110.42 75.0226 110.618 74.6978 110.749 74.4784C110.881 74.2096 111.003 73.9441 111.099 73.7091C111.392 72.972 111.455 72.1772 111.451 71.3889C111.449 71.005 111.441 70.633 111.429 70.2525C111.429 70.075 111.599 69.9292 111.768 69.9319C111.957 69.9363 112.088 70.0923 112.088 70.2698C112.139 71.6811 112.185 73.1538 111.496 74.4416C111.313 74.7306 111.189 75.421 110.714 75.1931L110.717 75.2017Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M111.665 75.5986C111.288 75.2433 111.884 74.9002 112.043 74.6008C112.429 74.0246 112.606 73.343 112.769 72.6768C112.871 72.2321 112.931 71.7837 112.972 71.3334C112.97 71.0775 112.987 70.7449 113.322 70.7416C113.503 70.7494 113.642 70.9021 113.643 71.0795C113.556 72.4167 113.293 73.8331 112.57 74.9859C112.345 75.2423 112.086 75.8671 111.665 75.5986Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M107.215 72.4029C106.725 71.6085 106.271 70.7802 105.657 70.0738C105.537 69.9435 105.526 69.7404 105.662 69.608C105.784 69.491 106.004 69.4734 106.128 69.6123C106.447 69.9707 106.726 70.3544 106.983 70.7568C107.262 71.19 107.533 71.6266 107.789 72.0785C107.879 72.2308 107.814 72.4437 107.659 72.5248C107.506 72.6143 107.305 72.5552 107.212 72.3943L107.215 72.4029Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M115.577 80.8516C114.969 80.3108 114.359 79.7904 113.694 79.3807C113.439 79.2342 113.165 79.115 112.875 79.0315C112.662 79.0167 112.431 78.8806 112.47 78.6283C112.501 78.4578 112.666 78.324 112.845 78.3522C114.114 78.6135 115.09 79.5624 116.048 80.3901C116.346 80.6972 115.893 81.1519 115.577 80.8516Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M97.4124 118.989C96.0606 118.366 94.5895 117.967 93.0943 117.883C92.98 117.869 92.8587 117.887 92.7376 117.856C92.344 117.734 92.4929 117.133 92.8936 117.222C94.5391 117.286 96.174 117.699 97.6637 118.397C98.0485 118.571 97.7972 119.163 97.3987 119.004L97.4124 118.989Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M97.5255 118.048C96.9045 117.7 96.2229 117.474 95.5966 117.138C95.2326 116.916 95.5673 116.36 95.9365 116.57C96.568 116.895 97.2444 117.132 97.8654 117.48C98.0271 117.565 98.065 117.787 97.9822 117.928C97.8856 118.084 97.6924 118.121 97.5341 118.045L97.5255 118.048Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M99.7603 118.304C99.3141 117.294 99.0904 116.198 98.8939 115.12C98.886 114.748 98.5199 113.767 99.06 113.683C99.2308 113.665 99.4093 113.792 99.4218 113.975C99.4331 114.355 99.5037 114.811 99.563 115.163C99.7549 116.154 99.9554 117.141 100.368 118.065C100.523 118.458 99.9183 118.705 99.7603 118.304Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M102.044 118.532C100.745 118.016 104.537 115.449 105.1 115.247C105.256 115.166 105.454 115.217 105.547 115.378C105.637 115.53 105.581 115.739 105.416 115.824C105.13 115.976 104.856 116.133 104.588 116.307C103.801 116.844 102.966 117.409 102.444 118.217C102.398 118.402 102.259 118.576 102.048 118.54L102.044 118.532Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M105.83 123.636C104.881 122.706 103.717 121.93 102.421 121.6C102.17 121.541 101.841 121.582 101.803 121.261C101.786 121.09 101.913 120.911 102.096 120.899C103.517 121.021 104.782 121.876 105.859 122.755C106.087 122.982 106.613 123.289 106.296 123.64C106.169 123.769 105.959 123.763 105.83 123.636Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M103.406 123.88C103.076 123.417 102.706 122.98 102.281 122.604C102.039 122.314 101.284 122.177 101.512 121.702C101.958 121.004 103.663 123.078 103.936 123.494C104.19 123.838 103.671 124.229 103.409 123.888L103.406 123.88Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M118.744 120.315C118.436 120.386 118.332 120.072 118.275 119.828C117.893 118.655 117.335 117.611 116.517 116.689C116.227 116.378 116.681 115.923 116.988 116.227C117.934 117.277 118.596 118.557 118.982 119.915C119.028 120.084 118.904 120.271 118.744 120.315Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M119.494 119.31C119.082 119.393 119.061 118.888 118.962 118.611C118.898 118.271 118.431 117.763 118.894 117.532C119.311 117.388 119.39 117.939 119.526 118.211C119.608 118.544 119.992 119.144 119.503 119.306L119.494 119.31Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M121.154 120.011C120.434 119.514 122.671 117.914 123.061 117.573C123.206 117.467 123.415 117.493 123.521 117.639C123.804 118.081 123.046 118.339 122.81 118.62C122.429 118.957 122.036 119.289 121.726 119.707C121.624 119.925 121.424 120.171 121.154 120.011Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M122.802 122.423C122.307 121.69 121.761 120.957 121.022 120.458C120.637 120.283 120.888 119.691 121.287 119.85C122.177 120.379 122.808 121.256 123.376 122.098C123.475 122.247 123.401 122.464 123.246 122.545C123.093 122.634 122.892 122.575 122.799 122.414L122.802 122.423Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M117.361 123.91C116.496 123.647 118.461 120.98 118.878 120.658C119.186 120.359 119.644 120.821 119.34 121.128C118.841 121.62 118.471 122.16 118.136 122.766C117.986 123.062 117.85 123.372 117.768 123.69C117.716 123.859 117.529 123.962 117.361 123.91Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M118.98 125.04C118.728 123.668 119.162 122.234 119.624 120.947C119.69 120.713 119.868 120.436 120.154 120.561C120.549 120.761 120.21 121.18 120.126 121.47C120.002 121.834 119.891 122.204 119.795 122.587C119.643 123.308 119.497 124.067 119.588 124.801C119.743 125.194 119.138 125.442 118.98 125.04Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M112.341 172.991C112.039 172.725 112.035 172.263 111.944 171.904C111.762 170.84 111.784 169.765 111.905 168.691C111.958 168.246 112.042 167.808 112.156 167.368C112.197 167.194 112.38 167.083 112.554 167.123C113.039 167.277 112.645 167.907 112.641 168.224C112.431 169.501 112.394 170.789 112.644 172.053C112.689 172.242 112.722 172.427 112.853 172.583C112.991 172.884 112.613 173.18 112.35 172.988L112.341 172.991Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M113.302 172.219C112.743 172.182 113.008 171.347 113.045 170.987C113.17 170.652 113.27 169.675 113.782 169.848C113.946 169.912 114.04 170.102 113.968 170.269C113.826 170.611 113.728 170.916 113.651 171.272C113.568 171.591 113.771 172.182 113.293 172.222L113.302 172.219Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M115.545 173.174C114.807 172.704 116.825 171.328 117.192 171.006C117.741 170.593 118.366 170.298 119.003 170.057C119.405 169.899 119.66 170.47 119.267 170.654C118.013 171.108 116.866 171.934 116.026 172.965C115.955 173.161 115.73 173.269 115.541 173.166L115.545 173.174Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M119.704 173.422C118.906 173.351 118.094 173.472 117.344 173.728C117.207 173.782 117.062 173.839 116.947 173.923C116.89 174.005 116.799 174.051 116.692 174.053C116.523 174.051 116.349 173.912 116.357 173.731C116.474 173.023 118.226 172.828 118.839 172.755C119.208 172.788 120.087 172.56 120.073 173.129C120.057 173.313 119.885 173.429 119.713 173.418L119.704 173.422Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M113.386 180.575C113.154 180.489 113.141 180.228 113.232 180.034C113.463 179.213 113.584 178.365 113.627 177.519C113.637 176.942 113.655 176.333 113.397 175.803C113.333 175.641 113.404 175.445 113.564 175.372C113.949 175.221 114.075 175.694 114.163 175.966C114.404 176.957 114.281 178.002 114.13 179C114.055 179.463 113.964 179.934 113.807 180.39C113.755 180.559 113.541 180.643 113.386 180.575Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M115.44 178.082C115.084 178.084 115.09 177.696 115.085 177.432C115.051 177.07 114.992 176.718 114.921 176.361C114.863 176.088 114.649 175.669 115.035 175.546C115.205 175.499 115.39 175.594 115.44 175.772C115.634 176.416 115.727 177.081 115.763 177.748C115.772 177.922 115.617 178.082 115.44 178.082Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M107.191 177.158C107.057 176.717 107.624 176.474 107.906 176.235C108.787 175.612 109.79 175.198 110.837 174.945C111.009 174.906 111.192 174.993 111.242 175.17C111.289 175.339 111.188 175.537 111.016 175.575C110.619 175.672 110.241 175.791 109.861 175.93C109.125 176.22 108.433 176.62 107.83 177.124C107.704 177.381 107.335 177.447 107.2 177.154L107.191 177.158Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M111.618 174.372C110.61 173.693 109.563 173.117 108.455 172.635C108.286 172.583 108.202 172.369 108.266 172.206C108.403 171.875 108.778 172.024 109.006 172.152C110.039 172.644 111.052 173.183 112.001 173.837C112.148 173.937 112.183 174.15 112.071 174.293C111.971 174.441 111.758 174.475 111.615 174.364L111.618 174.372Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M127.296 174.762C127.113 174.548 127.3 174.346 127.414 174.183C128.172 172.769 128.708 171.117 128.29 169.524C128.251 169.352 128.399 169.175 128.567 169.149C129.223 169.009 129.116 171.223 129.03 171.632C128.962 172.113 128.025 175.561 127.296 174.762Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M126.213 171.88C126.149 171.718 126.212 171.525 126.371 171.453C127.02 170.615 127.346 169.51 127.46 168.468C127.48 168.292 127.645 168.158 127.82 168.178C128.483 168.253 127.813 169.869 127.715 170.273C127.561 170.709 126.821 172.697 126.216 171.889L126.213 171.88Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M128.625 165.651C128.17 165.524 128.497 164.951 128.515 164.668C128.63 164.03 128.622 163.382 128.489 162.743C128.467 162.386 128.049 161.801 128.528 161.612C128.698 161.565 128.883 161.66 128.933 161.838C129.196 162.732 129.347 163.669 129.189 164.6C129.08 164.949 129.164 165.716 128.625 165.651Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M129.452 166.097C129.398 165.96 129.433 165.798 129.553 165.702C130.128 164.676 130.173 163.434 130.037 162.283C129.98 161.862 130.621 161.758 130.69 162.185C130.768 162.885 130.802 163.601 130.694 164.305C130.624 164.757 130.163 166.952 129.464 166.102L129.452 166.097Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M134.947 168.711C134.047 167.979 132.915 167.565 131.762 167.495C131.543 167.492 131.326 167.469 131.111 167.524C130.933 167.624 130.697 167.578 130.611 167.385C130.548 167.222 130.619 167.027 130.778 166.954C131.211 166.774 131.701 166.838 132.163 166.883C132.895 166.961 133.62 167.17 134.278 167.513C134.673 167.713 135.056 167.957 135.397 168.247C135.531 168.362 135.532 168.589 135.413 168.715C135.289 168.853 135.088 168.843 134.945 168.731L134.947 168.711Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M132.746 170.98C132.306 170.086 131.802 169.208 131.101 168.507C130.904 168.357 130.91 168.019 131.146 167.917C131.292 167.859 131.457 167.903 131.545 168.026C131.715 168.206 131.884 168.386 132.04 168.582C132.572 169.231 132.96 169.967 133.331 170.71C133.404 170.869 133.324 171.068 133.165 171.141C132.994 171.208 132.806 171.133 132.734 170.974L132.746 170.98Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M93.3811 40.1317C93.1033 38.1186 92.641 36.1385 92.0126 34.2138C91.3953 32.4428 91.0042 30.5433 89.9485 28.9643C88.9378 27.2986 87.1957 26.2857 85.9823 24.8082C85.4588 24.1553 85.141 23.3721 84.626 22.7158C84.2665 22.3043 83.8295 22.022 83.4099 21.6836C83.931 21.9032 84.5254 22.1334 84.9928 22.4926C85.494 22.8878 85.8852 23.4052 86.3504 23.8343C87.7606 24.958 89.6088 25.3862 90.9466 26.6272C93.3779 28.84 93.8631 32.3609 93.9256 35.4853C93.9735 37.115 93.5777 38.7218 93.4994 40.332C93.5422 40.3152 93.5342 40.2196 93.5651 40.1976C93.5733 40.2437 93.5556 40.3494 93.5042 40.3696C93.4187 40.4032 93.393 40.1369 93.4 40.1045L93.3811 40.1317Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M94.8861 38.583C98.5602 33.9794 97.2965 28.2276 95.4549 23.167C95.3257 22.2603 95.0409 21.3852 94.5916 20.5944C94.8936 20.91 95.1848 21.299 95.4403 21.6723C96.51 24.0157 98.3144 25.9913 98.9253 28.525C99.8696 32.359 97.9482 36.2931 94.8775 38.5863L94.8861 38.583Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M97.8303 27.2952C97.739 25.5049 98.8721 23.9636 100.256 22.9356C100.876 22.475 101.561 22.1068 102.224 21.7078C103.112 21.1019 104.173 20.7341 104.844 21.8622C105.006 22.1241 105.097 22.4539 105.141 22.7425C104.861 22.2308 104.085 21.44 103.576 22.1043C102.901 23.0509 101.886 23.9632 100.935 24.5347C99.649 25.3363 98.2017 26.3299 98.0345 27.9652C98.0323 28.0351 98.0265 28.146 98.0809 28.1838C98.0809 28.1838 98.0809 28.1838 98.0981 28.1771C98.0981 28.1771 98.1323 28.1637 98.1237 28.167C97.9079 28.2716 97.8603 27.4217 97.8493 27.268L97.8303 27.2952Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M95.1525 60.7983C94.3415 56.675 93.9972 52.457 94.4143 48.2654C94.6565 45.8405 95.1392 43.4494 95.6133 41.0616C95.6344 40.9645 95.6228 40.9098 95.7096 40.9546C95.713 40.9632 95.6772 40.9476 95.6601 40.9544C95.6704 43.6946 95.9044 46.4258 95.9351 49.1679C95.9592 51.6164 95.6638 54.082 95.3893 56.4999C95.2575 57.9239 95.233 59.3945 95.1559 60.8068L95.1525 60.7983Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M90.9958 69.8753C90.8185 69.8265 90.5471 69.8148 90.7739 69.5874C91.4042 69.0039 92.1836 68.5987 92.8698 68.0821C93.7669 67.4234 94.4674 66.7925 95.1946 66.0032C95.5764 65.5668 95.9517 65.0638 96.0578 64.4792C96.1385 63.5787 95.6071 62.5538 94.6638 62.3916C93.1702 62.0609 91.7874 62.8415 90.4236 63.3185C89.0616 63.7751 87.8455 62.7429 88.0797 61.3281C88.1479 60.245 88.8194 59.3393 89.1245 58.3309C89.1334 58.278 89.1389 58.2167 89.163 58.1775C89.1709 58.8259 88.9741 59.4562 88.8319 60.0747C88.6271 60.8858 88.4754 62.7618 89.7802 62.4363C91.1551 61.56 92.5452 60.4212 94.2822 60.6167C97.1048 60.5829 99.1607 64.1278 97.3894 66.4333C96.4648 67.6755 94.972 68.3019 93.5736 68.8419C92.6646 69.2191 91.6551 69.2903 90.7935 69.7376C90.8408 69.8079 90.9637 69.8188 91.0129 69.8685L90.9958 69.8753Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M79.5333 76.9881C78.0671 77.456 76.6547 76.4023 76.1389 75.0652C75.0808 72.5 76.9533 69.5722 79.5272 68.9057C80.0065 68.7172 80.6364 68.7361 81.0093 68.3525C81.0145 68.3406 81.0197 68.3287 81.0334 68.3134C80.9949 68.4668 80.8968 68.5942 80.78 68.6993C79.6934 69.63 78.414 70.5477 78.0872 72.0286C77.9478 72.7547 77.8594 73.5101 78.1128 74.2298C78.3138 74.8417 78.7332 75.5061 79.4701 75.3446C81.3465 74.8141 82.7069 73.2229 84.6303 74.747C82.2796 73.845 81.5478 76.4822 79.5299 76.9795L79.5333 76.9881Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M91.3186 77.1813C89.4053 77.3414 88.0357 75.718 87.0046 74.3268C86.9385 74.2343 86.8233 74.0921 86.9077 73.9799C86.9129 73.968 86.9765 73.9035 86.9523 73.9427C86.9144 73.997 86.9004 74.0618 86.9496 74.1116C87.3472 74.4192 87.8411 74.6198 88.2818 74.8611C88.7788 75.1198 89.259 75.3357 89.7578 75.574C90.7059 76.0501 91.8697 76.6981 92.9065 76.093C93.0058 76.0441 93.085 75.9438 93.1605 75.8845C92.9199 76.68 92.0977 77.102 91.3238 77.1694L91.3186 77.1813Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M96.5512 91.844C96.4427 91.7188 96.4595 91.4852 96.4076 91.3279C96.0216 89.1401 95.2903 87.0289 94.3774 85.0088C93.2286 82.7656 91.8661 80.5571 90.3539 78.5456C89.6708 77.7382 88.8523 77.0137 88.109 76.2793C88.8288 76.6774 89.5434 77.0874 90.2631 77.4856C92.2657 78.633 93.9901 80.3044 95.3428 82.1614C96.6842 84.1907 96.9041 86.7102 96.7494 89.081C96.7249 89.9988 96.5057 90.9241 96.5411 91.8184C96.5497 91.815 96.5601 91.7912 96.5653 91.7792C96.5824 91.7725 96.5512 91.844 96.5274 91.8336L96.5512 91.844Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M99.4793 91.0493C99.1342 91.0764 97.6573 91.3413 97.5314 90.9959C97.5489 90.9397 97.6497 90.9198 97.6925 90.903C97.665 90.9335 97.5813 90.9467 97.5638 91.0029C97.8227 91.1084 98.1498 90.9601 98.3943 90.9034C99.862 90.4645 101.185 89.6085 102.2 88.4692C104.993 85.4459 105.684 81.1467 105.673 77.1728C105.619 75.654 105.106 74.1975 104.678 72.7568C105.448 74.1123 106.353 75.484 106.82 76.9983C108.558 83.0274 106.202 89.8768 99.4674 91.0442L99.4793 91.0493Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M108.933 70.8891C108.719 70.9732 108.456 70.9581 108.257 70.829C108.215 70.7964 108.057 70.671 108.126 70.5946C108.153 70.564 108.208 70.6018 108.231 70.6122C108.238 70.6293 108.172 70.5863 108.141 70.6083C108.135 70.6697 108.239 70.7078 108.296 70.7252C109.296 70.7563 109.18 69.331 109.068 68.6444C108.859 67.4831 108.47 66.3429 108.527 65.1557C108.572 63.5879 109.497 62.1188 111.043 61.6787C112.123 61.2837 113.326 61.5804 114.293 62.1283C115.72 62.9193 116.546 64.6182 116.308 66.2224C116.236 66.7936 116.08 67.378 115.887 67.9178C115.973 66.7785 115.992 65.5962 115.358 64.6116C114.475 63.0933 111.818 62.42 110.839 64.1771C109.821 66.1865 111.161 69.5688 108.921 70.8839L108.933 70.8891Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M123.069 75.2006C121.637 75.5271 120.138 75.3858 118.692 75.6286C117.602 75.771 116.483 75.816 115.419 76.1258C115.283 76.1796 115.132 76.1992 115.039 76.3148C115.039 76.3148 115.046 76.3319 115.049 76.3404C115.049 76.3404 115.065 76.3541 115.073 76.3508C114.829 76.358 115.202 76.0239 115.244 76.0071C115.723 75.5917 116.275 75.2859 116.845 75.0024C118.032 74.4272 119.253 73.8384 120.574 73.6547C121.475 73.5078 122.406 73.7143 123.295 73.4633C124.537 73.1033 124.963 71.6724 124.733 70.5092C124.702 70.3043 124.626 70.0872 124.584 69.9061C124.995 70.3963 125.321 70.9493 125.45 71.5796C125.841 73.2026 124.666 74.8392 123.066 75.1921L123.069 75.2006Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M102.448 108.502C101.05 105.399 99.9023 102.128 99.5427 98.7259C99.3017 96.5799 99.2519 94.4178 99.028 92.265C99.0133 92.1523 98.9794 91.8399 99.1765 91.9894C99.1509 91.9995 99.105 91.9583 99.0827 91.977C99.8803 94.4076 101.032 96.7583 101.631 99.2869C102.064 101.042 102.287 102.839 102.356 104.648C102.392 105.947 102.329 107.245 102.439 108.505L102.448 108.502Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M110.711 92.7246C108.635 93.126 106.576 92.613 104.522 92.3644C103.125 92.2817 101.686 92.2652 100.345 92.6741C100.165 92.7447 99.0153 93.1376 99.383 93.3188C99.0505 93.2521 99.5172 92.8811 99.6065 92.8065C101.343 91.4426 103.584 90.7588 105.764 90.573C107.347 90.4538 108.932 90.64 110.51 90.6811C112.883 90.7352 115.148 89.7359 116.932 88.2153C115.501 90.4067 113.39 92.3521 110.707 92.716L110.711 92.7246Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M93.4965 141.251C91.625 140.989 89.7065 140.332 88.2991 139.04C86.9717 137.775 86.0772 136.103 85.4201 134.407C85.2722 134.03 83.8089 129.907 83.9746 129.901C84.4712 131.038 85.3154 132.029 85.9987 133.064C86.9905 134.48 88.1229 135.802 89.3928 136.971C90.8338 138.349 92.885 138.816 94.7962 139.002C97.9101 139.357 100.433 138.612 103.13 137.127C100.63 140.193 97.4757 141.67 93.5017 141.239L93.4965 141.251Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M105.226 135.898C105.132 135.886 105.08 135.728 105.053 135.66C104.686 134.6 104.505 133.487 104.198 132.403C103.754 130.672 103.337 128.932 102.859 127.214C102.542 126.233 101.952 125.36 101.389 124.505C101.001 123.897 100.542 123.308 100.122 122.742C103.636 124.944 105.355 126.174 105.559 130.614C105.62 132.101 105.333 133.556 105.18 135.028C105.18 135.304 105.098 135.623 105.233 135.866C105.277 135.878 105.336 135.825 105.363 135.844C105.32 135.861 105.27 135.91 105.226 135.898Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M108.747 137.11C107.418 137.149 106.198 136.484 105.089 135.824C105.062 135.805 104.908 135.717 105.006 135.689C105.015 135.685 104.989 135.695 104.989 135.695C106.498 136.316 108.488 136.452 109.833 135.35C112.131 133.252 113.59 130.428 114.705 127.561C115.312 125.862 115.637 124.075 115.99 122.308C116.066 123.156 116.11 124.047 116.165 124.914C116.401 127.477 116.158 130.149 114.967 132.473C113.981 134.489 111.115 137.176 108.752 137.098L108.747 137.11Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M116.713 137.345C115.117 137.834 113.399 137.661 111.783 137.398C111.004 137.3 110.287 136.91 109.52 136.768C109.489 136.79 109.45 136.766 109.513 136.751C110.026 136.697 110.609 136.823 111.111 136.794C113.05 136.624 115.064 136.167 116.734 135.136C119.442 133.202 121.431 130.446 123.207 127.674C123.736 126.884 124.277 126.099 124.806 125.308C124.29 126.784 123.809 128.326 123.303 129.828C122.279 133.054 119.997 136.172 116.725 137.35L116.713 137.345Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M114.2 162.339C112.713 159.637 111.256 156.913 110.169 154.023C109.187 151.073 108.376 148.055 107.736 145.02C107.507 143.836 107.366 142.648 107.184 141.456C106.871 139.703 106.845 137.828 106.181 136.164C106.184 136.173 106.179 136.185 106.174 136.197C106.167 136.179 106.162 136.142 106.194 136.149C106.87 137.088 107.206 138.219 107.652 139.278C108.857 142.141 109.673 145.146 110.491 148.132C111.853 152.828 112.925 157.638 114.209 162.336L114.2 162.339Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M122.393 195.237C122.046 195.008 122.427 193.99 122.495 193.637C122.769 192.523 123.093 191.438 123.358 190.327C124.05 186.836 124.669 183.207 123.965 179.683C123.904 179.302 123.816 178.903 123.759 178.53C124.8 180.273 125.801 182.091 126.127 184.124C126.481 186.66 125.692 189.251 124.469 191.469C123.861 192.537 123.109 193.514 122.537 194.598C122.417 194.921 122.173 195.304 122.732 195.143C122.629 195.184 122.49 195.258 122.376 195.244L122.393 195.237Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M126.46 195.226C125.32 195.644 124.109 195.805 122.918 195.918C122.925 195.935 122.957 195.942 122.981 195.952C122.694 195.877 123.414 195.722 123.473 195.67C126.285 194.505 128.61 192.376 130.408 189.962C132.153 187.716 133.895 185.234 134.123 182.321C134.16 181.863 134.155 181.371 134.174 180.919C134.346 181.681 134.508 182.446 134.57 183.232C134.844 186.391 133.53 189.484 131.567 191.905C130.3 193.558 128.421 194.583 126.465 195.214L126.46 195.226Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M122.373 197.021C122.39 197.015 122.411 197.016 122.424 197.001C122.463 196.848 122.276 196.724 122.176 196.645C120.051 195.387 117.828 194.258 115.864 192.73C114.968 192.036 114.143 191.245 113.143 190.71C110.567 189.462 107.637 189.124 104.814 189.483C104.08 189.525 103.335 189.591 102.611 189.609C103.453 189.338 104.286 189.069 105.118 188.772C108.207 187.33 112.08 187.002 114.99 189.026C116.39 189.947 117.407 191.304 118.578 192.472C119.754 193.678 120.835 194.97 121.984 196.207C122.107 196.346 122.693 196.955 122.37 197.013L122.373 197.021Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M101.966 188.747C100.089 189.1 98.1055 188.429 96.6892 187.189C95.4392 185.844 94.9084 183.891 94.4921 182.179C95.1883 183.623 96.1119 185.017 97.3278 186.099C98.7279 187.197 100.255 188.265 101.969 188.756L101.966 188.747Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M147.741 252.189C145.38 249.05 143.114 245.825 141.393 242.276C137.752 235.505 134.805 228.411 131.869 221.323C129.766 216.098 127.856 210.788 126.009 205.463C125.022 202.376 124.727 199.136 124.152 195.967C124.141 195.813 124.072 195.662 124.102 195.512C124.102 195.512 124.111 195.509 124.119 195.506C124.155 195.521 124.172 195.564 124.194 195.595C124.184 195.569 124.156 195.55 124.129 195.531C125.219 198.528 126.599 201.411 127.774 204.374C131.053 213.44 134.048 222.618 137.818 231.501C140.755 238.569 144.543 245.263 147.744 252.197L147.741 252.189Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M192.591 331.849C187.056 327.046 183.355 320.624 179.588 314.435C176.417 309.187 173.281 303.924 170.54 298.437C167.676 292.861 165.149 287.112 162.185 281.585C160.468 278.351 158.778 275.106 157.039 271.891C155.311 268.602 153.477 265.345 152.098 261.889C151.267 259.699 150.372 257.523 149.592 255.313C150.217 256.301 151.441 258.584 152.25 260.062C158.107 271.264 164.716 282.09 169.88 293.643C172.881 300.093 176.345 306.313 180 312.417C182.176 316.192 184.463 319.922 186.865 323.519C188.674 326.41 190.501 329.196 192.583 331.853L192.591 331.849Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M148.298 250.39C148.153 248.917 148.374 247.419 148.527 245.947C149.143 240.878 149.942 235.825 151.337 230.903C152.06 228.369 152.767 225.82 153.727 223.36C154.819 220.759 155.958 218.129 156.914 215.483C157.798 213.082 158.609 210.67 159.484 208.273C160.604 205.365 161.716 202.44 162.43 199.405C163.458 195.032 162.948 190.494 162.309 186.106C162.196 185.44 162.05 184.767 161.71 184.18C162.844 185.254 163.444 186.805 164.086 188.211C165.383 191.334 165.793 194.815 165.095 198.14C164.262 203.008 161.914 207.416 160.134 211.986C158.892 215.11 157.766 218.277 156.36 221.337C155.243 223.622 154.197 225.939 153.482 228.392C151.79 233.262 150.994 238.373 149.883 243.39C149.333 245.709 148.497 248.032 148.31 250.396L148.298 250.39Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M121.86 229.494C118.545 228.023 115.115 226.815 111.878 225.166C105.643 222.05 100.902 216.777 96.4761 211.529C94.3642 209.022 92.249 206.508 89.9534 204.163C89.1043 203.411 88.1831 202.726 87.1635 202.219C86.7023 201.976 86.217 201.772 85.7591 201.537C85.7661 201.505 85.8257 201.531 85.8462 201.533C85.8462 201.533 85.8377 201.536 85.8291 201.539C92.2278 202.232 95.2669 206.644 99.1579 211.086C101.592 213.959 104.242 216.629 106.92 219.268C111.256 223.535 116.94 226.028 121.857 229.485L121.86 229.494Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M187.392 299.479C186.457 296.194 185.504 292.917 184.792 289.574C184.172 286.313 183.737 283.019 183.281 279.724C181.793 268.828 181.923 257.8 182.006 246.829C182.023 243.378 181.879 239.92 181.883 236.463C182.052 229.556 182.445 222.639 183.222 215.769C184.046 208.466 184.35 201.121 184.735 193.783C184.786 192.253 184.882 190.714 184.842 189.18C184.762 187.82 184.506 186.489 184.249 185.159C184.198 184.853 184.123 184.537 184.05 184.25C184.2 184.556 184.342 184.866 184.478 185.187C185.097 186.661 185.822 188.104 186.236 189.659C186.8 191.846 186.842 194.139 186.8 196.396C186.688 199.678 186.596 202.962 186.401 206.237C186.018 211.571 185.358 216.904 184.857 222.214C184.538 225.548 184.391 228.893 184.092 232.229C183.878 234.475 183.796 236.728 183.73 238.975C183.554 251.068 182.719 263.242 183.825 275.304C184.298 279.699 184.897 284.162 185.572 288.517C186.224 292.16 186.903 295.821 187.384 299.482L187.392 299.479Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M206.258 347.984C205.728 344.55 205.058 341.112 204.97 337.622C204.839 334.071 205.05 330.513 204.837 326.954C204.692 324.425 204.398 322.043 204.28 319.532C204.139 314.198 203.998 308.863 203.393 303.554C202.846 297.214 202.874 290.826 203.361 284.475C203.592 281.946 204.004 279.424 204.165 276.893C204.337 274.417 204.388 272.008 204.521 269.557C204.634 266.955 204.526 264.343 204.882 261.755C205.093 259.628 205.586 257.539 206.319 255.533C206.142 256.767 205.938 258.032 205.878 259.26C205.718 266.293 205.897 273.359 205.219 280.378C204.573 285.696 204.549 291.066 204.697 296.417C204.763 301.208 205.411 305.948 205.706 310.719C205.879 316.06 205.966 321.406 206.392 326.737C206.524 329.459 206.399 332.184 206.3 334.898C206.186 339.256 206.238 343.609 206.251 347.967L206.258 347.984Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M173.986 315.896C168.009 312.205 162.235 308.177 155.942 305.015C149.502 301.881 143.246 298.359 136.722 295.386C128.298 291.63 119.666 288.272 111.643 283.727C108.716 281.936 106.045 279.768 103.404 277.599C101.484 275.984 99.625 274.297 97.9205 272.45C95.3764 269.976 93.5302 266.486 90.0688 265.172C90.0346 265.186 89.9918 265.202 89.949 265.219C89.8772 265.238 89.7167 265.232 89.6864 265.155C89.5553 264.821 90.6318 264.971 90.8604 264.999C94.3267 265.522 96.9304 268.022 99.234 270.463C103.195 274.581 107.643 278.252 112.414 281.39C115.284 283.135 118.33 284.573 121.339 286.045C127.132 288.841 133.04 291.375 138.788 294.258C143.203 296.49 147.532 298.904 151.847 301.334C155.808 303.468 159.803 305.56 163.578 308.024C167.286 310.317 170.692 313.075 173.986 315.896ZM90.0398 265.174C89.9545 265.158 89.8949 265.132 89.8111 265.145C89.8775 265.188 89.956 265.187 90.0312 265.177L90.0398 265.174Z" fill={color}/>
</g>
<defs>
<clipPath id="clip0_214_44805">
<rect width="135.062" height="365.462" fill="white" transform="translate(0.856079 49.416) rotate(-21.4649)"/>
</clipPath>
</defs>
</svg>

  )
})

export default FlowerThreeSVG