import { CollectibleVo } from "../../vo/collectible.vo"
import { http } from "../request"

export const collectibleFindMyShelfApi = async (accessToken: string) => {
    return await http.get<CollectibleVo[]>("/collectible/shelf", {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
}
