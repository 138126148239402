import React, { memo } from 'react'

interface AuthorSVGProps {
    size?: string
}

const AuthorSVG: React.FC<AuthorSVGProps> = memo(({ size = 18 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75ZM12.3841 7.07009C12.5609 6.85795 12.5322 6.54267 12.3201 6.36589C12.108 6.18911 11.7927 6.21777 11.6159 6.42991L9.26793 9.24745C8.93068 9.65216 8.71031 9.91483 8.52302 10.0852C8.34637 10.2459 8.26118 10.2655 8.20546 10.2681C8.14974 10.2706 8.06311 10.2587 7.87265 10.1147C7.6707 9.96199 7.42745 9.72034 7.05494 9.34784L6.35355 8.64645C6.15829 8.45118 5.84171 8.45118 5.64645 8.64645C5.45118 8.84171 5.45118 9.15829 5.64645 9.35355L6.34784 10.0549L6.37239 10.0795C6.71342 10.4206 7.00557 10.7127 7.26951 10.9123C7.55124 11.1254 7.86593 11.2845 8.25077 11.267C8.63562 11.2496 8.93461 11.0626 9.19589 10.8249C9.44068 10.6023 9.70517 10.2849 10.0139 9.91432L10.0139 9.91431L10.0362 9.88764L12.3841 7.07009Z" fill="#60B2FF"/>
    </svg>
  )
})

export default AuthorSVG