import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CollectibleProps, CollectibleVo } from "../../vo/collectible.vo";
import { WorkVo } from "../../vo/work.vo";
import { workFindMyShelfApi } from "../../api/work/findMyShelf";
import { collectibleFindMyShelfApi } from "../../api/collectible/findMyShelf";

interface BooksState {
    shelfBooks: WorkVo[] | null
    shelfBooksLoading: boolean
    shelfInitialCollectibles: CollectibleVo[]
    shelfCollectibles: CollectibleProps[]
    shelfCollectiblesLoading: boolean
    workTypeId: number | "all"
    workTagId: number | "all"
}

const initialState: BooksState = {
    shelfBooks: null,
    shelfBooksLoading: false,
    shelfInitialCollectibles: [],
    shelfCollectibles: [],
    shelfCollectiblesLoading: false,
    workTypeId: "all",
    workTagId: "all",
}

// 获取书架中的书籍
export const workFindMyShelfAsync = createAsyncThunk(
    "books/workFindMyShelfAsync",
    async (args: {
        userId: number, 
        accessToken: string
    }) => {
        const { userId, accessToken } = args
        return await workFindMyShelfApi(userId, accessToken)
    }
)

// 获取书架中的藏品
export const collectibleFindMyShlefAsync = createAsyncThunk(
    "books/collectibleFindMyShlefAsync",
    async (accessToken: string) => {
        return await collectibleFindMyShelfApi(accessToken)
    }
)

export const booksSlice = createSlice({
    name: "books",
    initialState,
    reducers: {
        setWorkTypeId: (state, action: PayloadAction<number | "all">) => ({ ...state, workTypeId: action.payload }),
        setWorkTagId: (state, action: PayloadAction<number | "all">) => ({ ...state, workTagId: action.payload }),
        setShelfCollectibles: (state, action: PayloadAction<CollectibleProps[]>) => ({ ...state, shelfCollectibles: action.payload }),
        setShelfCollectiblesLoading: (state, action: PayloadAction<boolean>) => ({ ...state, shelfCollectiblesLoading: action.payload }),
    },
    extraReducers(builder) {
        builder
        // workFindMyShelfAsync
        .addCase(workFindMyShelfAsync.pending, (state) => {
            state.shelfBooksLoading = true
        })
        .addCase(workFindMyShelfAsync.fulfilled, (state, action) => {
            state.shelfBooksLoading = false
            state.shelfBooks = action.payload
        })
        // collectibleFindMyShlefAsync
        .addCase(collectibleFindMyShlefAsync.pending, (state) => {
            state.shelfCollectiblesLoading = true
        })
        .addCase(collectibleFindMyShlefAsync.fulfilled, (state, action) => {
            state.shelfCollectiblesLoading = false
            state.shelfInitialCollectibles = action.payload
        })
    }
})

export const {
    setWorkTypeId,
    setWorkTagId,
    setShelfCollectibles,
    setShelfCollectiblesLoading,
} = booksSlice.actions