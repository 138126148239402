import { http } from "../request"

interface WorkAddShelfResponse {
    collector_id: number
    work_id: number
}

export const workRemoveShelfApi = async (id: number, accessToken: string) => {
    return await http.delete<WorkAddShelfResponse>(`/work/shelf/${id}`, {
        headers: {
            Authorization: "Bearer " + accessToken,
        }
    })
}
