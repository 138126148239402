import { Route } from './'
import { lazy, Suspense } from 'react'
import Loading from "../components/my/MySpin"

const Privacy = lazy(() => import('../pages/other/privacy'))
const Terms = lazy(() => import('../pages/other/terms'))
const Contact = lazy(() => import('../pages/other/contact'))

export const other: Route[] = [
    { 
        path: '/other/privacy', 
        element: (
            <Suspense fallback={<Loading />}>
                <Privacy />
            </Suspense>
        ),
        meta: { banner: "私隱政策" },
    },
    { 
        path: '/other/terms', 
        element: (
            <Suspense fallback={<Loading />}>
                <Terms />
            </Suspense>
        ),
        meta: { banner: "條款與細則" },
    },
    { 
        path: '/other/contact', 
        element: (
            <Suspense fallback={<Loading />}>
                <Contact />
            </Suspense>
        ),
        meta: { banner: "聯絡我們" },
    },
]