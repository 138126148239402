import { http } from "../request"

interface ResetPasswordBody {
    email: string
    password: string
    token: string
}

export const sendResetPasswordEmailApi = async (email: string) => {
    return await http.get(`/account/resetPassword?email=${email}`)
}

export const resetPasswordApi = async (body: ResetPasswordBody) => {
    return await http.put("/account/resetPassword", body)
}