import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { moodFindAllApi } from "../../api/mood/findAll";

export interface MoodTheme {
    id: number
    light: string
    dark: string
    text: string
    border: string 
    backgroundUrl: string
}

interface ThemeState {
    moods: MoodTheme[]
}

const initialState: ThemeState = {
    moods: [],
}

// 获取所有心情
export const moodFindAllAsync = createAsyncThunk(
    "theme/moodFindAllAsync",
    async () => {
        return await moodFindAllApi()
    }
)

export const themeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
        // moodFindAllAsync
        .addCase(moodFindAllAsync.pending, (state) => {
            state.moods = []
        })
        .addCase(moodFindAllAsync.fulfilled, (state, action) => {
            if(action.payload) {
                state.moods = action.payload
            }
        })
    }
})