import { http } from "../request"

export interface CollectorLoginBody {
    email: string
    password: string
}

export interface CollectorLoginResponse {
    accessToken: string
}

export const collectorLoginApi = async (body: CollectorLoginBody) => {
    return await http.post<CollectorLoginResponse>("/collector/login", body)
}
