import { Select } from "antd"
import { memo, useContext } from "react"
import MyButton from "../../../my/MyButton"
import { MyTabsRef } from "../../../my/MyTabs"
import { RootState, useAppSelector } from "../../../../redux"
import MyTag from "../../../my/MyTag"
import { CollectibleFormContext, CollectibleFormContextType } from "."

interface CollectibleStyleProps {
    childRef: React.RefObject<MyTabsRef> 
}

export const CollectibleStyle: React.FC<CollectibleStyleProps> = memo(({ childRef }) => {
    // redux
    const { workTags } = useAppSelector((state: RootState) => state.workReducer)

    // context
    const { mainTagId, setMainTagId } = useContext(CollectibleFormContext) as CollectibleFormContextType

    // other data
    const fontOptions = [
        { label: <div className="text-xl py-2">Ping Fang HK</div>, value: 1 }
    ]
    const workTagOptions = workTags.map(workTag => ({
        label: (
            <section className="w-12 my-2">
                <MyTag text={workTag.name} color={workTag.color}/>
            </section>
        ),
        value: workTag.id
    }))

    return (
        <main className='flex flex-col flex-1 justify-between w-full'>
            <article className="flex flex-col gap-3 pb-10">
                <p className='text-base text-primary m-0 p-0'>字體</p>
                <Select 
                    options={fontOptions}
                    defaultValue={1}
                    className="h-14 text-sm text-center"
                ></Select>
                <p className='text-base text-primary m-0 p-0'>類別</p>
                <Select
                    value={mainTagId}
                    onChange={(value: number) => setMainTagId(value)}
                    options={workTagOptions}
                    className="h-14 text-sm text-center"
                ></Select>
                {/* todo: 花纹 */}
            </article>
            <MyButton 
                type='primary' 
                className='h-12 text-base'
                onClick={() => {
                    if(!childRef.current) return
                    if(childRef.current.tabs) {
                        const ids = childRef.current.tabs.map(tab => tab.id)
                        if(childRef.current.selectedTab) {
                            const index = ids.indexOf(childRef.current.selectedTab.id)
                            const next = childRef.current.tabs[index as number + 1]
                            childRef.current.setSelectedTab(next)
                        }
                    }
                }}
            >繼續</MyButton>
        </main>
    )
})