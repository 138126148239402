import { memo, useRef, useState, useEffect, useContext } from "react"
import { SignCanvas } from "../../../base/SignCanvas"
import MyButton from "../../../my/MyButton"
import { CollectibleFormContext, CollectibleFormContextType } from "."

export const CollectibleSign: React.FC = memo(() => {
    // ref
    const canvasContainerRef = useRef<HTMLElement | null>(null)

    // responsive data
    let [containerWidth, setContainerWidth] = useState<number>(0)
    let [containerHeight, setContainerHeight] = useState<number>(0)

    // context
    const { signUrl, setSignUrl, handleSubmit } = useContext(CollectibleFormContext) as CollectibleFormContextType

    // mounted: 初始化 canvas 画布宽高 (容器多大, 画布就多大)
    useEffect(() => {
        if (canvasContainerRef.current) {
            const { width, height } = canvasContainerRef.current.getBoundingClientRect()
            setContainerWidth(width)
            setContainerHeight(height)
        }
    }, [])

    return (
        <main className='flex flex-col flex-1 justify-between w-full'>
            <div className="pb-10">
                <p className='text-base text-primary m-0 p-0'>您的簽名 (您的簽名將在藏品顯示)</p>
                <div className='flex gap-5 py-5 relative'>
                    {/* 99.5% instead of 100% to make it fit the space */}
                    <section ref={canvasContainerRef} className='w-[99.5%] h-36 rounded-lg border-[1px] border-solid'> 
                        <SignCanvas 
                            signUrl={signUrl}
                            setSignUrl={setSignUrl}
                            containerWidth={containerWidth} 
                            containerHeight={containerHeight}
                        />
                    </section>
                </div>
            </div>
            {/* 簽名顏色 */}
            <MyButton 
                type='primary' 
                onClick={() => handleSubmit()}
                className='h-12 text-base'
            >發佈</MyButton>
        </main>
    )
})