import React, { createContext, memo, useMemo } from 'react'
import Logo from './Logo'
import Search from './Search'
import Edit from './Edit'
import Chat from './Chat'
import Navigation from './Navigation'
import User from './User'
import Login from './Login'
import { useLocation } from 'react-router-dom'
import { RootState, useAppSelector } from '../../redux'
import { Row } from 'antd'

type HeaderCustomContextType = {
  isTransparent: boolean
}

export const HeaderCustomContext = createContext<HeaderCustomContextType>({
  isTransparent: true
})

const HeaderCustom: React.FC = memo(() => {
  // redux
  const { isLogin } = useAppSelector((state: RootState) => state.authReducer)

  // router
  const { pathname } = useLocation()

  // computed: 背景是否透明
  const isTransparent: boolean = useMemo(() => pathname === "/", [pathname])

  return (
    <main 
      className='px-10'
      style={{ 
        color: isTransparent ? "#fff" : "#102e24",  
        backgroundColor: isTransparent ? "transparent" : "#fff"
      }}
    >
      <HeaderCustomContext.Provider value={{ isTransparent }}>
        <Row className="border-0 border-b-[1px] border-solid border-white border-opacity-20 text-base font-[500] h-20 flex items-center gap-10 relative">
          <Logo />
          <Search />
          <Navigation/>
          <section className='flex items-center gap-5'>
            { isLogin ? <User /> : <Login/> }
            <Edit />
            <Chat />
          </section>
        </Row>
      </HeaderCustomContext.Provider>
    </main>
  )
})

export default HeaderCustom