import React, { memo } from 'react'

interface PlusSVGProps {
  size?: string
}

const PlusSVG: React.FC<PlusSVGProps> = memo(({ size = "24" }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="9" stroke="#102E24" strokeWidth="1.5"/>
    <path d="M12 15L12 9" stroke="#102E24" strokeWidth="1.5" strokeLinecap="square"/>
    <path d="M15 12L9 12" stroke="#102E24" strokeWidth="1.5" strokeLinecap="square"/>
    </svg>
  )
})

export default PlusSVG