import { EditorParagraphJson } from "../../utils/ParagraphsToHtml"
import { ChapterVo } from "../../vo/chapter.vo"
import { http } from "../request"

export interface ChapterCreateBody {
    works_id: number
    chapter_prefix: string
    title: string
    paragraphs: EditorParagraphJson
    "previous_chapter_id": number | null,
    "next_chapter_id": number | null,
}

export const chapterCreateApi = async (body: ChapterCreateBody, accessToken: string) => {
    return await http.post<ChapterVo>("/chapter", body, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
}
