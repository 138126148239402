import { Route } from './'
import { lazy, Suspense } from 'react'
import Loading from "../components/my/MySpin"

const Read = lazy(() => import('../pages/read'))
const BookDetail = lazy(() => import('../pages/read/book'))
const ChapterDetail = lazy(() => import('../pages/read/chapter'))

export const read: Route[] = [
    { 
        path: '/read', 
        element: (
            <Suspense fallback={<Loading />}>
                <Read />
            </Suspense>
        )
    },
    { 
        path: '/read/book/:id', 
        element: (
            <Suspense fallback={<Loading />}>
                <BookDetail />
            </Suspense>
        )
    },
    { 
        path: '/read/chapter/:id', 
        element: (
            <Suspense fallback={<Loading />}>
                <ChapterDetail />
            </Suspense>
        )
    },
]