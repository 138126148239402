import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserVo } from "../../vo/user.vo";
import { accountInfoApi } from "../../api/account/info";
import { AuthorLoginBody, authorLoginApi } from "../../api/authors/login";
import { CollectorLoginBody, CollectorLoginResponse, collectorLoginApi } from "../../api/collector/login";
import { AuthorRegisterBody, authorRegisterApi } from "../../api/authors/register";
import { CollectorRegisterBody, collectorRegisterApi } from "../../api/collector/register";
import { accountUploadAvatarApi } from "../../api/account/uploadAvatar";
import { message } from "antd";
import { accountUploadBackgroundApi } from "../../api/account/uploadBackground";
import { AccountUpdateBody, accountUpdateApi } from "../../api/account/update";

interface AuthState {
    loading: "pending" | "fulfilled" | "rejected"
    user: UserVo | null
    isLogin: boolean
}

const initialState: AuthState = {
    loading: "pending",
    user: null,
    isLogin: false,
}

// 作者注册
export const authorRegisterAsync = createAsyncThunk(
    "user/authorRegisterAsync",
    async (body: AuthorRegisterBody) => {
        return await authorRegisterApi(body)
    }
)

// 藏家注册
export const collectorRegisterAsync = createAsyncThunk(
    "user/collectorRegisterAsync",
    async (body: CollectorRegisterBody) => {
        return await collectorRegisterApi(body)
    }
)

// 作者登录
export const authorLoginAsync = createAsyncThunk(
    "user/authorLoginAsync",
    async (body: AuthorLoginBody) => {
        return await authorLoginApi(body)
    }
)

// 藏家登录
export const collectorLoginAsync = createAsyncThunk(
    "user/collectorLoginAsync",
    async (body: CollectorLoginBody): Promise<CollectorLoginResponse> => {
        return await collectorLoginApi(body)
    }
)

// 根据 accessToken 获取用户信息
export const accountInfoAsync = createAsyncThunk(
    "user/accountInfoAsync",
    async (token: string) => {
        return await accountInfoApi(token)
    }
)

// 上传头像
export const accountUploadAvatarAsync = createAsyncThunk(
    "user/accountUploadAvatarAsync",
    async (args: {
        formData: FormData, 
        accessToken: string
    }) => {
        const { formData, accessToken } = args
        return await accountUploadAvatarApi(formData, accessToken)
    }
)

// 上传背景
export const accountUploadBackgroundAsync = createAsyncThunk(
    "user/accountUploadBackgroundAsync",
    async (args: {
        formData: FormData, 
        accessToken: string
    }) => {
        const { formData, accessToken } = args
        return await accountUploadBackgroundApi(formData, accessToken)
    }
)

// 更改用户信息
export const accountUpdateAsync = createAsyncThunk(
    "user/accountUpdateAsync",
    async (args: {
        body: AccountUpdateBody,
        accessToken: string
    }) => {
        const { body, accessToken } = args
        return await accountUpdateApi(body, accessToken)
    }
)

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setIsLogin: (state, action: PayloadAction<boolean>) => ({ ...state, isLogin: action.payload }),
        setUser: (state, action: PayloadAction<UserVo | null>) => ({ ...state, user: action.payload })
    },
    extraReducers: (builder) => {
        builder 
        // accountInfoAsync
        .addCase(accountInfoAsync.pending, (state) => {
            state.loading = 'pending';
            state.user = null;
        })
        .addCase(accountInfoAsync.fulfilled, (state, action) => {
            state.loading = 'fulfilled';
            state.user = action.payload;
        })
        // accountUploadAvatarAsync
        .addCase(accountUploadAvatarAsync.fulfilled, (state, action) => {
            if(!(action.payload as any).error && state.user) {
                message.success("成功更改頭像")
                state.user = { ...state.user, avatar: action.payload }
            }
        })
        // accountUploadBackgroundAsync
        .addCase(accountUploadBackgroundAsync.fulfilled, (state, action) => {
            if(!(action.payload as any).error && state.user) {
                message.success("成功更改專欄封面相片")
                state.user = { ...state.user, background: action.payload }
            }
        })
        // accountUpdateAsync
        .addCase(accountUpdateAsync.fulfilled, (state, action) => {
            if(!(action.payload as any).error) {
                state.user = action.payload
            }
        })
    }
})

export const {
    setIsLogin,
    setUser
} = authSlice.actions