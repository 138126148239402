import { memo } from "react";

interface PatternOneSVGProps {
    color?: string
    scale?: string
}

export const PatternOneSVG: React.FC<PatternOneSVGProps> = memo(({ color = "#102e24", scale = "1" }) => {
    return (
        <main className="w-full h-full absolute top-0 left-0">
        {/* 1 */}
        <svg style={{ scale }} className="absolute top-4 left-4 transform origin-top-left" width="21" height="30" viewBox="0 0 21 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_504_73576)">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.7739 29.1714C11.7162 27.6282 12.0178 19.9121 10.244 17.2514C7.82272 17.7303 5.75623 16.4088 3.80504 15.1849C2.21748 14.5197 0.266294 13.9343 0.284032 11.859C-0.274718 8.91447 2.84719 4.4356 6.05778 6.0409C11.0688 9.21602 10.5987 10.236 13.02 15.0252C13.898 14.9898 13.8537 16.0541 13.9424 16.6749C14.1109 17.952 14.3237 19.2203 14.5898 20.4708C14.9889 22.2713 15.2018 24.116 15.2461 25.9608C15.3082 27.1226 15.6452 29.606 13.765 29.1802L13.7739 29.1714ZM14.0399 27.3976C14.1197 23.7258 13.1442 20.187 12.6652 16.5773C12.5765 16.6217 12.4878 16.666 12.3992 16.7015C12.1153 16.8257 11.8315 16.9232 11.5477 17.003C12.7362 19.4775 12.8869 22.3954 13.4989 25.0295C13.6586 25.8277 13.8093 26.626 14.0399 27.3976ZM9.61427 16.0363C10.3859 15.9742 11.1575 15.8678 11.867 15.5485C10.5987 13.5885 10.3061 11.0342 8.49677 9.43774C6.07552 7.66393 4.53231 5.24268 2.20862 8.79917C1.39266 9.95215 1.17094 11.6993 1.95141 12.9144C2.79397 13.5264 3.89373 13.7038 4.78064 14.2714C6.2529 15.1849 7.76951 16.2758 9.6054 16.0363H9.61427Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.9222 16.4168C11.317 15.7605 14.7493 12.4168 15.2371 11.5122C16.5763 9.95123 17.3745 8.23064 17.6849 6.21737C17.9333 5.02891 18.8024 3.98237 18.7581 2.74957C18.3412 0.68308 15.3435 1.15314 13.9067 1.81832C13.0287 2.2529 12.4788 3.08659 11.8491 3.80498C10.501 4.98457 9.8713 6.55439 10.0398 8.3282C10.0398 8.816 10.4567 9.42796 9.88903 9.75612C9.60523 9.92463 9.17951 9.83594 9.01987 9.52552C8.46999 7.85814 8.7538 5.76504 9.72052 4.29278C10.9977 2.98903 11.9644 1.20635 13.7293 0.514568C15.6894 -0.283647 18.8113 -0.390075 19.8401 1.84493C20.4432 3.29058 19.5652 4.7185 19.0685 6.04885C18.6871 7.26391 18.5718 8.55879 17.9687 9.69403C16.9488 11.6275 15.4145 13.2328 14.1994 15.0421C13.7737 15.4944 13.7471 16.5942 12.9222 16.4168Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M3.82351 6.64199C1.7925 4.47795 3.5397 0.868244 6.17381 0.229673C7.62833 -0.20491 8.70149 0.974673 9.63274 1.89705C10.4398 2.4292 11.3622 4.68193 9.78351 4.67307C9.44649 4.58438 9.22476 4.18527 9.3578 3.84824C9.18928 2.87265 7.88553 1.90592 7.05184 1.42699C7.05184 1.42699 7.03411 1.42699 7.02524 1.42699C7.0075 1.42699 6.99863 1.42699 6.98089 1.42699C6.98976 1.42699 7.0075 1.42699 7.01637 1.42699C7.01637 1.42699 7.01637 1.42699 7.06071 1.43586C5.41994 1.29396 3.85012 3.32497 4.25809 4.87705C4.38226 5.24068 4.58625 5.56884 4.82571 5.87039C5.33125 6.51783 4.32018 7.2983 3.82351 6.64199Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M3.66311 20.1954C-0.0086808 20.0535 -1.99535 15.6899 2.98019 15.6722C3.8937 15.6633 5.65864 14.1733 5.96906 15.6455C5.9868 16.426 4.34602 16.4792 3.74293 16.7897C3.16644 17.1444 0.683105 16.701 1.4281 17.8362C1.95138 18.4304 2.76733 18.8384 3.55668 18.9182C5.94245 19.1754 8.32822 18.2442 10.2705 16.9316C10.9091 16.426 11.6985 17.4371 11.0422 17.9338C8.89584 19.3883 6.27061 20.426 3.66311 20.1865V20.1954Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.9208 15.477C12.7346 15.3794 12.7346 15.1577 12.8055 14.9803C13.1514 13.4105 13.2579 11.7786 13.7989 10.2531C14.0472 9.52588 14.3221 8.8607 14.6326 8.16891C14.8898 7.57469 15.1647 6.98933 15.4485 6.40397C15.484 6.33302 15.5372 6.27094 15.617 6.23546C15.8654 6.12016 16.158 6.40397 16.0339 6.6523C15.1736 8.43498 14.3044 10.2797 13.9408 12.2221C13.7634 13.1178 13.657 14.0314 13.4707 14.9271C13.4619 14.9981 13.4264 15.0779 13.4264 15.1489C13.4264 15.3972 13.1514 15.61 12.9208 15.4681V15.477Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.3272 15.476C11.9104 13.4273 11.9547 11.3253 12.1587 9.25882C12.212 8.60251 12.3361 7.95507 12.4869 7.30763C12.4958 7.11251 12.6554 6.95286 12.8505 6.97947C13.019 7.00608 13.1521 7.16572 13.1255 7.33423C12.6909 9.2056 12.6111 11.1391 12.6643 13.0459C12.7175 13.8264 12.815 14.6157 12.9658 15.3873C13.019 15.7953 12.3982 15.884 12.3361 15.476H12.3272Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.8283 5.05651C13.8017 4.7727 13.8993 4.24943 14.2895 4.33812C14.458 4.38246 14.5644 4.55984 14.5112 4.72835C14.4225 4.95008 14.5024 5.27824 14.1831 5.32258C14.0146 5.34919 13.8549 5.22502 13.8283 5.04764V5.05651Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.97226 7.91982C7.5288 6.6072 6.73946 5.48083 5.86142 4.42541C5.71065 4.23029 5.5244 4.00857 5.58648 3.74249C5.67517 3.40547 6.19845 3.45869 6.20732 3.80458C6.81928 4.67374 7.57315 5.46309 8.03434 6.43869C8.22946 6.84666 8.40684 7.25464 8.56648 7.67148C8.62857 7.83113 8.56648 8.01738 8.39797 8.08833C8.2472 8.15041 8.04321 8.08833 7.98113 7.91982H7.97226Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.87768 7.59083C8.78012 7.53761 8.74465 7.44005 8.70917 7.34249C8.45197 6.65071 8.2125 5.88797 7.67149 5.37356C7.53846 5.26714 7.50298 5.07202 7.61828 4.93011C7.84001 4.65517 8.15042 4.90351 8.31006 5.11636C8.82447 5.72833 9.09054 6.49106 9.34774 7.23606C9.43643 7.49327 9.10828 7.7416 8.88655 7.59083H8.87768Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.8483 16.3448C9.57999 16.3271 8.46249 15.52 7.46029 14.8548C6.31618 14.1009 5.20755 13.2584 4.24082 12.3005C4.13439 12.1675 4.161 11.9635 4.3029 11.8571C4.42707 11.7595 4.61332 11.7772 4.71975 11.8837C4.94148 12.1054 5.19868 12.3271 5.4204 12.5222C6.78624 13.6575 8.26737 14.7927 9.89927 15.52C10.3605 15.6974 10.8571 15.7328 11.3538 15.6974C11.5223 15.6974 11.6731 15.8481 11.6731 16.0166C11.6553 16.4335 11.1409 16.3271 10.8483 16.3448Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.1581 15.7875C9.96075 15.5392 9.09159 14.5459 8.1692 13.8097C7.98295 13.6324 7.60159 13.5171 7.73462 13.1978C7.97409 12.7455 8.44415 13.2066 8.68361 13.4195C9.19801 13.863 9.71242 14.3153 10.2712 14.7055C10.5727 14.8829 10.8654 15.0957 11.2113 15.1578C11.504 15.1667 11.7966 15.0869 12.0716 15.0071C12.2401 14.9627 12.4175 15.0603 12.4618 15.2288C12.6037 15.7432 11.4951 15.7875 11.1581 15.7875Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M6.45678 11.8134C6.21731 11.6006 5.66743 11.2813 5.96898 10.9177C6.32374 10.6072 6.57207 11.1571 6.84701 11.3079C7.17517 11.5651 6.78493 12.0618 6.45678 11.8134Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.06249 8.51486C3.65451 8.28426 3.2288 8.08914 2.79422 7.97384C2.61683 7.97384 2.43945 7.84968 2.43945 7.64569C2.45719 7.25545 2.88291 7.30867 3.14898 7.41509C3.59243 7.557 3.99154 7.75212 4.38177 7.96497C4.53255 8.0448 4.57689 8.24878 4.49707 8.39956C4.40838 8.55033 4.21326 8.59468 4.06249 8.51486Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.1171 2.60811C16.6825 2.38639 17.1349 1.82764 17.2324 1.5527C17.3743 1.26002 17.534 0.985078 17.6936 0.701268C17.7734 0.550494 17.9863 0.506149 18.1282 0.585971C18.5628 0.896387 17.9065 1.46401 17.8089 1.81877C17.6227 2.09371 17.5872 2.75889 17.126 2.60811H17.1171Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.35791 18.2347C1.19827 18.1904 1.08297 18.013 1.13619 17.8445C1.1894 17.6494 1.40226 17.5873 1.57964 17.5873C2.06744 17.5695 2.5641 17.5962 3.0519 17.5962C4.0275 17.5962 5.05631 17.5784 6.01416 17.4986C6.52857 17.4631 7.05184 17.4365 7.56625 17.3922C8.56845 17.3301 9.46422 16.8423 10.3334 16.3811C10.6882 16.1771 11.0074 16.7181 10.6527 16.931C9.78351 17.3833 8.90547 17.8622 7.92101 17.9864C7.31791 18.0662 6.72369 18.0751 6.1472 18.1194C4.55077 18.2613 2.94547 18.1992 1.35791 18.2259V18.2347Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.2745 14.99C11.0949 14.2628 10.4474 12.9147 9.69356 11.7972C8.7357 10.1653 7.76011 8.60431 6.31445 7.43359C8.26564 7.79722 9.91529 9.23401 10.7135 11.0256C11.1924 11.8859 11.7334 14.6885 12.5228 14.9723C12.576 14.9634 12.6381 14.9013 12.6736 14.8925C12.5671 15.0255 12.4252 15.0876 12.2745 14.99Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.8247 15.1672C13.8447 13.8102 14.6961 12.3912 15.4943 10.8923C15.92 10.032 16.328 9.14511 16.6473 8.24047C16.8601 7.6285 17.0641 7.00767 17.3834 6.44892C17.7204 5.86356 18.1639 5.36689 18.5453 4.82588C18.6162 4.72832 18.6783 4.61302 18.7404 4.5332C18.5719 5.2516 18.2792 5.92564 18.1284 6.64404C17.9067 7.69945 18.0663 8.79035 17.8357 9.83689C17.2859 12.5597 14.6695 13.8102 12.7006 15.38C12.7094 15.38 12.7183 15.38 12.7272 15.38C12.736 15.38 12.7183 15.38 12.7094 15.38C12.6207 15.38 12.807 15.1672 12.8159 15.1495L12.8247 15.1672Z" fill={color}/>
        </g>
        <defs>
        <clipPath id="clip0_504_73576">
        <rect width="20.044" height="29.2235" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        {/* 2 */}
        <svg style={{ scale }} className="absolute bottom-0 right-0 transform origin-bottom-right" width="76" height="123" viewBox="0 0 76 123" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_504_73594)">
        <path fillRule="evenodd" clipRule="evenodd" d="M59.7915 17.7386C59.7915 17.7386 59.7915 17.6828 59.7977 17.6518C59.5178 17.5093 59.5489 17.1374 59.4308 16.8833C59.07 15.8792 58.3485 15.0673 57.8447 14.1376C56.6256 11.9931 56.0223 9.58211 55.9165 7.13393C55.7797 5.80758 55.6926 4.36966 56.3893 3.17346C56.9117 1.97106 58.7839 0.520748 59.493 2.35533C59.9532 3.97299 61.2034 5.21877 61.3403 6.917C62.4847 10.2019 62.6278 12.6873 61.4522 16.0341C61.2283 16.5858 60.4011 19.2571 59.7915 17.7386ZM58.9332 14.2244C59.4121 14.9991 59.9843 15.7304 60.2767 16.6044C61.4398 13.8835 61.8814 10.7287 60.681 7.95825C60.4322 7.13393 60.4197 6.24143 60.0217 5.46669C59.3126 4.51841 59.0265 3.3594 58.4605 2.34914C55.0644 3.72507 57.316 11.9063 58.9394 14.2182L58.9332 14.2244Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M60.8291 17.4831C60.3813 17.4335 60.3129 16.7703 60.7358 16.6278C61.42 16.3303 61.9922 15.8406 62.552 15.3634C64.6854 13.7395 65.6122 11.1674 65.8486 8.57046C65.8175 7.07057 66.172 5.39093 65.5935 3.984C65.0275 4.10796 64.5921 4.54181 64.1194 4.85171C63.4726 5.29176 62.6453 5.52109 62.1851 6.19666C61.8741 6.53135 61.9363 7.36807 61.308 7.23791C61.0779 7.17593 60.9349 6.92801 60.9971 6.69249C61.308 5.13682 62.8817 4.64718 64.0199 3.82905C66.5638 1.80853 66.8873 4.44885 66.7815 6.50036C66.8437 8.27916 66.6509 10.0518 66.0725 11.7438C65.4318 14.1734 63.1491 16.5906 60.8291 17.4769V17.4831Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M60.3633 17.4968C60.4939 16.7407 61.7938 17.094 62.3661 16.846C64.089 16.6167 65.8057 16.1953 67.2736 15.2656C68.9716 13.9578 71.0552 12.6562 71.3662 10.3568C71.416 9.73082 71.9385 8.3177 71.0366 8.17515C69.5251 8.33009 68.1257 8.70817 67.0372 9.8052C66.7511 9.99114 66.664 10.4312 66.2597 10.3878C65.9612 10.363 65.787 10.0097 65.9052 9.74942C66.8817 8.47265 68.3993 7.63593 69.9979 7.44999C73.6427 6.30337 72.4423 11.3857 71.1921 13.0281C68.8783 15.7676 65.6004 17.534 62.0053 17.8005C61.4891 17.8129 60.469 18.2716 60.3633 17.4968Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M70.7446 19.1201C68.9408 19.0953 67.2677 18.2338 65.4515 18.4011C64.4874 18.3639 60.687 18.3763 60.3574 17.5334C60.3138 16.9074 61.0229 17.019 61.4148 17.1429C63.2869 17.6016 65.2089 17.4528 67.1122 17.5706C69.0403 17.8805 72.0507 19.0085 73.755 17.7751C74.4951 16.0273 73.2512 13.8084 71.2608 13.7837C71.0182 13.7527 70.8503 13.5171 70.8814 13.2816C71.2422 12.3829 72.8158 13.294 73.388 13.6287C74.7315 14.546 75.1482 16.5789 74.6195 18.054C73.8918 19.4548 72.0694 19.1449 70.7508 19.1263L70.7446 19.1201Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M67.3168 18.0544C67.4163 17.3045 68.4488 17.7383 68.9588 17.5028C69.9167 17.3107 70.7875 16.8768 71.6769 16.4926C72.3175 16.288 73.7792 15.5195 74.0467 16.4802C74.028 17.038 73.3998 16.883 73.0266 16.9698C71.9195 17.1557 67.8828 19.5295 67.3105 18.0544H67.3168Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M69.7733 8.2798C69.5867 8.04428 69.7049 7.74059 69.935 7.59184C71.2226 6.38944 71.1915 4.34412 70.9738 2.73267C70.9675 2.70787 70.9613 2.68928 70.9489 2.66449C69.369 3.34626 67.9633 4.53006 67.1921 6.07954C66.9619 6.39564 67.0366 7.01543 66.5328 7.02782C64.2874 6.47001 71.8694 -1.14723 71.9316 3.04876C72.0374 4.54246 72.0063 6.1973 71.179 7.50506C70.868 7.88934 70.3145 8.82522 69.7795 8.286L69.7733 8.2798Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M59.8724 4.1702C59.1695 3.71156 60.0963 2.78187 60.4135 2.31702C61.3527 1.30676 62.7086 0.761346 64.0459 0.532022C65.775 0.265512 68.3189 -0.912093 69.0715 1.44312C69.5567 2.0939 68.972 4.7776 67.9768 3.68676C67.8213 3.32729 68.2256 3.11036 68.3003 2.78807C68.518 2.05051 68.0888 0.903898 67.2242 0.848117C65.5698 1.07124 63.8406 1.36254 62.2919 2.01333C60.6996 2.58353 60.5814 4.4801 59.8724 4.1702Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M55.3246 30.6598C54.3295 30.6722 56.5686 25.9618 56.6744 25.1809C57.3959 22.9186 58.2542 20.7122 59.2183 18.5429C59.4422 18.1028 59.5231 17.4954 59.9895 17.2413C60.456 17.0368 60.8417 17.7062 60.4312 18.0037C59.268 20.551 58.2604 23.1665 57.3959 25.8316C56.8734 27.3315 56.3447 28.8314 55.8782 30.3561C55.8098 30.5916 55.561 30.728 55.3309 30.666L55.3246 30.6598Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M56.5023 30.8167C55.6564 30.4944 56.9128 28.5607 56.9999 27.8851C58.2874 24.5878 59.4319 21.2471 60.6758 17.9436C60.8189 17.2495 61.8825 17.5036 61.5528 18.173C60.7007 20.1501 60.1036 22.1954 59.3634 24.1973C58.7166 25.815 58.0324 27.5256 57.485 29.1495C57.174 29.6577 57.2922 30.8849 56.4961 30.8105L56.5023 30.8167Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M50.898 39.8825C50.3133 39.7276 48.8392 39.3619 49.2311 38.5438C49.7784 38.0232 50.3693 39.0086 50.9913 38.9962C52.1606 39.1512 52.4219 37.7009 52.0487 36.858C50.7425 33.821 54.3749 34.9428 55.8552 33.9078C57.0495 33.1702 56.7385 30.9513 55.3888 30.5795C54.3376 30.313 53.1994 31.1869 52.2166 30.6414C50.898 29.3337 53.4917 28.2057 53.4108 26.8669C53.386 26.8173 53.3611 26.7677 53.3611 26.7677C52.0922 25.3732 50.0894 27.4309 49.8531 28.7945C50.0148 30.0402 47.8254 29.4762 49.7784 26.9351C50.7487 25.4352 53.6161 24.5737 54.2878 26.743C54.462 28.0507 53.1869 28.8131 52.7266 29.8915C52.7266 29.8915 52.7266 29.9039 52.7329 29.9039C52.7329 29.9039 52.7391 29.9163 52.7453 29.9225C55.2333 29.4391 57.4289 29.4576 57.4848 32.6248C57.4164 34.9738 54.8103 35.3085 52.9754 35.4758C52.2104 35.8477 53.2429 36.9199 53.1061 37.5831C53.218 38.8165 52.2166 40.0747 50.9104 39.8763L50.898 39.8825Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M44.1105 36.8149C39.7752 36.4182 40.8824 30.9764 44.9564 31.3111C43.1588 29.5137 42.6053 26.8858 45.2549 25.5904C47.9232 23.855 49.839 27.5428 49.7083 29.8298C49.8887 30.3318 49.1672 30.6727 48.8998 30.2079C48.7878 28.646 48.1347 25.2185 45.8831 26.2536C41.8153 28.491 46.6482 30.462 46.0262 31.9991C45.5535 32.4763 44.7698 32.0487 44.1789 32.247C42.655 32.4081 41.5479 34.0568 42.6737 35.3335C43.5258 36.474 45.8334 35.3707 45.9329 36.35C45.8085 37.1124 44.6703 36.7405 44.1167 36.8149H44.1105Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M47.8678 34.0509C47.2894 34.0695 47.277 33.1584 47.8554 33.1646C48.1229 33.177 48.3965 33.2018 48.664 33.1398C49.3357 33.1708 50.1194 32.1792 50.6481 32.6812C51.1333 33.6976 48.5582 34.1439 47.8678 34.0447V34.0509Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M49.5534 50.6051C46.3937 48.6528 44.4655 45.0146 44.2852 41.3516C44.31 39.6906 44.2789 37.6019 45.4545 36.3189C46.8042 35.6061 49.5969 39.3063 49.9763 40.4839C50.9591 42.8267 50.6978 45.4918 50.6108 47.9772C50.4553 48.6094 50.7227 49.9234 50.2189 50.2704C50.194 50.5865 49.8208 50.7663 49.5534 50.6051ZM49.5969 49.6011C50.0012 45.0332 50.4366 39.7897 45.921 37.0689C43.9866 41.3888 45.8401 46.8678 49.6031 49.6011H49.5969Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M47.911 50.3096C44.7015 49.6527 41.7036 47.4214 40.5404 44.3101C40.0118 42.9403 39.3276 40.7834 40.5964 39.6678C41.4859 39.4819 41.9088 40.6843 42.5432 41.1181C43.5322 41.961 44.3968 42.9465 45.3919 43.7708C45.9206 43.9878 45.5785 44.8121 45.0436 44.589C43.5571 43.4424 42.2322 42.0292 40.9074 40.6905C40.2854 44.7811 43.9427 48.4193 47.7057 49.3862C48.3339 49.5225 48.9746 49.4233 49.6028 49.411C49.8454 49.411 50.0444 49.6155 50.0444 49.851C50.0009 50.601 48.4397 50.2725 47.9048 50.3096H47.911Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M50.1889 50.563C50.0831 49.8255 52.9754 50.092 53.6782 49.6333C57.7211 48.1458 59.612 43.4726 59.5187 39.444C56.4958 40.2559 53.6036 41.8859 51.7687 44.4767C51.2276 44.9663 50.929 46.8629 50.3444 46.7141C49.8841 46.6397 49.9214 46.1377 50.0831 45.8092C51.6257 41.9169 55.6624 39.4316 59.6244 38.5391C61.509 38.8985 59.7302 43.7639 59.3445 45.0035C58.1814 48.0591 55.3949 50.594 52.0486 50.8853C51.4826 50.8605 50.3195 51.3812 50.1827 50.5692L50.1889 50.563Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M60.7988 53.4509C59.2936 53.389 57.8879 52.8559 56.4386 52.5027C54.6722 52.168 52.7938 52.2114 51.1269 51.449C49.1925 50.8168 50.3867 49.3293 50.9527 50.3582C52.6756 51.4056 54.7904 51.2445 56.6999 51.6535C59.8782 52.7072 62.8451 53.0853 65.8928 51.3994C63.4111 49.7074 60.6681 47.2964 57.4338 48.5236C57.2037 48.598 56.9487 48.4368 56.8865 48.2137C56.818 47.972 56.9673 47.7427 57.1975 47.6683C58.9577 47.0423 60.8983 47.346 62.559 48.1207C63.6972 48.6104 64.5867 49.4967 65.6005 50.1846C66.0608 50.5379 66.7512 50.7053 67 51.2693C67.2114 52.6142 61.8748 53.767 60.7988 53.4509Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M70.1159 45.957C68.4365 45.802 66.7882 45.3124 65.0964 45.2628C64.518 45.2628 64.5242 44.3765 65.0964 44.3765C67.1552 44.141 72.9521 46.8991 71.9756 42.8146C70.3211 38.4513 65.5753 44.3393 66.1787 41.2032C66.6265 40.3107 67.5097 39.6475 67.7212 38.6372C68.2001 36.5733 65.4447 34.8379 64.4869 37.2303C64.1386 38.3583 63.0314 41.6308 61.8496 39.2075C61.3334 37.8811 61.6133 36.1023 60.1019 35.3957C59.0134 34.9247 57.5828 35.6313 57.2532 36.7345C57.2594 37.8067 58.3976 38.3211 58.7646 39.1765C58.3727 40.7941 56.2082 37.5092 56.3699 36.8151C56.5068 34.8069 59.4052 33.5983 60.9664 34.9061C62.453 35.8234 62.1669 37.6766 62.764 39.0835C63.5415 37.9431 63.4793 36.2139 64.8414 35.5011C66.0978 34.5714 67.6963 35.5631 68.3245 36.7655C69.0771 38.1972 68.4365 39.8025 67.4226 40.9243C72.4794 37.8377 75.6702 46.0314 70.1096 45.9632L70.1159 45.957Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M66.6822 50.7302C66.0976 50.6311 64.5799 50.4823 64.7541 49.6518C65.1273 48.9948 65.8488 49.7881 66.3774 49.8005C69.2635 50.284 69.0831 46.3235 66.8688 45.5426C65.6622 45.6479 66.253 43.7018 67.9884 45.1893C70.3084 46.6272 69.7859 50.9409 66.6822 50.724V50.7302Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M63.2552 46.7082C62.1232 46.5967 60.8979 43.2684 62.1232 42.8655C63.2117 41.44 65.1958 47.0429 63.2552 46.7082ZM63.1806 45.6608C63.1246 44.9976 62.9131 44.322 62.5399 43.7642C62.5026 44.446 62.7887 45.1154 63.1806 45.6608Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M47.5633 34.5042C46.5681 33.8596 48.1044 32.4588 48.8819 32.3349C50.0512 31.8019 50.555 32.7192 49.7402 33.0415C47.7436 33.2212 48.49 34.5847 47.5633 34.5042Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M48.8931 38.6563C48.7376 38.5137 48.5137 38.4889 48.3769 38.3216C48.1343 38.0303 48.408 37.5406 48.7874 37.615C49.851 37.8071 49.8323 39.0343 48.8931 38.6563Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M53.4538 42.0152C52.6328 41.7983 53.8519 39.5794 53.8705 38.9039C54.1193 38.3398 54.903 32.991 55.8547 35.1355C55.2514 36.0714 54.3495 42.5234 53.4538 42.0152Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M52.8261 38.2483C52.0859 37.8703 52.8759 36.8104 52.8821 36.1844C53.0003 35.7196 53.0811 34.6845 53.7964 34.9944C54.3127 35.3973 53.6533 36.203 53.6596 36.7546C53.4481 37.2381 53.5911 38.3537 52.8261 38.2483Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M51.029 44.2711C50.2577 43.9179 51.1845 42.6349 51.1969 41.9965C51.4457 40.9305 51.7194 39.945 51.8251 38.879C52.0553 37.9865 52.982 38.5257 52.6648 39.3872C52.4409 40.1123 51.906 44.6244 51.029 44.2711Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M48.8503 61.9556C47.992 61.9061 49.4972 58.1997 49.5096 57.4621C50.1192 55.2619 50.2311 52.9191 50.9837 50.8304C51.2823 50.5825 51.7737 50.849 51.7115 51.2394C51.4316 52.3055 51.2885 53.4273 51.0335 54.5057C50.679 56.3341 50.2809 58.1501 49.8082 59.9537C49.5159 60.5115 49.69 62.0548 48.8441 61.9556H48.8503Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M47.8811 60.8318C47.0166 60.6769 47.962 58.4332 47.9247 57.7701C48.1735 56.1152 48.6151 54.4976 48.9572 52.8675C49.2371 51.9192 49.1376 50.7664 49.6911 49.9359C49.8777 49.7809 50.17 49.8305 50.3131 50.0165C50.5495 50.3264 50.226 50.6487 50.2198 50.9833C49.9026 52.7993 49.4859 54.5905 49.0754 56.3817C48.8203 57.7391 48.5964 59.0964 48.3787 60.46C48.3476 60.7017 48.1175 60.869 47.8811 60.838V60.8318Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M50.8356 73.6003C50.6117 73.5073 50.4997 73.2408 50.5992 73.0177C51.0595 72.0508 50.1887 71.0653 49.5419 70.4145C49.1687 69.9683 47.6635 69.5716 48.4347 68.8589C49.7471 68.5552 51.2026 69.3113 52.5025 68.7535C54.0202 67.8176 52.7886 65.3322 51.3145 65.0471C50.4251 64.9356 49.5481 65.3446 48.7457 65.6855C48.385 66.0078 47.7008 65.9272 47.8127 65.3322C48.1735 64.6195 48.696 63.9067 48.6213 63.0638C48.6897 60.9999 45.6109 60.8945 45.1817 62.7601C44.8272 63.3427 45.2875 65.3694 44.2736 65.0967C43.6641 64.8302 44.2736 62.6361 44.4789 62.1155C46.2267 58.8306 50.7485 61.0309 49.2309 64.5203C54.3436 62.2581 56.4708 71.0405 50.139 69.7514C51.0844 70.6377 52.0485 72.0384 51.4141 73.3523C51.3145 73.5755 51.0533 73.6808 50.8294 73.5879L50.8356 73.6003Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M41.6653 68.3137C32.9823 65.9957 41.261 57.8578 44.3149 63.8078C44.5451 64.3904 45.5962 65.9399 44.5637 66.1754C44.3149 66.1754 44.1097 65.9585 44.1221 65.7105C43.7676 64.5577 43.2513 63.1074 42.0136 62.6736C40.5519 62.2521 38.8974 63.2872 38.7108 64.8118C38.6238 66.5225 40.7696 66.9563 42.0198 67.5017C42.5547 67.7187 42.2002 68.543 41.6715 68.3199L41.6653 68.3137Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.9771 70.2355C45.1934 70.1053 43.4083 68.9215 44.0862 68.0352C43.004 66.7151 45.9273 67.1985 46.4062 67.7377C47.532 68.4071 47.7186 70.5082 45.9833 70.2355H45.9771ZM46.2818 69.3678C46.3192 69.3678 46.3627 69.3554 46.4 69.3368C46.3814 68.5249 45.4422 68.1096 44.7269 68.0662C44.8077 68.1654 44.8451 68.2893 44.8264 68.4133C45.1498 68.8843 45.6847 69.343 46.2818 69.3678Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M44.2928 80.0643C44.156 79.9528 44.0938 79.7792 44.1311 79.6057C41.4565 77.5728 39.3107 74.4304 39.4911 70.9472C39.6217 69.6456 40.0198 67.6995 41.4254 67.2656C43.0675 67.6995 44.3488 69.2614 45.1449 70.7055C46.221 72.8685 46.0841 75.3601 45.5803 77.6471C45.3688 78.1988 45.1947 80.6469 44.2866 80.0643H44.2928ZM44.0564 78.3475C44.1871 78.4529 44.3052 78.5644 44.4172 78.6822C44.5416 78.2917 44.6349 77.8889 44.7095 77.486C44.7095 77.4984 44.7095 77.5108 44.7033 77.5232C45.6425 74.0771 44.921 69.9307 41.4876 68.1581C38.8815 71.3005 41.1766 76.1534 44.0502 78.3475H44.0564Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M38.3644 80.6856C34.6884 80.3943 32.3684 76.4214 32.0761 73.056C32.1072 72.1759 31.8149 67.8063 33.5067 68.9406C34.2406 69.7153 34.9123 70.521 35.7209 71.2152C37.5744 72.9568 39.9878 73.9733 41.6982 75.8264C41.9035 76.3037 42.7183 76.7375 42.3948 77.283C42.2456 77.4751 41.9595 77.5123 41.7729 77.3635C41.2815 76.9049 41.014 76.2293 40.4667 75.8202C37.9663 73.9175 35.1611 72.3432 33.1583 69.8578C32.3684 73.5084 33.6497 77.8842 37.2137 79.5824C39.4839 80.2703 41.7915 79.4088 44.0866 79.3283C44.8766 79.4584 44.5158 80.3819 43.7943 80.227C41.9843 80.3385 40.193 80.9149 38.3706 80.6918L38.3644 80.6856Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M39.3021 72.9546C38.1204 71.275 35.9558 70.8349 34.1148 70.2027C33.9033 70.2275 33.6856 70.0912 33.6296 69.8804C33.5425 68.9507 34.7119 69.4342 35.197 69.6015C36.497 70.1036 37.9027 70.463 39.016 71.3369C39.3892 71.6468 39.694 72.0311 39.9988 72.4092C40.3533 72.8554 39.6442 73.407 39.2959 72.9484L39.3021 72.9546Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M35.0993 70.9216C34.1912 69.8246 33.6128 68.4425 33.7248 67.0045C33.8678 66.0748 33.8989 63.7692 35.2797 64.0233C35.7773 64.2527 35.8146 64.8787 36.0012 65.3311C36.6667 66.8806 37.3198 68.4177 37.6619 70.0725C37.8921 70.5621 37.1395 70.9526 36.872 70.4754C36.7289 70.1531 36.6792 69.7874 36.5797 69.4465C36.2873 67.9156 35.4414 66.5893 34.9936 65.1142C34.3965 66.7504 34.44 68.647 35.5534 70.0601C36.1816 70.5621 35.8084 71.4732 35.0993 70.9216Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.2494 81.6587C45.6771 81.5223 43.7552 80.636 44.3399 79.9047C44.514 79.7311 44.7877 79.7435 44.9681 79.9047C45.4159 80.3199 45.9322 80.7042 46.5479 80.8158C50.8521 80.9955 53.1348 76.2417 54.6027 72.8638C52.0028 73.7377 49.434 74.9092 47.4125 76.7995C46.7221 77.4999 46.1063 78.2932 45.6149 79.1485C45.6025 79.3779 45.4035 79.5638 45.1733 79.5638C44.2404 79.3902 45.0365 78.3366 45.3537 77.9089C47.4996 74.6736 51.2439 73.0684 54.7955 71.8474C56.4562 71.3453 55.3926 73.2915 55.0816 73.9857C54.528 75.2748 53.9123 76.5516 53.085 77.692C51.5611 79.8675 49.123 82.1731 46.2432 81.6525L46.2494 81.6587Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M49.2919 83.0272C48.6637 82.9528 44.3036 81.7814 46.1634 80.9633C47.1274 81.4591 48.1599 81.9054 49.2546 82.1161C53.7578 82.9404 56.7744 80.2939 60.0648 77.7589C58.2548 77.6846 56.5132 76.8169 54.6846 77.1453C54.1621 77.2569 53.6459 77.4366 53.1856 77.7094C52.6942 78.0007 52.2339 77.2321 52.7378 76.947C53.1296 76.7177 53.5463 76.5441 53.9755 76.414C55.4994 75.9306 57.0979 76.3086 58.6031 76.6805C59.4552 76.8664 62.534 76.7797 60.9044 78.2362C57.3342 80.9757 54.1683 83.8019 49.2919 83.021V83.0272ZM46.5925 81.3165C46.5925 81.2732 46.5676 81.2298 46.5428 81.1926C46.5614 81.2298 46.5801 81.2732 46.5925 81.3165Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M58.9647 77.0081C59.0082 76.3822 59.9848 76.5991 60.4015 76.2334C61.1914 75.8863 61.2474 75.2232 61.1292 74.4732C61.179 73.5993 62.2177 73.4319 62.7713 72.9547C63.1693 72.589 63.5861 71.8639 63.0449 71.4424C61.5086 70.209 59.0891 72.651 58.1561 71.399C57.7207 70.6863 58.38 69.8062 57.982 69.112C56.9184 67.891 54.5735 68.8455 54.6854 70.4817C54.6232 71.1511 54.6668 71.9878 54.2811 72.5332C53.9888 72.775 53.4974 72.5023 53.5721 72.1242C53.6094 71.9259 53.6529 71.7337 53.684 71.5974C53.8768 70.4879 53.684 69.0934 54.7538 68.3744C55.8112 67.5129 57.5341 67.389 58.5417 68.393C59.2757 69.0748 58.8901 70.0541 58.9087 70.9032C60.2895 70.5747 61.745 69.8186 63.1631 70.4755C65.3152 71.585 63.7353 73.7418 62.112 74.4112C61.8694 75.2541 62.4416 75.7066 61.378 76.6425C60.8991 77.0329 59.1762 78.0494 58.9709 77.0143L58.9647 77.0081Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M57.8136 74.32C57.3658 74.2704 56.4701 74.1341 56.7687 73.4895C56.8869 73.285 57.173 73.2044 57.3782 73.3283C57.8509 73.4709 58.3485 73.4771 58.8337 73.4523C59.4059 73.3655 59.5241 74.2642 58.9518 74.3324C58.5724 74.3634 58.1868 74.3696 57.8074 74.32H57.8136Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.1222 99.048C42.8735 99.017 42.718 98.7877 42.7428 98.5522C42.8672 97.17 43.0849 95.8003 43.1844 94.4306C43.3026 90.6808 43.8438 86.9745 44.3973 83.2681C44.4906 82.5801 44.6523 81.8922 44.6834 81.198C44.615 80.9749 44.7767 80.7269 44.9944 80.665C46.1513 80.541 45.2308 82.8466 45.2619 83.4912C44.7456 87.0178 44.1983 90.5445 44.0925 94.1083C44.0179 95.633 43.7505 97.139 43.6261 98.6637C43.6012 98.9055 43.3586 99.0728 43.1222 99.0418V99.048Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M41.8348 98.441C41.1195 98.2613 41.6295 97.282 41.5735 96.8048C41.6295 95.9371 41.6357 95.0632 41.6979 94.1955C41.9965 90.3589 42.1769 86.51 42.8299 82.7107C43.0476 82.0971 42.9606 79.432 43.7567 79.5993C44.5777 79.5993 43.6945 82.0475 43.738 82.6425C43.0663 86.5348 42.8735 90.4767 42.5687 94.4062C42.3883 95.1313 42.7864 98.5898 41.8285 98.441H41.8348Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M37.1197 113.435C33.9041 112.964 35.0174 109.754 36.6222 108.099C34.6505 110.361 29.2516 110.373 29.9109 106.407C30.5267 103.537 33.3505 104.213 35.5212 104.219C35.2787 102.539 33.1763 102.434 31.9884 101.696C29.3449 98.4176 38.3264 95.5417 38.9235 102.415C39.3216 102.855 38.625 103.438 38.2393 102.998C37.891 102.446 37.9905 101.715 37.7231 101.12C37.07 98.8886 33.6553 98.2564 32.4362 100.308C31.9324 101.436 34.5758 101.479 35.1791 102.192C35.938 102.756 36.9642 104.051 36.0872 104.913C34.9863 105.465 33.6677 104.87 32.4984 105.018C31.1114 105.093 30.3525 106.754 30.9496 107.925C31.9635 109.196 34.2897 108.942 35.4155 107.913C35.9815 107.293 37.9719 106.215 37.8972 107.776C37.5551 108.601 36.7403 109.14 36.3671 109.958C36.1494 110.578 35.6332 111.384 36.0748 112.003C36.9705 112.778 38.4135 112.834 39.446 112.282C39.6574 112.171 39.9311 112.227 40.0555 112.443C40.379 113.565 37.8288 113.621 37.126 113.441L37.1197 113.435Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M40.4035 107.638C39.682 107.65 37.3309 105.872 38.7365 105.438C38.9231 105.438 39.0911 105.562 39.1533 105.729C39.6073 106.479 40.6647 107.155 41.4795 106.522C41.0503 106.076 40.3662 105.915 39.7566 105.927C39.514 105.927 39.315 105.723 39.315 105.487C39.5638 104.552 41.0192 105.239 41.6164 105.506C43.3144 106.417 41.8278 107.923 40.4035 107.644V107.638Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M38.3322 118.387C37.3059 118.288 38.7987 113.429 39.2092 112.79C40.5838 110.782 41.9024 111.501 43.4138 108.935C44.3344 108.266 45.5597 108.786 46.5797 108.427C48.2467 107.708 48.6261 105.278 47.9854 103.747C46.9592 101.863 44.0483 103.562 42.7296 104.33C42.1263 104.51 41.9024 103.766 42.2569 103.369C42.767 102.595 43.4823 101.9 43.7 100.971C44.0296 99.9977 43.3827 98.5846 42.201 99.0556C40.4221 99.7684 39.2092 101.696 39.2776 103.531C38.5623 104.163 38.2949 102.96 38.4379 102.421C38.6121 101.262 39.3523 100.258 40.1235 99.4027C43.022 96.248 46.2066 99.4399 43.8679 102.669C50.3428 99.6258 50.4547 107.956 46.7663 109.301C45.7463 109.753 44.0607 108.892 43.5818 110.218C42.9287 111.575 41.268 111.823 40.279 112.834C39.5202 113.739 39.3834 115.052 39.1097 116.143C38.8298 116.732 39.1843 118.418 38.3446 118.393L38.3322 118.387Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M40.4104 121.325C39.9439 120.717 41.8099 119.744 42.1208 119.155C42.6993 118.499 43.1596 117.687 43.5328 116.93C44.559 114.91 44.9198 112.635 45.2246 110.416C44.2356 110.64 43.2093 110.931 42.3448 111.47C42.2515 111.631 42.0338 111.712 41.8534 111.662C41.0262 111.272 41.9654 110.553 42.4692 110.385C44.6834 109.555 46.8479 108.408 45.8901 111.978C45.4112 115.04 44.4409 118.226 42.1271 120.42C41.617 120.755 41.0386 121.883 40.4042 121.325H40.4104Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M35.3036 124.236C29.7741 123.759 25.7934 117.89 25.8743 112.64C26.0733 110.917 31.1363 112.82 32.3305 113.006C34.8931 113.855 37.4868 115.324 38.6872 117.846C39.2905 118.956 39.8752 120.084 40.4163 121.224C40.5345 121.664 41.0134 122.123 40.8268 122.575C40.8082 123.232 39.8316 123.338 39.3652 123.648C38.0901 124.199 36.6719 124.286 35.3036 124.249V124.236ZM36.3734 123.35C37.6298 123.319 38.8613 123.009 39.9436 122.371C38.9111 120.245 38.0341 117.865 36.3547 116.173C33.7113 113.96 30.0975 113.105 26.745 112.801C26.7824 117.84 30.8813 123.679 36.3734 123.35Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28.1635 112.735C26.9445 111.415 25.8311 109.958 25.5077 108.148C25.4206 107.374 24.8421 106.01 25.7502 105.57C26.4344 105.496 27.0315 105.985 27.6784 106.147C28.5989 106.413 29.4946 106.766 30.2783 107.318C30.7821 107.597 30.3281 108.384 29.8305 108.086C28.7358 107.281 27.3861 107.051 26.1608 106.512C26.2416 107.808 26.5899 109.115 27.2679 110.231C27.5229 110.907 29.3329 112.134 28.7918 112.741C28.6176 112.908 28.3315 112.908 28.1635 112.741V112.735Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M34.7622 111.308C34.4139 110.633 32.0752 109.604 32.8465 108.872C33.2445 108.407 34.9985 110.347 35.3966 110.688C35.8009 111.097 35.1603 111.723 34.7622 111.308Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M47.7742 113.025C46.947 112.783 45.5848 112.151 45.6097 111.171C45.6843 110.942 45.9394 110.812 46.1695 110.886C47.7556 112.925 49.945 112.566 51.1081 110.291C51.4813 109.331 52.5138 107.973 53.4467 109.225C53.7764 109.672 53.6707 110.279 53.9817 110.725C54.6161 111.295 55.6486 110.886 56.3017 110.521C57.4275 109.901 57.8628 108.451 57.4834 107.261C57.0667 105.736 55.3687 105.197 54.473 104.081C54.1434 103.077 55.3065 102.321 55.5553 101.41C56.0591 100.226 55.4869 98.3975 54.0563 98.2488C51.2822 98.131 51.786 101.007 50.4923 101.627C49.2981 101.837 49.8268 100.164 49.3292 99.5627C48.5393 98.4285 46.6173 98.9801 46.5925 100.375C46.4494 101.199 47.1958 101.8 47.3077 102.532C47.258 102.91 46.7044 103.034 46.5054 102.705C43.5261 98.7012 49.5407 95.8564 50.4674 100.244C51.4066 96.5815 56.3763 96.1972 56.6251 100.393C56.7122 101.658 55.829 102.662 55.2878 103.703C55.6175 104.056 56.1275 104.242 56.482 104.589C58.9264 106.213 59.3743 109.938 56.538 111.395C55.5926 111.903 54.1682 112.194 53.341 111.326C52.8559 110.998 52.9803 109.653 52.5449 109.634C51.8918 110.434 51.5808 111.512 50.7535 112.206C49.9636 112.907 48.8254 113.353 47.7804 113.018L47.7742 113.025Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M51.7428 107.459C50.729 107.354 49.5721 105.271 50.4864 104.565C50.5424 103.926 51.4132 104.05 51.8548 104.174C53.4782 104.639 53.7767 107.465 51.7428 107.459ZM51.8299 106.579C52.9184 106.362 51.9419 104.726 51.1084 104.98C51.1084 105.023 51.1022 105.067 51.0898 105.11C51.0773 105.141 51.0649 105.172 51.0524 105.197C51.1084 105.724 51.3261 106.337 51.8361 106.579H51.8299Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M41.0633 122.686C40.4911 122.662 40.5284 121.769 41.1006 121.8C41.4241 121.819 41.7475 121.831 42.0647 121.825C46.4622 122.06 50.2501 119.098 51.3137 114.883C49.1367 116.154 46.5492 116.594 44.5464 118.162C44.2728 118.385 43.9867 118.608 43.7876 118.899C43.6943 119.116 43.4642 119.253 43.2278 119.178C42.2762 118.62 43.8436 117.61 44.3039 117.238C45.5914 116.24 47.165 115.782 48.6516 115.168C49.5161 114.958 51.3572 113.464 52.0476 113.978C52.6882 114.834 51.6309 116.364 51.3261 117.251C50.7539 118.546 49.8458 119.656 48.7511 120.542C46.6363 122.327 43.7503 122.891 41.0509 122.686H41.0633Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.7251 123.247C42.1777 123.086 40.8342 123.073 41.0581 122.243C41.195 121.958 41.5371 121.939 41.7859 122.094C44.1494 122.795 46.8115 122.447 49.1626 121.883C52.0797 121.226 54.5304 119.28 55.7308 116.553C54.7543 116.435 53.7591 116.417 52.7826 116.553C52.2539 116.535 51.464 117.235 51.1343 116.578C50.9664 115.785 52.2974 115.803 52.8261 115.655C53.8026 115.63 57.5221 115.072 56.5207 116.956C55.681 118.574 54.6485 120.154 53.0687 121.14C50.0334 123.092 46.2517 123.563 42.7251 123.241V123.247Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M39.4834 157.142C38.7867 153.442 38.9049 149.5 38.6561 145.713C38.4695 140.067 38.3327 134.414 38.7245 128.774C38.936 126.853 38.7059 124.783 39.303 122.942C39.8254 122.521 40.2422 123.128 39.9934 123.642C39.0977 132.474 39.2968 141.375 39.7508 150.231C39.869 152.047 39.9498 153.863 40.1116 155.679C40.1675 156.231 40.4972 157.675 39.4771 157.148L39.4834 157.142Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M41.6674 162.671C40.9894 156.913 40.3177 151.149 40.2617 145.335C40.3612 137.941 40.6038 130.565 40.977 123.19C40.8339 122.49 41.7047 122.062 41.8851 122.855C41.8851 123.221 41.8416 123.587 41.8229 123.952C41.5181 132.846 40.8588 141.753 41.3751 150.647C41.5368 154.204 41.9971 157.737 42.4262 161.27C42.4822 161.722 42.5257 162.175 42.5631 162.634C42.5942 163.191 41.6985 163.253 41.6736 162.671H41.6674Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2709 168.979C42.0283 168.954 41.848 168.737 41.8728 168.495C41.9101 168.099 41.9537 167.708 42.0097 167.312C41.4437 165.502 41.9537 163.382 42.0781 161.498C42.4264 158.04 43.5895 154.742 45.2689 151.711C48.4286 146.177 51.4887 140.58 54.7977 135.132C56.7197 132.076 67.3618 114.79 68.525 125.972C67.5982 134.568 55.2704 145.464 49.8716 152.344C47.3152 155.536 45.0014 159.05 43.8321 162.998C43.5273 164.027 43.2723 165.068 43.1044 166.066C42.812 166.748 43.1106 168.991 42.2771 168.979H42.2709ZM42.7623 163.481C44.8335 155.697 50.7983 149.877 56.023 144.038C59.5995 139.675 69.1781 129.926 67.3556 124.162C65.7509 120.586 60.6506 128.24 59.5062 129.672C56.0417 134.432 53.1619 139.551 50.3194 144.689C46.8301 150.503 42.9613 156.502 42.7623 163.475V163.481Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M41.2379 170.083C40.0499 162.02 33.9234 155.933 28.3379 150.454C24.7428 146.822 21.7138 142.707 18.8091 138.517C16.4517 135.22 14.5796 131.526 13.6901 127.559C13.2796 124.188 12.9997 120.537 14.2002 117.296C16.7068 113.162 18.8899 119.917 19.7421 121.901C21.2535 125.285 22.4975 128.799 24.3385 132.028C26.7394 136.59 30.1106 140.525 33.1956 144.616C37.3878 149.785 40.3485 155.983 41.7728 162.466C41.8412 163.358 43.3589 167.282 42.6498 167.517C41.7231 167.802 41.8537 165.862 41.5676 165.305C40.9456 162.881 40.4293 160.439 39.6456 158.072C37.3754 151.043 32.7167 145.329 28.2322 139.608C25.2031 135.617 22.5845 131.284 20.8119 126.592C19.9473 124.683 19.1201 122.762 18.3239 120.828C17.7144 119.514 17.3101 117.977 16.1719 117.01C15.9915 116.893 15.6183 116.843 15.469 117.01C14.3183 118.374 14.3743 120.37 14.2686 122.062C14.1255 125.161 14.4738 128.26 15.7178 131.123C16.968 134.42 18.9957 137.327 21.0482 140.166C23.3371 143.432 25.8002 146.562 28.5681 149.432C33.9234 154.743 39.8198 160.408 41.7044 167.957C41.7293 168.651 42.5503 170.139 41.7417 170.443C41.5054 170.486 41.269 170.325 41.2255 170.083H41.2379Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M55.481 246.311C51.6994 237.838 50.0076 228.609 48.9813 219.443C48.6205 214.633 48.3593 209.811 48.2598 204.989C48.5832 196.616 50.0138 188.286 52.4457 180.266C55.6614 169.94 58.8895 159.515 63.797 149.846C66.005 145.57 67.8524 141.033 70.9498 137.296C72.4986 135.188 83.3149 122.923 82.5001 131.153C81.7413 135.498 79.6328 139.483 77.6113 143.357C74.887 148.489 72.1378 153.615 69.8862 158.97C65.9242 167.349 62.4659 175.989 59.5799 184.796C58.0374 190.282 57.3097 195.959 56.2896 201.556C53.0117 216.945 52.595 230.698 56.3145 246.019C56.4513 246.515 55.6925 246.788 55.481 246.311ZM53.1113 237.175C53.2916 237.795 53.4845 238.408 53.6835 239.022C53.9136 239.735 54.1438 240.448 54.3863 241.154C51.718 228.238 52.5826 214.912 55.2882 202.082C56.3891 196.188 57.1044 190.195 58.7651 184.418C62.4037 173.175 67.1993 162.348 72.2498 151.675C75.4779 144.962 79.9313 138.64 81.5734 131.296C81.6605 130.62 81.8097 129.753 81.3868 129.195C81.0323 129.158 80.7026 129.412 80.3916 129.554C77.1822 131.42 74.6755 134.246 72.2933 137.029C69.5815 140.122 67.7839 143.816 65.9615 147.466C61.166 156.447 57.8819 166.085 54.7533 175.741C51.6372 185.125 49.5286 194.849 49.1492 204.741C49.2985 215.65 50.2066 226.626 53.1113 237.181V237.175Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M59.6923 16.5362C59.4808 14.7388 58.8774 13.0281 58.3114 11.3175C57.2603 8.29293 56.912 5.29934 57.9756 2.21897C57.9756 2.23756 57.9693 2.25616 57.9693 2.27475C57.9818 1.98965 58.411 1.98345 58.411 2.27475C58.2803 2.78298 58.1062 3.28501 58.0129 3.80564C57.8014 4.78491 57.733 5.79517 57.7828 6.79924C57.9071 8.35491 58.2928 9.8734 58.7966 11.3485C59.3564 13.0281 59.9286 14.714 60.1401 16.4742C60.1774 16.7531 59.742 16.8275 59.6985 16.5362H59.6923Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M60.6875 16.9691C60.6875 16.8637 60.7497 16.7831 60.8119 16.7088C61.0358 16.4485 61.2659 16.1943 61.4836 15.9278C62.0434 15.2523 62.541 14.5829 62.9204 13.8019C63.5486 12.5748 63.8099 11.2174 64.1271 9.89105C64.3323 9.02954 64.5314 8.18043 64.6993 7.34371C64.8486 6.66193 64.9357 5.96157 65.1534 5.29219C65.2404 5.03808 65.34 4.79636 65.4581 4.56084C65.6199 4.30672 65.9993 4.54844 65.8313 4.80876C65.4084 5.65167 65.3151 6.61855 65.116 7.51725C64.8175 8.91798 64.4878 10.3063 64.1457 11.7008C63.7166 13.6098 62.6903 15.308 61.3717 16.7398C61.2908 16.8389 61.2037 16.9319 61.1229 17.0311C61.098 17.124 61.0109 17.1922 60.9114 17.1922C60.7932 17.1922 60.6875 17.093 60.6875 16.9691Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M65.8064 4.51853C65.5762 4.45036 65.6447 4.21484 65.7193 4.04749C65.7628 3.83057 66.0241 3.70661 66.1671 3.91734C66.2231 4.0103 66.2044 4.12187 66.136 4.19624C66.0925 4.35739 66.0116 4.57432 65.8064 4.51853Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M66.957 9.17143C66.957 9.08466 67.0068 9.01029 67.0814 8.9731C67.4173 8.68799 67.7159 8.3595 68.002 8.03101C68.6737 7.25627 69.2522 6.39476 69.6502 5.44648C70.0545 4.4858 70.2411 3.43835 70.7574 2.52726C70.9066 2.27934 71.2861 2.49627 71.143 2.75038C70.7263 3.49413 70.5397 4.33085 70.2536 5.12419C69.8617 6.25221 69.2211 7.27487 68.456 8.18596C68.1264 8.57023 67.7843 8.93591 67.4111 9.2706C67.268 9.43794 66.9757 9.41935 66.9633 9.16523L66.957 9.17143Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M61.0156 16.7963C61.0156 16.5669 61.2582 16.5669 61.4261 16.5607C62.4586 16.4492 63.5098 16.2384 64.4614 15.8046C65.4939 15.2282 66.3523 14.4286 67.1857 13.6043C68.2866 12.5507 69.3938 11.497 70.389 10.338C70.7559 9.93515 71.0483 9.46411 71.2286 8.94968C71.2535 8.83192 71.3841 8.73895 71.5085 8.78234C71.6205 8.81333 71.7014 8.94348 71.664 9.05505C71.5148 9.5013 71.2846 9.92276 71.0047 10.3008C70.1091 11.3669 69.145 12.3709 68.1249 13.3192C66.8872 14.4906 65.6992 15.8542 64.0571 16.4554C63.261 16.7157 62.4275 16.9016 61.5941 16.9946C61.3888 17.007 61.0343 17.0999 61.0156 16.7963Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M64.4807 17.7998C64.2941 17.775 64.1137 17.7316 63.9271 17.7254C63.8089 17.744 63.697 17.6511 63.6783 17.5333C63.6596 17.4094 63.7467 17.304 63.8711 17.2854C64.2692 17.2854 64.6673 17.4032 65.0653 17.4156C65.7868 17.4465 66.5519 17.3908 67.2423 17.1614C68.4614 16.7028 69.5436 15.959 70.7627 15.4942C71.6584 15.1967 72.6038 14.8806 73.5617 14.9736C73.6985 14.9736 73.8105 15.0913 73.7856 15.2277C73.7607 15.4136 73.5679 15.4384 73.4124 15.4074C72.6225 15.3578 71.8388 15.6182 71.0924 15.8475C68.6169 16.7958 67.404 18.2275 64.4869 17.7998H64.4807Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M49.5092 50.0862C48.626 49.0326 48.1346 47.7248 47.6743 46.4418C47.183 45.0783 46.6667 43.7086 46.3993 42.2768C46.2438 41.5393 46.2189 40.7893 46.1256 40.0394C45.9639 39.0291 45.7711 37.9817 45.3232 37.0582C45.2237 36.9094 45.1864 36.6677 45.4041 36.6057C45.6591 36.5438 45.6964 36.8537 45.7959 37.0148C46.4241 38.4341 46.5734 40.0022 46.7227 41.5331C46.872 42.6363 47.1768 43.7086 47.5375 44.7622C47.9667 45.9336 48.3399 47.136 48.8748 48.264C49.1547 48.7847 49.4221 49.3239 49.8202 49.7763C50.0192 49.9808 49.7145 50.2969 49.503 50.0924L49.5092 50.0862Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M48.8572 49.7879C46.2262 48.8148 43.7133 46.7261 42.5316 44.154C42.1584 43.2553 42.1148 42.2574 41.6857 41.3773C41.5799 41.117 41.978 40.9373 42.0962 41.2038C42.5751 42.1893 42.5875 43.3297 43.0913 44.3027C44.1985 46.4038 46.0769 47.9905 48.1854 49.0318C48.4156 49.1433 48.6457 49.2673 48.8945 49.3417C49.0251 49.3479 49.1246 49.4656 49.106 49.5958C49.0873 49.7135 48.9753 49.8003 48.8572 49.7879Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M49.9961 50.029C49.9961 49.9112 50.0956 49.8121 50.2138 49.8059C51.0597 49.5456 51.8621 49.2109 52.6644 48.839C53.784 48.244 54.6672 47.2957 55.538 46.397C56.7758 44.9095 57.858 43.2795 58.6106 41.5069C58.8532 40.8561 59.1891 40.2053 59.1455 39.5111C59.1206 39.3748 59.1704 39.2074 59.3321 39.1888C59.8608 39.1393 59.4814 40.478 59.3757 40.7507C58.6044 42.9944 57.292 45.0335 55.7557 46.8309C54.9347 47.6676 54.1012 48.5353 53.0811 49.1303C52.4467 49.4712 51.775 49.7253 51.1032 49.9856C50.8233 50.0476 50.0583 50.5186 50.0085 50.0352L49.9961 50.029Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M64.7229 51.3245C63.933 51.1447 63.1617 50.903 62.3718 50.7357C60.9723 50.4506 59.5417 50.5745 58.136 50.7109C57.1906 50.7915 56.2576 51.0084 55.3184 51.12C54.4228 51.1881 53.5147 51.1014 52.619 51.0146C52.1836 50.9712 51.7545 50.9092 51.3191 50.8782C51.0889 50.8534 50.8526 50.8906 50.6411 50.8039C50.3985 50.6613 50.5976 50.2894 50.8526 50.4134C50.8401 50.4072 50.8215 50.401 50.809 50.3948C50.8277 50.3948 50.8837 50.4072 50.8775 50.4072C52.2769 50.4754 53.664 50.7481 55.0634 50.6923C57.881 50.3204 60.7981 49.682 63.5784 50.5807C63.9952 50.6985 64.4119 50.7977 64.8348 50.8968C64.953 50.9278 65.0214 51.058 64.9903 51.1695C64.9592 51.2873 64.8286 51.3493 64.7167 51.3245H64.7229Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M65.9416 51.7153C65.587 51.641 65.2574 51.4736 64.9091 51.3806C64.7909 51.3435 64.7225 51.2257 64.7536 51.1079C64.8718 50.7856 65.2512 51.0522 65.4751 51.1017C65.6679 51.1575 65.8421 51.2691 66.0411 51.2753C66.1655 51.2753 66.265 51.3744 66.265 51.4984C66.265 51.6719 66.0909 51.7463 65.9354 51.7153H65.9416Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.2148 80.0534C41.8527 79.7187 40.4781 79.415 39.1408 78.9873C38.2514 78.6588 37.4366 78.1692 36.7089 77.568C34.6687 76.0557 34.196 73.6199 33.5678 71.3143C33.4186 70.8185 33.2631 70.3846 32.9956 69.9569C32.9396 69.8764 32.8339 69.8268 32.8401 69.709C32.8401 69.6408 32.8712 69.5727 32.9272 69.5293C33.5181 69.1636 34.109 71.6118 34.2209 72.0208C34.6314 73.5641 35.0357 75.2128 36.1304 76.4276C37.0447 77.4006 38.1581 78.1816 39.4207 78.6216C40.08 78.8386 40.758 78.9687 41.4235 79.1609C42.058 79.322 42.6862 79.477 43.3268 79.6257C43.6005 79.7001 43.4947 80.1215 43.2086 80.0534H43.2148Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M44.3903 79.7799C43.5444 78.1374 43.588 76.2161 43.3641 74.4187C43.022 72.3052 42.4311 70.1793 41.3426 68.3261C41.1995 68.0782 41.5727 67.8489 41.7282 68.103C42.7918 69.888 43.3516 71.9271 43.7497 73.9538C43.9425 75.2554 43.9612 76.5817 44.2224 77.8771C44.3592 78.4287 44.4836 79.0113 44.7449 79.5134C44.919 79.7365 44.5769 80.0154 44.3903 79.7861V79.7799Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.1617 79.973C45.0186 79.9606 44.8383 79.9359 44.7885 79.7747C44.7512 79.6632 44.8321 79.533 44.944 79.502C46.0325 79.657 47.1147 79.3347 48.0166 78.7149C49.4969 77.7356 50.5917 76.3473 51.7672 75.0519C51.7672 75.0519 51.7672 75.0519 51.761 75.0581C52.5011 74.2214 53.1418 73.2917 53.9628 72.5293C54.1681 72.331 54.4853 72.6347 54.28 72.8392C53.459 73.6016 52.837 74.5189 52.1031 75.337C50.2433 77.3513 48.2343 80.1528 45.1741 79.9545L45.1617 79.973Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M48.7319 81.0312C48.4583 81.0064 48.4645 80.5911 48.7506 80.5911C48.8874 80.5973 49.0367 80.5911 49.1487 80.5849C50.7969 80.3928 52.2524 79.5622 53.8509 79.1842C55.835 78.6078 57.8938 78.6016 59.8531 77.9632C60.1267 77.8826 60.2574 78.3103 59.9712 78.3908C58.0431 79.023 55.9905 79.0354 54.0437 79.5932C53.0112 79.8597 52.016 80.2502 51.0146 80.5911C50.2807 80.839 49.5094 81.0745 48.7319 81.0312Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M40.0614 121.2C39.9557 121.138 39.9246 121.001 39.9805 120.896C40.1298 120.598 40.2978 120.313 40.447 120.016C41.324 118.336 41.8776 116.495 42.5058 114.729C42.9288 113.626 43.4637 112.572 44.0421 111.543C44.2909 111.19 44.7636 110.031 45.1928 110.037C45.454 110.037 45.4913 110.421 45.2363 110.477C44.4153 111.636 43.7747 112.938 43.19 114.233C42.6302 115.541 42.2757 116.892 41.747 118.206C41.4111 119.049 41.0317 119.879 40.6025 120.685C40.4595 120.877 40.3911 121.348 40.0614 121.206V121.2Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M32.1001 113.371C30.9121 111.933 29.3696 110.874 28.0945 109.522C27.4663 108.81 26.9314 108.016 26.4587 107.198C26.3343 106.963 26.1726 106.721 26.1602 106.448C26.1664 106.144 26.6266 106.169 26.6018 106.473C27.1616 107.694 27.9701 108.81 28.928 109.752C30.1222 110.843 31.3662 111.828 32.4111 113.061C32.6102 113.266 32.3054 113.582 32.0939 113.377L32.1001 113.371Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M36.7838 123.419C34.7561 123.406 33.0332 122.129 31.7955 120.63C31.1735 119.904 30.5453 119.173 30.0477 118.349C29.5687 117.543 29.1458 116.706 28.6793 115.888C28.0822 114.766 27.3296 113.725 26.4401 112.833C26.3904 112.721 26.4464 112.591 26.5583 112.541C26.6578 112.498 26.7636 112.541 26.8009 112.591C26.9502 112.733 27.1057 112.882 27.2425 113.037C28.7291 114.636 29.519 116.706 30.6821 118.522C31.1548 119.185 31.7022 119.849 32.2308 120.45C32.8964 121.181 33.5743 121.894 34.4451 122.334C35.969 123.171 37.9345 123.189 39.4708 122.396C39.7196 122.253 39.9497 122.631 39.6947 122.78C38.8177 123.27 37.7665 123.425 36.7838 123.412V123.419Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M40.2793 122.345C40.3042 122.104 40.5778 121.992 40.7582 121.868C41.9835 121.143 43.4763 121.05 44.77 120.505C47.0901 119.445 49.3665 117.461 50.8406 115.391C50.9899 115.224 51.077 114.821 51.3693 114.957C51.4751 115.019 51.5124 115.156 51.4502 115.261C50.1938 117.257 48.3776 118.837 46.4183 120.126C45.2365 120.951 43.8557 121.285 42.4811 121.645C41.9027 121.831 41.3553 121.992 40.8515 122.333C40.7209 122.395 40.6649 122.568 40.5094 122.568C40.385 122.568 40.2855 122.469 40.2855 122.345H40.2793Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.6181 121.565C46.5746 121.46 46.6181 121.318 46.7363 121.274C47.2836 121.082 47.9056 120.952 48.4405 120.76C51.0031 119.762 53.7834 118.721 55.4814 116.452C55.6556 116.229 56.0101 116.489 55.8359 116.725C54.3991 118.646 52.2035 119.743 50.0204 120.611C49.1745 120.989 48.3037 121.305 47.4018 121.534C47.1406 121.559 46.7798 121.857 46.6243 121.565H46.6181Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.9776 130.335C18.2312 128.712 17.4102 127.082 17.0059 125.328C16.4336 122.526 16.3777 119.576 15.2581 116.911C15.2208 116.836 15.1523 116.787 15.1523 116.7C15.1523 116.582 15.2456 116.471 15.37 116.471C15.5255 116.471 15.6064 116.613 15.6686 116.731C16.9126 119.619 16.8317 122.848 17.6092 125.854C18.001 127.206 18.6168 128.476 19.2015 129.753C19.2761 129.97 19.5684 130.28 19.2761 130.441C19.1704 130.49 19.0335 130.441 18.9838 130.335H18.9776Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M41.6043 168.206C41.0321 166.322 40.1613 164.568 39.2594 162.826C37.9844 160.124 36.305 157.657 34.7314 155.129C33.0147 152.538 30.8999 150.245 29.0962 147.716C28.1943 146.563 27.2053 145.485 26.2101 144.406C25.1092 143.278 23.9834 142.163 23.038 140.904C21.7256 139.219 20.5252 137.434 19.4927 135.562C18.709 134.186 17.8942 132.822 17.3033 131.353C16.4823 129.42 15.9163 127.393 15.5057 125.341C15.3005 124.313 15.1015 123.277 15.0579 122.23C15.0144 121.511 14.8713 117.191 15.5617 117.389C15.6799 117.42 15.7545 117.538 15.7234 117.656C15.6301 118.077 15.5555 118.462 15.512 118.883C15.4062 120.513 15.4311 122.168 15.6675 123.786C16.1277 126.525 16.8119 129.24 17.9626 131.775C18.398 132.766 18.9329 133.702 19.474 134.638C20.7056 136.888 22.0988 139.051 23.716 141.053C25.1341 142.813 26.8383 144.313 28.2814 146.049C29.6995 147.635 30.8439 149.439 32.231 151.05C33.2386 152.302 34.24 153.567 35.117 154.918C35.6954 155.823 36.2552 156.746 36.8337 157.657C38.003 159.411 38.992 161.271 39.9187 163.161C40.7522 164.748 41.511 166.39 42.0335 168.107C42.0584 168.225 41.9651 168.343 41.8531 168.361C41.7349 168.38 41.6292 168.305 41.5981 168.188V168.206H41.6043Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.1703 128.482C19.4052 126.678 18.6464 124.862 18.1924 122.953C17.6326 120.864 17.1972 118.608 15.6733 116.972C15.5365 116.805 15.3126 116.687 15.2566 116.476C15.2379 116.358 15.3188 116.241 15.437 116.222C15.5365 116.203 15.636 116.265 15.6795 116.352C17.8689 118.391 18.1737 121.527 19.0134 124.242C19.4115 125.569 19.9712 126.833 20.4937 128.11C20.5684 128.265 20.6741 128.494 20.4626 128.593C20.3569 128.643 20.2138 128.593 20.1703 128.488V128.482Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M47.6074 32.199C47.3711 31.4739 47.0165 30.7859 46.5749 30.1599C46.152 29.4843 45.4554 28.9017 45.3434 28.0774C45.3123 27.7923 45.7415 27.7303 45.785 28.0154C45.8099 28.2695 45.9281 28.4989 46.0711 28.7096C46.5625 29.3914 47.0912 30.0483 47.4955 30.7921C47.7132 31.2012 47.8873 31.635 48.0366 32.0813C48.0739 32.1928 47.9931 32.323 47.8811 32.354C47.7629 32.385 47.6448 32.3168 47.6074 32.199Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M48.5508 32.7067C48.3394 31.7027 48.1155 30.6304 47.4437 29.8185C47.2198 29.6388 47.4872 29.2917 47.7174 29.4652C47.7982 29.5334 47.8667 29.6202 47.9289 29.7069C48.5446 30.5685 48.7934 31.6221 48.9925 32.6448C49.0298 32.9299 48.6006 32.9918 48.5508 32.7067Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M49.4043 33.1581C49.4727 31.8999 50.0823 30.7285 50.6545 29.6315C50.8286 29.3278 51.0028 29.0303 51.2081 28.7452C51.2765 28.646 51.4258 28.6336 51.5191 28.708C51.6621 28.8195 51.5999 28.9931 51.4942 29.1108C50.8473 30.0963 50.3435 31.1561 49.9952 32.278C49.9268 32.5197 49.8708 32.7614 49.8521 33.0155C49.8646 33.1767 49.8335 33.3874 49.6282 33.3874C49.5038 33.3874 49.4043 33.2882 49.4043 33.1643V33.1581Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M50.2195 32.7997C49.9023 32.6324 50.2319 32.2915 50.3688 32.1117C50.7046 31.7151 51.0592 31.3432 51.4137 30.9651C51.4946 30.8783 51.6438 30.8845 51.7247 30.9651C51.8056 31.0457 51.8056 31.1882 51.7247 31.2812C51.4386 31.5849 51.1525 31.8886 50.8726 32.1985C50.742 32.3535 50.5989 32.5022 50.5056 32.6819C50.4558 32.7935 50.3252 32.8493 50.2133 32.7997H50.2195Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M51.7051 36.7797C51.2199 35.5525 50.6353 34.2571 49.4908 33.5258C49.3851 33.5878 49.2607 33.5444 49.1922 33.4452C49.0243 33.1663 49.354 32.9494 49.609 33.0919C49.9449 33.2531 50.2185 33.5258 50.4922 33.7737C51.251 34.567 51.7175 35.5959 52.1156 36.6062C52.2213 36.8727 51.8232 37.0462 51.7051 36.7797Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M49.4852 38.9973C48.4838 37.9312 48.3532 36.5987 48.3905 35.2041C48.3656 34.6525 48.3967 34.0451 48.1293 33.5493C48.0609 33.4501 48.1106 33.3076 48.2101 33.2456C48.4403 33.1216 48.5771 33.4006 48.6331 33.5803C49.0001 34.6339 48.7202 35.7868 48.9192 36.8714C49.0312 37.5718 49.367 38.1977 49.8397 38.7246C50.0139 38.9539 49.6718 39.2204 49.4852 38.9973Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M51.4826 39.1712C49.6353 38.0432 49.6104 35.6322 48.8702 33.8162C48.7956 33.5373 49.2123 33.4195 49.2994 33.6984C49.9089 35.1983 50.0085 36.9461 51.041 38.2477C51.2151 38.4336 51.4453 38.6134 51.6381 38.7435C51.7687 38.8055 51.8744 38.9481 51.7873 39.0906C51.7314 39.1898 51.5821 39.2394 51.4826 39.1712Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.4013 35.7312C43.3391 35.6259 43.3764 35.4895 43.4821 35.4275C44.017 35.0928 44.6142 34.8883 45.1864 34.628C45.7586 34.3863 46.2873 34.1012 46.7973 33.7665C47.0461 33.5991 47.2825 33.4132 47.4753 33.1839C47.5561 33.0971 47.7054 33.1033 47.7925 33.1839C48.0724 33.4752 47.2638 33.971 47.0586 34.126C46.1567 34.7457 45.1491 35.1176 44.1601 35.5515C43.9237 35.6259 43.6128 36.0163 43.4013 35.725V35.7312Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.3628 36.6607C45.1078 36.3818 45.7111 36.0471 45.8666 35.8302C46.5757 35.1112 47.1915 34.2683 47.7388 33.4316C47.801 33.3324 47.9379 33.289 48.0436 33.351C48.1431 33.413 48.1867 33.5494 48.1245 33.6547C47.857 34.07 47.5709 34.479 47.2723 34.8757C46.8183 35.5017 46.2772 36.0595 45.7298 36.6049C45.6676 36.7413 45.4686 36.7723 45.3691 36.6607H45.3628Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M61.8691 5.65199C61.9438 4.75949 62.0246 3.84219 62.317 2.98688C62.3605 2.87532 62.4973 2.82573 62.6093 2.86912C62.7213 2.9187 62.771 3.04886 62.7275 3.16042C62.6591 3.34016 62.6155 3.5261 62.572 3.71204C62.4476 4.35042 62.3543 5.0012 62.317 5.65199C62.3108 5.94329 61.8754 5.94329 61.8754 5.65199H61.8691Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M63.3681 4.48165C63.2499 4.45066 63.1815 4.3267 63.2126 4.20894C63.2934 3.88045 63.3929 3.56435 63.5173 3.25446C63.5609 3.1429 63.6977 3.09331 63.8097 3.1367C64.0336 3.23586 63.9154 3.47138 63.847 3.63873C63.7661 3.86805 63.6977 4.09738 63.6417 4.3267C63.6106 4.44446 63.48 4.51264 63.3681 4.48165Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M60.3195 17.9928C60.2013 17.9743 60.1142 17.8627 60.1267 17.7449C60.2013 17.1127 60.2386 16.5363 60.2697 15.9227C60.3132 14.4662 60.2137 12.9973 60.0645 11.5532C60.0645 11.4292 60.164 11.3301 60.2884 11.3301C60.4128 11.3301 60.5123 11.4292 60.5123 11.5532C60.5745 12.0304 60.6118 12.5449 60.6429 13.0035C60.7611 14.6026 60.7797 16.214 60.5745 17.8069C60.562 17.9247 60.4439 18.0114 60.3257 17.999L60.3195 17.9928Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M49.6777 49.9304C49.6777 49.8188 49.7586 49.732 49.8643 49.7134C50.7164 49.261 51.4877 48.6474 52.2154 48.0338C52.7752 47.538 53.3288 47.0235 53.8575 46.4967C53.9446 46.4099 54.0876 46.4099 54.1747 46.4967C54.2618 46.5835 54.2618 46.726 54.1747 46.8128C53.8264 47.1599 53.4656 47.4946 53.1049 47.8293C52.2901 48.5978 51.4069 49.2982 50.4614 49.8994C50.2437 50.0295 49.7524 50.3828 49.6777 49.9428V49.9304Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M58.449 47.7178C58.2251 47.4451 58.6667 47.253 58.7973 47.0484C59.581 46.1001 60.259 45.0403 60.5389 43.8317C60.5576 43.7387 60.5762 43.6396 60.5824 43.5404C60.5949 43.3483 60.8686 43.2801 60.9805 43.435C61.0987 43.6953 60.937 44.0052 60.881 44.2718C60.6695 44.9597 60.371 45.6291 59.9605 46.2241C59.5935 46.7447 59.239 47.2777 58.7662 47.7116C58.6792 47.7922 58.5423 47.7984 58.449 47.7116V47.7178Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M58.7286 47.9407C58.6601 47.7733 58.8032 47.6556 58.9463 47.606C59.1702 47.4944 59.3879 47.3705 59.5993 47.2465C60.0969 46.9428 60.6132 46.6329 60.9739 46.1681C61.1979 45.852 61.571 46.2239 61.2849 46.4904C60.6754 47.1907 59.8357 47.6432 59.0209 48.0584C58.9152 48.108 58.7721 48.046 58.7286 47.9407Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M63.8461 44.1539C63.5226 43.7201 65.0278 43.3482 65.3202 43.2304C65.9733 43.0445 66.6574 43.0011 67.3354 43.0259C67.4536 43.0321 67.5593 43.1251 67.5593 43.249C67.5593 43.3668 67.4598 43.4784 67.3354 43.4722C67.0617 43.4598 66.7881 43.466 66.5144 43.4784C65.712 43.5341 64.9159 43.7697 64.213 44.1539C64.1197 44.2593 63.9456 44.2655 63.8523 44.1539H63.8461Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M67.018 44.8359C66.4707 44.712 65.886 44.6438 65.3387 44.712C65.0588 44.7864 64.9406 44.3649 65.2205 44.2843C65.8549 44.1852 66.5142 44.2657 67.1362 44.4021C67.2544 44.4269 67.3228 44.5632 67.2917 44.6748C67.2606 44.7926 67.1362 44.8545 67.018 44.8298V44.8359Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M62.7712 43.3111C62.6282 43.1251 62.8334 42.9702 62.9703 42.8648C63.1195 42.7285 63.2626 42.5921 63.3808 42.4682C63.7478 42.0777 63.9841 41.5942 64.1769 41.0984C64.2702 40.8567 64.3573 40.6212 64.4444 40.3795C64.4879 40.2679 64.631 40.2183 64.7367 40.2617C64.8549 40.3113 64.8984 40.4414 64.8549 40.553C64.5377 41.4517 64.2018 42.3876 63.4492 43.026C63.2626 43.1623 63.0138 43.5652 62.7712 43.3049V43.3111Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M63.2676 43.7952C63.1183 43.4791 63.5786 43.4109 63.7528 43.2622C64.1384 42.9956 64.4183 42.6114 64.6857 42.2333C64.8599 41.9792 65.0092 41.7127 65.146 41.44C65.2082 41.2788 65.3015 41.0743 65.513 41.1549C65.6249 41.2044 65.6747 41.3346 65.6312 41.4462C65.2455 42.2643 64.7293 43.0886 63.9891 43.634C63.7839 43.7394 63.4667 44.0679 63.2676 43.7952Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M61.2587 40.6899C61.1468 40.0701 61.0659 39.4379 60.8544 38.8429C60.8109 38.7314 60.8544 38.6012 60.9726 38.5516C61.0783 38.5082 61.2214 38.5516 61.2649 38.6694C61.3769 38.9731 61.4577 39.283 61.5199 39.5991C61.5884 39.94 61.6506 40.2808 61.7003 40.6279C61.719 40.7457 61.6257 40.8634 61.5075 40.8758C61.3893 40.8944 61.2774 40.8077 61.2587 40.6837V40.6899Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M64.4306 48.0576C64.1818 47.5679 63.9268 47.0907 63.6096 46.6692C63.4852 46.5143 63.3422 46.3717 63.1805 46.2478C63.0498 46.1858 62.9379 46.0432 63.025 45.8945C63.0872 45.7953 63.224 45.7519 63.3297 45.8139C64.0637 46.2912 64.4431 47.1279 64.8412 47.884C64.9531 48.1505 64.555 48.3241 64.4306 48.0576Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.5577 67.5203C42.861 66.7951 42.0338 66.1815 41.1132 65.7601C41.0448 65.7229 40.964 65.7043 40.8956 65.6547C40.6779 65.4812 40.9204 65.1403 41.1506 65.2952C42.1706 65.7415 43.0974 66.4046 43.8624 67.2104C44.0615 67.4149 43.7567 67.7248 43.5452 67.5265L43.5577 67.5203Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.8626 66.955C43.5578 66.5831 43.1846 66.2732 42.8736 65.9075C42.6994 65.6782 43.0477 65.4117 43.2281 65.6348C43.5453 65.9943 43.9123 66.3104 44.2171 66.6823C44.2979 66.7752 44.2668 66.924 44.1798 66.9922C44.0802 67.0665 43.9496 67.0417 43.8688 66.955H43.8626Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.2058 67.6684C45.1747 66.9247 45.3053 66.1809 45.4484 65.4558C45.5355 65.2202 45.5479 64.5137 45.9086 64.5943C46.0206 64.6252 46.1015 64.7492 46.0641 64.867C45.9771 65.1087 45.9086 65.4124 45.8589 65.6479C45.7345 66.3173 45.6163 66.9867 45.6474 67.6684C45.6474 67.9535 45.2058 67.9597 45.2058 67.6684Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.5865 68.3754C45.8961 67.7308 48.9189 67.0553 49.3232 67.0677C49.4414 67.0553 49.5533 67.1358 49.572 67.2598C49.5907 67.3776 49.5036 67.4953 49.3792 67.5077C49.1615 67.5325 48.95 67.5635 48.7385 67.6069C48.1103 67.7494 47.4448 67.8982 46.9161 68.2763C46.8415 68.3816 46.7109 68.456 46.5865 68.3816V68.3754Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M47.7058 72.5167C47.3388 71.6986 46.7977 70.9238 46.0638 70.397C45.9207 70.2978 45.703 70.242 45.759 70.0313C45.7901 69.9198 45.9145 69.8392 46.0327 69.8764C46.8972 70.304 47.4819 71.1531 47.9422 71.9713C48.0293 72.1696 48.2843 72.4919 47.9982 72.6344C47.8862 72.684 47.7556 72.6282 47.7058 72.5167Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.1196 72.0694C46.0263 71.6975 45.9019 71.3318 45.7277 70.9909C45.6468 70.7492 45.2052 70.4765 45.4665 70.2348C45.9205 69.9063 46.4803 71.6293 46.5487 71.9578C46.6234 72.2367 46.2004 72.3545 46.1196 72.0756V72.0694Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M56.6563 73.6191C56.4448 73.5881 56.4572 73.365 56.4821 73.1977C56.5319 72.3671 56.4386 71.5738 56.1524 70.7929C56.0467 70.5264 56.4448 70.3528 56.563 70.6193C56.8988 71.5118 56.9983 72.4787 56.9051 73.427C56.8926 73.5447 56.7682 73.6315 56.6563 73.6191Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M57.3785 73.1726C57.0986 73.123 57.2105 72.8007 57.2167 72.6024C57.2603 72.373 57.0923 71.9392 57.4407 71.9082C57.7392 71.9206 57.6521 72.2863 57.6708 72.4908C57.6397 72.7201 57.733 73.1912 57.3847 73.1726H57.3785Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M58.249 74.0204C57.9194 73.5308 59.7231 73.0783 60.0528 72.9606C60.171 72.9296 60.2954 72.9978 60.3265 73.1155C60.3949 73.4626 59.8538 73.4378 59.6361 73.5556C59.3126 73.6733 58.983 73.7849 58.6844 73.9708C58.5663 74.0824 58.3797 74.1878 58.249 74.0204Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M58.6913 75.9438C58.5606 75.3612 58.3989 74.7662 58.0568 74.2704C57.8578 74.0659 58.1626 73.756 58.374 73.9543C58.8032 74.5059 58.9836 75.2125 59.1329 75.8819C59.1578 75.9996 59.0582 76.1174 58.9401 76.1298C58.8219 76.1484 58.7099 76.0616 58.6913 75.9376V75.9438Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M54.8954 75.5342C54.4165 75.1561 56.3135 73.9661 56.6556 73.867C56.9231 73.7554 57.0972 74.1583 56.8298 74.276C56.3944 74.462 56.0274 74.7099 55.6667 75.0074C55.4987 75.1561 55.337 75.3173 55.2064 75.497C55.1318 75.59 54.9887 75.6086 54.8954 75.5342Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M55.6367 76.6435C55.8171 75.72 56.4453 74.9267 57.0549 74.2325C57.1544 74.1024 57.3347 73.9722 57.484 74.121C57.6831 74.3441 57.3658 74.5238 57.2414 74.685C57.0735 74.8833 56.9118 75.0878 56.7563 75.3048C56.4826 75.72 56.2027 76.1601 56.0783 76.6435C56.0783 76.9286 55.6367 76.9348 55.6367 76.6435Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M39.5886 105.11C39.4642 104.868 39.5762 104.577 39.6073 104.329C39.7566 103.616 40.0365 102.947 40.3786 102.302C40.5216 102.036 40.6833 101.782 40.8637 101.534C40.9321 101.435 41.0752 101.41 41.1747 101.478C41.4422 101.695 41.0379 101.992 40.957 102.191C40.5092 102.941 40.1671 103.74 40.0116 104.595C39.9929 104.726 39.968 104.85 40.0116 104.98C40.024 105.203 39.713 105.296 39.5949 105.11H39.5886Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M40.3853 104.862C40.0432 104.701 40.4164 104.242 40.5283 104.025C40.69 103.846 40.9948 103.257 41.2747 103.492C41.3618 103.573 41.3742 103.715 41.2871 103.802C41.113 103.982 40.9762 104.149 40.8393 104.354C40.7087 104.534 40.69 104.955 40.379 104.862H40.3853Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M41.5607 106.016C41.2124 105.539 42.8234 105.173 43.1344 105.062C43.5822 104.938 44.0487 104.907 44.509 104.913C44.8013 104.913 44.82 105.334 44.5276 105.353C43.6257 105.328 42.699 105.564 41.9153 106.004C41.822 106.109 41.654 106.121 41.5607 106.01V106.016Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M44.1169 107.198C43.6318 106.956 43.0906 106.832 42.5557 106.807C42.4562 106.807 42.3505 106.807 42.2572 106.832C42.2012 106.869 42.1328 106.876 42.0644 106.851C41.9586 106.807 41.884 106.677 41.9337 106.566C42.1825 106.15 43.3332 106.46 43.7375 106.566C43.9614 106.677 44.571 106.752 44.4217 107.105C44.3657 107.217 44.2289 107.248 44.1231 107.198H44.1169Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M38.3699 110.131C38.2455 110.02 38.3015 109.852 38.4072 109.753C38.7555 109.294 39.0416 108.792 39.278 108.272C39.4273 107.912 39.589 107.534 39.5579 107.138C39.5579 107.02 39.6512 106.914 39.7694 106.908C40.0493 106.908 40.0119 107.237 39.9995 107.429C39.9062 108.111 39.5703 108.737 39.2282 109.325C39.0665 109.598 38.8924 109.871 38.6809 110.119C38.6062 110.212 38.4507 110.212 38.3699 110.131Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M40.2795 109.072C40.0555 108.985 40.1551 108.743 40.2173 108.576C40.2857 108.34 40.3354 108.105 40.379 107.863C40.4101 107.677 40.379 107.361 40.6527 107.38C40.7708 107.392 40.8641 107.497 40.8517 107.621C40.8144 108.074 40.7086 108.514 40.5656 108.942C40.5283 109.053 40.3914 109.115 40.2795 109.072Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M35.3164 106.456C35.3413 106.146 35.758 106.134 35.9944 106.053C36.7034 105.88 37.4374 105.867 38.1589 105.967C38.2771 105.985 38.3704 106.084 38.3579 106.208C38.3455 106.326 38.2335 106.425 38.1153 106.407C37.8417 106.369 37.5742 106.351 37.3005 106.345C36.7656 106.345 36.2307 106.425 35.7269 106.593C35.5839 106.723 35.3351 106.673 35.3226 106.456H35.3164Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M38.793 105.799C38.3265 105.124 37.8102 104.504 37.2318 103.927C37.1385 103.853 37.1385 103.698 37.2193 103.611C37.3873 103.438 37.5863 103.624 37.6983 103.76C38.2269 104.324 38.7308 104.913 39.1661 105.557C39.2346 105.657 39.2035 105.799 39.0977 105.861C38.9982 105.929 38.8552 105.898 38.793 105.793V105.799Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M48.5642 109.915C48.502 109.735 48.6699 109.654 48.7819 109.58C49.6091 108.879 50.3555 107.975 50.4861 106.871C50.5048 106.754 50.6416 106.679 50.7536 106.704C51.2014 106.778 50.5857 108.142 50.4302 108.377C50.2684 108.663 48.8254 110.596 48.5642 109.915Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M48.5957 107.837C48.5957 107.719 48.6828 107.614 48.801 107.607C49.4167 107.242 49.8957 106.628 50.2253 106.002C50.2813 105.897 50.4181 105.853 50.5239 105.909C50.9219 106.12 50.1009 106.969 49.9392 107.198C49.7339 107.434 48.7761 108.5 48.5957 107.843V107.837Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M51.6544 104.521C51.3993 104.329 51.7477 104.05 51.8285 103.876C52.0587 103.504 52.2141 103.095 52.2888 102.661C52.3634 102.432 52.2452 101.961 52.5936 101.961C52.7117 101.973 52.805 102.079 52.7926 102.203C52.7366 102.829 52.5998 103.455 52.2701 104C52.1146 104.192 51.9778 104.694 51.6544 104.521Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M52.0664 105.006C52.0664 104.907 52.1286 104.814 52.2281 104.783C52.8439 104.281 53.1798 103.512 53.3788 102.756C53.4472 102.477 53.8764 102.57 53.8142 102.855C53.6898 103.314 53.5343 103.772 53.2917 104.188C53.1362 104.454 52.3028 105.718 52.0726 105.012L52.0664 105.006Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M54.8774 108.005C54.4918 107.324 53.8822 106.784 53.1732 106.456C53.0363 106.4 52.9057 106.332 52.7565 106.313C52.6196 106.332 52.4828 106.245 52.4766 106.103C52.4766 105.985 52.5699 105.879 52.688 105.873C53.0052 105.867 53.2976 106.028 53.5775 106.171C54.0191 106.4 54.4234 106.71 54.753 107.088C54.9521 107.311 55.1324 107.559 55.2755 107.826C55.3315 107.931 55.2755 108.073 55.1698 108.123C55.0578 108.179 54.9334 108.123 54.8712 108.018L54.8774 108.005Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M52.9317 108.884C52.8757 108.215 52.7762 107.539 52.5088 106.926C52.4217 106.783 52.5088 106.573 52.6829 106.566C52.7887 106.566 52.882 106.635 52.9068 106.734C52.969 106.889 53.0312 107.044 53.081 107.205C53.2552 107.744 53.3174 108.302 53.3671 108.86C53.3733 108.977 53.2738 109.083 53.1556 109.089C53.0312 109.089 52.9317 108.996 52.9255 108.878L52.9317 108.884Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M60.5386 17.0132C60.862 15.6807 61.0611 14.3233 61.1419 12.9598C61.1917 11.6954 61.4156 10.4063 61.1419 9.15428C60.918 7.85891 60.0721 6.79287 59.674 5.56568C59.5061 5.02646 59.4999 4.45625 59.3382 3.91703C59.2138 3.56995 59.0085 3.28484 58.8281 2.96875C59.1018 3.23526 59.419 3.52656 59.6243 3.86745C59.842 4.23932 59.9601 4.66078 60.1467 5.04505C60.7563 6.0987 61.8137 6.82386 62.3486 7.93329C63.3313 9.92282 62.7653 12.2532 62.0314 14.2304C61.6582 15.2654 61.0113 16.1765 60.5635 17.1682C60.5946 17.1682 60.6132 17.1062 60.6381 17.1C60.6319 17.131 60.5946 17.193 60.5572 17.193C60.495 17.193 60.5448 17.0194 60.5572 17.0008L60.5386 17.0132Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M61.8695 16.4119C65.3215 14.4286 65.9497 10.5053 66.043 6.87331C66.1861 6.27211 66.2234 5.65232 66.1363 5.04492C66.2483 5.31763 66.3354 5.63372 66.4038 5.93122C66.4971 7.66664 67.1439 9.35248 66.9014 11.0941C66.5468 13.7344 64.3637 15.7301 61.8633 16.4119H61.8695Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M66.5155 10.054C66.9012 8.90736 67.9958 8.21939 69.1216 7.91569C69.6254 7.77934 70.1479 7.71736 70.6642 7.63059C71.3732 7.46944 72.1321 7.50043 72.2751 8.37434C72.3124 8.57887 72.2876 8.80819 72.244 9.00033C72.1943 8.60986 71.9019 7.92189 71.4168 8.21319C70.7575 8.64085 69.8929 8.96314 69.1527 9.0871C68.1451 9.27304 66.9882 9.53955 66.4782 10.525C66.4595 10.5684 66.4284 10.6366 66.4533 10.6738C66.4533 10.6738 66.4533 10.6738 66.4658 10.6738C66.4658 10.6738 66.4906 10.6738 66.4844 10.6738C66.3227 10.6862 66.5031 10.1407 66.5342 10.0416L66.5155 10.054Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M56.5391 30.4247C57.0491 27.6357 57.8763 24.9024 59.1763 22.3736C59.9289 20.9109 60.8246 19.5288 61.714 18.1467C61.7513 18.0909 61.7575 18.0537 61.8011 18.1033C61.8011 18.1095 61.7824 18.0909 61.77 18.0909C61.0982 19.8139 60.5695 21.5865 59.9102 23.3157C59.3193 24.859 58.5232 26.3341 57.7519 27.7844C57.3165 28.6459 56.9371 29.5632 56.5391 30.4309V30.4247Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M51.673 35.0978C51.5735 35.0234 51.4055 34.949 51.6046 34.8622C52.1457 34.6515 52.7366 34.5895 53.2964 34.4346C54.0241 34.2425 54.6212 34.0193 55.2743 33.7032C55.6226 33.5235 55.9834 33.3004 56.1948 32.9595C56.4685 32.4141 56.3877 31.6393 55.8341 31.3046C54.9757 30.7282 53.9122 30.877 52.9356 30.8398C51.9653 30.7902 51.4553 29.8419 51.9529 29.0114C52.2639 28.3482 52.9108 27.9454 53.3524 27.3876C53.371 27.3566 53.3897 27.3194 53.4146 27.3008C53.2591 27.7098 52.9792 28.0569 52.7366 28.4102C52.4069 28.8689 51.8472 30.0093 52.749 30.127C53.8313 29.9163 54.9882 29.5444 56.0331 30.096C57.8182 30.7716 58.235 33.5049 56.5494 34.5152C55.6599 35.0668 54.5652 35.0916 53.5514 35.0854C52.8859 35.0978 52.2328 34.8932 51.5797 34.9614C51.5921 35.0172 51.6668 35.0544 51.6854 35.0978H51.673Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.6982 36.7353C41.6595 36.6672 41.0313 35.6569 41.0375 34.69C41.0064 32.8182 42.9097 31.4423 44.6948 31.6592C45.0431 31.6592 45.4349 31.8266 45.7646 31.6778C45.7708 31.6716 45.777 31.6654 45.7895 31.6592C45.7273 31.746 45.634 31.8018 45.5344 31.839C44.6201 32.1551 43.5876 32.4154 43.0154 33.2645C42.7479 33.686 42.5054 34.1384 42.4867 34.6528C42.4618 35.0867 42.5613 35.6073 43.0652 35.6879C44.3776 35.818 45.6277 35.1549 46.4612 36.5866C45.2048 35.44 44.0914 36.9151 42.6982 36.7291V36.7353Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M50.0692 39.7666C48.8252 39.3947 48.365 38.0374 48.0602 36.9093C48.0415 36.835 48.0042 36.7172 48.0851 36.6676C48.0913 36.6614 48.1473 36.6366 48.1224 36.6552C48.0851 36.68 48.0602 36.7172 48.0788 36.7606C48.253 37.0519 48.5142 37.2998 48.7319 37.5601C48.9807 37.8452 49.2295 38.0993 49.4845 38.3721C49.9635 38.9051 50.5357 39.5992 51.338 39.4753C51.4127 39.4691 51.4873 39.4257 51.5495 39.4071C51.2012 39.8472 50.5792 39.9091 50.0754 39.7604L50.0692 39.7666Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M49.7332 50.2648C49.6958 50.1595 49.7643 50.0169 49.7705 49.9053C50.069 48.4364 50.1312 46.9303 50.0566 45.4366C49.8887 43.7446 49.5777 42.0216 49.1236 40.3853C48.8935 39.7098 48.5576 39.0528 48.2715 38.4082C48.626 38.8359 48.9743 39.2697 49.3289 39.6974C50.3054 40.9122 50.9771 42.3873 51.369 43.8872C51.7111 45.4924 51.2259 47.1287 50.5417 48.579C50.2992 49.1492 49.9322 49.676 49.7332 50.2462C49.7394 50.2462 49.7518 50.2338 49.758 50.2276C49.7705 50.2276 49.7332 50.2648 49.7207 50.2524L49.7332 50.2648Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M51.7754 50.4889C51.5515 50.4207 50.5563 50.2224 50.5625 49.9745C50.5874 49.9435 50.6558 49.9559 50.6869 49.9559C50.662 49.9683 50.6061 49.9559 50.5812 49.9869C50.718 50.117 50.9606 50.1046 51.1285 50.1294C52.161 50.2162 53.206 50.0055 54.1265 49.5406C56.6331 48.332 58.1321 45.8033 59.1086 43.3055C59.4507 42.3386 59.488 41.2974 59.5751 40.2871C59.7244 41.3284 59.9545 42.413 59.8737 43.479C59.4756 47.6936 56.2972 51.4124 51.7692 50.4827L51.7754 50.4889Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M62.7149 40.1632C62.5594 40.1632 62.3977 40.0889 62.3044 39.9587C62.2857 39.9277 62.2173 39.8099 62.2795 39.779C62.3044 39.7666 62.3293 39.8037 62.3417 39.8161C62.3417 39.8285 62.3106 39.7852 62.2857 39.7914C62.2671 39.8285 62.323 39.8781 62.3541 39.9029C62.9761 40.1694 63.256 39.2459 63.3555 38.7873C63.511 38.0064 63.5483 37.1944 63.878 36.4631C64.2947 35.49 65.2401 34.7958 66.3224 34.9012C67.0999 34.9198 67.7841 35.4032 68.2568 35.9858C68.9596 36.8349 69.0591 38.1055 68.5118 39.0538C68.3252 39.3947 68.0826 39.7232 67.8276 40.0145C68.1635 39.3203 68.4683 38.5828 68.3128 37.808C68.1324 36.6366 66.6272 35.5582 65.576 36.4197C64.4378 37.4299 64.444 39.8843 62.7087 40.157L62.7149 40.1632Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M70.5442 46.3604C69.5615 46.2116 68.6534 45.753 67.6831 45.5484C66.9616 45.3687 66.2463 45.1208 65.4999 45.0526C65.4004 45.0526 65.3009 45.0278 65.2138 45.0774C65.2138 45.0774 65.2138 45.0898 65.2138 45.096C65.2138 45.096 65.22 45.1084 65.2263 45.1084C65.0708 45.0526 65.388 44.9348 65.4191 44.9348C65.8234 44.7923 66.2463 44.7365 66.6755 44.6993C67.5649 44.6311 68.4793 44.563 69.3563 44.7737C69.9596 44.9039 70.4945 45.2633 71.1165 45.3253C71.9873 45.4059 72.6092 44.6126 72.7523 43.8254C72.7834 43.6891 72.7896 43.5341 72.8083 43.4102C72.9451 43.8192 73.0135 44.2469 72.9389 44.6745C72.7834 45.7902 71.6389 46.5277 70.5442 46.3542V46.3604Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M49.3229 62.1773C49.211 59.884 49.298 57.5474 49.9138 55.3224C50.2932 53.9154 50.797 52.5457 51.1889 51.1388C51.2075 51.0644 51.2635 50.8599 51.3506 51.0024C51.3319 51.0024 51.3133 50.9652 51.2946 50.9714C51.1951 52.6945 51.3382 54.4547 51.0894 56.1901C50.9277 57.3987 50.6229 58.5825 50.2186 59.7353C49.92 60.5596 49.5593 61.3591 49.3167 62.1773H49.3229Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M58.4283 54.3113C57.0226 54.051 55.8533 53.2204 54.6218 52.5573C53.7634 52.1606 52.8615 51.7949 51.9161 51.7205C51.7855 51.7205 50.9645 51.6833 51.1511 51.8879C50.9582 51.7639 51.3439 51.6462 51.4185 51.6214C52.8491 51.1937 54.4289 51.3177 55.8471 51.7391C56.8733 52.0552 57.825 52.5635 58.8077 52.9787C60.2881 53.5985 61.9612 53.5303 63.4602 53.0159C62.0172 54.0386 60.2072 54.7389 58.4283 54.3051V54.3113Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M35.5834 80.5297C34.47 79.9037 33.4251 79.0174 32.8591 77.8584C32.3366 76.7366 32.1873 75.466 32.1935 74.2388C32.1935 73.9661 32.2931 71.0159 32.3988 71.0531C32.4299 71.8898 32.716 72.7203 32.8902 73.5385C33.1638 74.6727 33.5495 75.7821 34.0595 76.8295C34.6255 78.0505 35.8011 78.8501 36.958 79.4389C38.8301 80.4305 40.6028 80.5855 42.6678 80.319C40.3354 81.6267 37.9842 81.7755 35.5896 80.5235L35.5834 80.5297Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M44.2922 80.0649C44.2362 80.0339 44.2424 79.9224 44.2424 79.8728C44.2735 79.1166 44.4352 78.3729 44.5098 77.6168C44.6591 76.4206 44.8271 75.2244 44.9515 74.0282C44.995 73.334 44.8395 72.6398 44.6964 71.9642C44.6031 71.487 44.4601 71.0036 44.3357 70.5449C46.0026 72.7948 46.7801 73.991 45.8098 76.8296C45.4801 77.7779 44.939 78.6208 44.4788 79.5071C44.4103 79.6807 44.2797 79.8604 44.3046 80.0463C44.3295 80.0649 44.3792 80.0463 44.3917 80.0649C44.3606 80.0649 44.317 80.0835 44.2922 80.0649Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.2071 81.6932C45.3612 81.3895 44.7579 80.6705 44.2229 79.9826C44.2105 79.964 44.1359 79.871 44.2043 79.8772C44.2105 79.8772 44.1918 79.8772 44.1918 79.8772C44.988 80.6395 46.2071 81.2159 47.3267 80.8565C49.2921 80.1065 50.9093 78.6934 52.3212 77.1687C53.1236 76.2514 53.7704 75.2102 54.4297 74.1875C54.268 74.7391 54.0752 75.3093 53.8948 75.8671C53.4097 77.5344 52.5949 79.152 51.2701 80.3172C50.1505 81.3399 47.6812 82.3192 46.2133 81.687L46.2071 81.6932Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M51.165 83.8095C50.0392 83.7228 49.0005 83.1897 48.0489 82.6257C47.5824 82.3716 47.2279 81.9502 46.78 81.6713C46.7551 81.6775 46.7365 81.6527 46.78 81.6589C47.1159 81.7518 47.4518 81.9749 47.7752 82.0803C49.0378 82.4522 50.4186 82.6629 51.7248 82.4274C53.908 81.882 55.8424 80.6424 57.6461 79.3408C58.1748 78.9752 58.7097 78.6157 59.2384 78.25C58.548 79.0495 57.8638 79.8986 57.1734 80.7168C55.7304 82.4894 53.5224 83.8839 51.1712 83.8157L51.165 83.8095Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.3957 98.8806C43.1282 96.8167 42.8857 94.7466 42.9168 92.6641C43.0287 90.5692 43.2651 88.4743 43.6134 86.4104C43.7627 85.6109 43.9679 84.8299 44.1483 84.0366C44.3847 82.859 44.8325 81.6752 44.8263 80.4666C44.8263 80.4728 44.82 80.479 44.8138 80.4852C44.8138 80.4728 44.82 80.448 44.8387 80.4604C45.0315 81.2166 44.9631 82.0099 44.9818 82.7846C45.0315 84.8795 44.8014 86.9682 44.5775 89.0445C44.2727 92.3294 43.7564 95.6143 43.4019 98.8806H43.3957Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M40.4093 121.558C40.2475 121.329 40.7389 120.784 40.8695 120.579C41.3174 119.947 41.7901 119.346 42.2317 118.714C43.5316 116.693 44.8191 114.567 45.2483 112.181C45.3043 111.927 45.3478 111.654 45.4038 111.406C45.6277 112.757 45.8081 114.146 45.5095 115.503C45.1053 117.183 43.967 118.614 42.6484 119.705C42.0015 120.226 41.2863 120.654 40.6581 121.193C40.5026 121.366 40.2538 121.546 40.6456 121.583C40.571 121.583 40.4652 121.596 40.3968 121.558H40.4093Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.9726 122.558C42.1516 122.54 41.3492 122.342 40.5717 122.118C40.5717 122.131 40.5904 122.143 40.6028 122.156C40.4411 122.038 40.9325 122.118 40.9822 122.1C43.041 122.063 45.0314 121.3 46.7605 120.228C48.415 119.249 50.1255 118.121 50.99 116.348C51.1269 116.069 51.245 115.759 51.3694 115.48C51.2886 116.001 51.2015 116.522 51.046 117.03C50.4364 119.081 48.8442 120.699 47.0093 121.734C45.8026 122.459 44.3659 122.639 42.9788 122.552L42.9726 122.558Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M39.9564 122.675C39.9689 122.675 39.9813 122.681 39.9938 122.675C40.056 122.588 39.9689 122.464 39.9253 122.39C38.8991 121.076 37.7795 119.818 36.9211 118.374C36.5293 117.717 36.2059 117.016 35.7083 116.434C34.3959 115.014 32.6357 114.078 30.7697 113.607C30.297 113.452 29.8118 113.31 29.3516 113.143C29.9487 113.18 30.5396 113.217 31.1367 113.236C33.438 113.093 35.9571 113.843 37.2881 115.832C37.9412 116.756 38.246 117.859 38.6938 118.882C39.1354 119.929 39.4962 121.008 39.9129 122.068C39.9564 122.185 40.1741 122.712 39.9564 122.669V122.675Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M29.1581 112.441C27.8893 112.2 26.807 111.288 26.2224 110.16C25.7683 109.008 25.9176 107.65 26.0793 106.473C26.1602 107.551 26.3965 108.654 26.8941 109.634C27.5037 110.669 28.2003 111.716 29.1581 112.447V112.441Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2704 163.575C41.5613 161.021 40.9331 158.437 40.7279 155.784C40.1121 150.634 40.0126 145.452 39.9193 140.277C39.8882 136.478 40.0001 132.672 40.1556 128.873C40.2987 126.691 40.9144 124.584 41.3374 122.452C41.3685 122.353 41.3623 122.241 41.4183 122.154C41.4183 122.154 41.4245 122.154 41.4307 122.154C41.4494 122.173 41.4494 122.204 41.4556 122.229C41.4556 122.21 41.4431 122.191 41.4307 122.173C41.3747 124.324 41.5302 126.474 41.5364 128.625C41.3561 135.127 40.9704 141.628 41.1446 148.136C41.2441 153.299 41.9718 158.437 42.2704 163.581V163.575Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M31.5977 142.936C29.8747 141.194 28.015 139.589 26.3854 137.754C23.2319 134.259 21.5526 129.778 20.066 125.39C19.357 123.295 18.6479 121.194 17.7833 119.154C17.435 118.473 17.0245 117.816 16.5083 117.245C16.2781 116.979 16.0231 116.731 15.793 116.471C15.8054 116.452 15.8365 116.483 15.8489 116.489C15.8489 116.489 15.8427 116.489 15.8365 116.489C19.6928 118.504 20.5138 122.024 21.8636 125.774C22.6846 128.179 23.6922 130.509 24.7247 132.827C26.3978 136.577 29.3585 139.546 31.5977 142.93V142.936Z" fill={color}/>
        </g>
        <defs>
        <clipPath id="clip0_504_73594">
        <rect width="79" height="150" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        </main>
        
    )
})