import { memo, useState } from "react"
import MyButton from "../../../components/my/MyButton"
import { useAppSelector, RootState } from "../../../redux"
import { WorkDetailForm } from "../../../pages/work/detail"
import BookCover from "../../../components/book/BookCover"
import { ScrollToTopLink } from "../../../components/base/ScrollToTopLink"

interface WorkTemplateCoverProps {
    form: WorkDetailForm
    type: any
}

const patternIds = [1]

export const WorkTemplateCover: React.FC<WorkTemplateCoverProps> = memo(({ form, type }) => {
    // redux
    const { workTags } = useAppSelector((state: RootState) => state.workReducer)
    const templateCover = workTags.find(workTag => workTag.id === form.mainTagId)?.coverUrl || ''

    // responsive data
    const [selectedPatternId, setSelectedPatternId] = useState<number>(patternIds[0])

    return (
        <main className="w-full flex flex-col gap-10">
            {
                form.mainTagId
                    ? (
                        <ul className='mx-auto h-full grid grid-cols-2 gap-5 px-5'>
                            {
                                patternIds.map(patternId => (
                                    <li
                                        key={patternId}
                                        onClick={() => setSelectedPatternId(patternId)}
                                        className="max-w-80 border-solid rounded-md flex"
                                        style={{ borderColor: selectedPatternId === patternId ? "#102e24" : "transparent" }}
                                    >
                                        <BookCover title={form.title} patternId={patternId} main_tag_id={Number(form.mainTagId)} />
                                    </li>
                                ))
                            }
                            <li className="max-w-80">
                                <BookCover title={'敬請期待'} pattern={false} />
                            </li>
                        </ul>
                    ) 
                    : (
                        <section className="text-3xl pt-20 pb-32 text-center">
                            請前往選擇主類別標籤
                        </section>
                    )
            }
            <ScrollToTopLink 
                to="/work/detail"
                state={{
                    type,
                    form: { ...form, cover: null, subCover: templateCover, patternId: selectedPatternId } as WorkDetailForm
                }} 
                className="px-5 flex justify-center"
            >
                <MyButton 
                    type='primary'
                    className='w-full h-12 text-base'
                >儲存</MyButton>
            </ScrollToTopLink>
        </main>
    )
})