import React, { Fragment, memo, useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { RootState, useAppSelector } from '../../redux'
import { useAuthentication } from '../../hooks/useAuthentication'
import { useLanguage } from '../../hooks/useLanguage'

const Navigation: React.FC = memo(() => {
  // router
  const { pathname } = useLocation()

  // hooks
  const { pleaseLogin, stayTuned } = useAuthentication()
  const { t } = useLanguage()

  // redux
  const { isLogin } = useAppSelector((state: RootState) => state.authReducer)

  // other data
  const links = [
    { to: "/star", name: t()['header']['star'], check: true },
    { to: "/read", name: t()['header']['read'], check: false },
    { to: "/books", name: t()['header']['bookmark'], check: true },
  ]

  // method: 判断当前路由是否为 links 中的子路由
  const isActive = useCallback((to: string): boolean => {
    return pathname.startsWith(to)
  }, [pathname])

  return (
    <section className='flex-1'>
      <div className='flex gap-6 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50'>
        {
          links.map((link) => (
            <Fragment key={link.to}>
              <Link to={ link.to }>
                <span 
                  onClick={(link.check && !isLogin) ? pleaseLogin : undefined}
                  style={{ borderBottomWidth: isActive(link.to) ? "2px" : "0" }}
                  className='py-3 border-solid border-0 border-[#F6C159]'
                >{ link.name }</span>
              </Link>
              <span>|</span>
            </Fragment>
          ))
        }
        <Link to='/community' onClick={stayTuned}>
          <span className='py-3 border-solid border-0 border-[#F6C159]'>
            {t()['header']['community']}
          </span>
        </Link>
      </div>
    </section>
  )
})

export default Navigation