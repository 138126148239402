import { WorkVo } from "../../vo/work.vo"
import { http } from "../request"

export const workUpdateApi = async (id: number, body: FormData, accessToken: string) => {
    return await http.put<WorkVo>(`/work/${id}`, body, {
        headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/form-data"
        }
    })
}
