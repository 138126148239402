import React, { memo, useEffect, useState } from 'react'
import MyTabs from '../../components/my/MyTabs'
import { useLocation, useNavigate } from 'react-router-dom'
import Banner from '../../components/base/Banner'
import LeftSVG from '../../components/svgs/LeftSVG'
import { WorkCustomCover } from '../../views/work/cover/custom'
import { WorkTemplateCover } from '../../views/work/cover/template'
import { WorkDetailForm } from './detail'

export const WorkCoverDetail: React.FC = memo(() => {
    // router
    const navigate = useNavigate()
    const location = useLocation()

    // watch location
    useEffect(() => {
        if(location.state) {
            location.state.form && setForm(location.state.form)
            location.state.type && setType(location.state.type)
        }
    }, [location])

    // responsive data
    const [type, setType] = useState<'add' | 'edit'>()
    const [form, setForm] = useState<WorkDetailForm>({
        id: null,
        cover: null,
        subCover: '',
        title: '',
        description: '',
        workTypeId: null,
        mainTagId: null,
        subTagIds: [],
        progress: 0,
        patternId: 0
    })

    return (
        <main className='bg-white mb-16'>
            <Banner title='工作台'></Banner>
            <header className='flex items-center relative text-xl'>
                <section onClick={() => navigate('/work/detail', { state: { form, type } })} className='w-12 h-12 flex justify-center items-center cursor-pointer'>
                    <LeftSVG />
                </section>
                <b className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                    更改封面
                </b>
            </header>
            <MyTabs type='normal' tabsClassName='mx-auto mb-5 gap-10'>
                <MyTabs.Item title='自定義'>
                    <WorkCustomCover {...{ form, type }} />
                </MyTabs.Item>
                <MyTabs.Item title='模板封面'>
                    <WorkTemplateCover {...{ form, setForm, type }} />
                </MyTabs.Item>
            </MyTabs>
        </main>
    )
})