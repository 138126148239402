import React, { MouseEventHandler, memo } from 'react'

interface TagProps {
    text: string
    color: string
    onMouseDown?: MouseEventHandler
}

const MyTag: React.FC<TagProps> = memo(({ text, color, onMouseDown }) => {
    return (
        <div 
            style={{
                color,
                borderColor: color
            }} 
            className="w-full aspect-[2/1] whitespace-nowrap rounded-md px-2 py-[1px] font-semibold border-solid border-[1px] bg-transparent flex justify-center items-center text-sm"
        >
            <span>{text}</span>
            {
                onMouseDown && <span onMouseDown={onMouseDown}>x</span>
            }
        </div>
    )
})

export default MyTag