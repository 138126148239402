import { http } from "../request"

export interface CollectiblePurchaseBody {
    font_id: number
    message: string
}

interface CollectiblePurchaseResponse {
    sessionId: string
    sessionUrl: string
}

export const collectiblePurchaseApi = async (id: number, body: CollectiblePurchaseBody, accessToken: string) => {
    return await http.post<CollectiblePurchaseResponse>(`/collectible/purchase/${id}`, body, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
}
