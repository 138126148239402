import { CollectibleVo } from "../../vo/collectible.vo"
import { WorkVo } from "../../vo/work.vo"
import { http } from "../request"

interface RecommendFindResponse {
    twoCollectibles: CollectibleVo[]
    tenCollectibles: CollectibleVo[]
    works: WorkVo[]
    tagsCollectibles: {
        tag_id: number
        collectibles: CollectibleVo[]
    }[]
}

export const recommendFindApi = async () => {
    return await http.get<RecommendFindResponse>("/recommend")
}
