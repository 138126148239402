import React, { memo, useMemo } from 'react'
import { PatternOneSVG } from '../svgs/PatternOneSVG'
import { RootState, useAppSelector } from '../../redux'
import MyImage from '../my/MyImage'
import { WorkTagProps } from '../../api/workTag/findAll'

interface BookCoverProps {
    coverUrl?: string
    main_tag_id?: number
    title?: string
    pattern?: boolean
    patternId?: number
}

// tip: 书籍封面组件
const BookCover: React.FC<BookCoverProps> = memo((props) => {
    // props
    const { coverUrl, main_tag_id, title, pattern = true } = props

    // redux
    const { workTags } = useAppSelector((state: RootState) => state.workReducer)

    // other data
    const mainTag: WorkTagProps | null = useMemo(() => {
        return workTags.find(workTag => workTag.id === main_tag_id) || null
    }, [main_tag_id, workTags])

    return (
        <main className='w-full aspect-[163/227] bg-[#ebeee8] rounded-md overflow-hidden relative select-none'>
            {
                (mainTag || coverUrl) && (
                    <MyImage
                        src={`${coverUrl}`}
                        subSrc={mainTag?.coverUrl || ''}
                        isErrorOpacity={true}
                        className='w-full h-full object-cover relative z-50'
                    />
                )
            }
            <section className='w-full h-full absolute top-0 left-0'>
                <span 
                    style={{ color: mainTag?.color }}
                    className='book-title source-han-serif-hk absolute top-1/2 -translate-y-1/2 left-0 w-full h-4/5 flex justify-center items-center text-xl'
                >
                    { title }
                </span>
            </section>
            { pattern && <PatternOneSVG color={mainTag?.color} /> }
        </main>
    )
})

export default BookCover