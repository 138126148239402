import { configureStore } from "@reduxjs/toolkit";
import { homeSlice } from "./modules/homeSlice";
import { starSlice } from "./modules/starSlice";
import { readSlice } from "./modules/readSlice";
import { booksSlice } from "./modules/booksSlice";
import { moreSlice } from "./modules/moreSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { workSlice } from "./modules/workSlice";
import { editSlice } from "./modules/editSlice";
import { themeSlice } from "./featrue/themeSlice";
import { accountSlice } from "./featrue/accountSlice";
import { authSlice } from "./featrue/authSlice";
import { userSlice } from "./modules/userSlice";
import { homeCollectibleSlice } from "./modules/homeCollectibleSlice";
import { fileSlice } from "./featrue/fileSlice";

export type RootState = ReturnType<typeof store.getState>
type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const store = configureStore({
    reducer: {
        homeReducer: homeSlice.reducer,
        homeCollectibleReducer: homeCollectibleSlice.reducer,
        starReducer: starSlice.reducer,
        readReducer: readSlice.reducer,
        booksReducer: booksSlice.reducer,
        moreReducer: moreSlice.reducer,
        workReducer: workSlice.reducer,
        editReducer: editSlice.reducer,
        themeReducer: themeSlice.reducer,
        accountReducer: accountSlice.reducer,
        authReducer: authSlice.reducer,
        userReducer: userSlice.reducer,
        fileReducer: fileSlice.reducer
    }
})