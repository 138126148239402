import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WorkTagProps, workTagFindAllApi } from "../../api/workTag/findAll";
import { WorkTypeProps, workTypeFindAllApi } from "../../api/workType/findAll";
import { workFindMineApi } from "../../api/work/findMine";
import { workUpdateApi } from "../../api/work/update";
import { workCreateApi } from "../../api/work/create";
import { message } from "antd";
import { WorkVo } from "../../vo/work.vo";

interface WorkState {
    workTypes: WorkTypeProps[]
    workTags: WorkTagProps[]
    works: WorkVo[]
    myWorks: WorkVo[]
    myWorksLoading: boolean
    selectedWorkTypeId: number | "all"
    selectedWorkTagId: number | "all"
}

const initialState: WorkState = {
    workTypes: [],
    workTags: [],
    works: [],
    myWorks: [],
    myWorksLoading: false,
    selectedWorkTypeId: "all",
    selectedWorkTagId: "all",
}

// 获取所有书籍类别
export const workTypeFindAllAsync = createAsyncThunk(
    "work/workTypeFindAllAsync",
    async () => {
        return await workTypeFindAllApi()
    }
)

// 获取所有书籍标签
export const workTagFindAllAsync = createAsyncThunk(
    "work/workTagFindAllAsync",
    async () => {
        return await workTagFindAllApi()
    }
)

// 获取我的书籍
export const workFindMineAsync = createAsyncThunk(
    "work/workFindMineAsync",
    async (accessToken: string) => {
        return await workFindMineApi(accessToken)
    }
)

// 更新书籍
export const workUpdateAsync = createAsyncThunk(
    "work/workUpdateAsync",
    async (args: {
        id: number, body: FormData, accessToken: string
    }) => {
        const { id, body, accessToken } = args
        return await workUpdateApi(id, body, accessToken)
    }
)

// 创建书籍
export const workCreateAsync = createAsyncThunk(
    "work/workCreateAsync",
    async (args: {
        body: FormData, accessToken: string
    }) => {
        const { body, accessToken } = args
        return await workCreateApi(body, accessToken)
    }
)

export const workSlice = createSlice({
    name: "work-bench",
    initialState,
    reducers: {
        setSelectedWorkTypeId: (state, action: PayloadAction<number | "all">) => ({...state, selectedWorkTypeId: action.payload}),
        setSelectedWorkTagId: (state, action: PayloadAction<number | "all">) => ({...state, selectedWorkTagId: action.payload}),
    },
    extraReducers(builder) {
        builder 
        // workTypeFindAllAsync
        .addCase(workTypeFindAllAsync.pending, (state) => {
            state.workTags = []
        })
        .addCase(workTypeFindAllAsync.fulfilled, (state, action) => {
            state.workTypes = action.payload
        })
        // workTagFindAllAsync
        .addCase(workTagFindAllAsync.pending, (state) => {
            state.workTags = []
        })
        .addCase(workTagFindAllAsync.fulfilled, (state, action) => {
            state.workTags = action.payload
        })
        // workFindMineAsync
        .addCase(workFindMineAsync.pending, (state) => {
            state.myWorksLoading = true
            state.myWorks = []
        })
        .addCase(workFindMineAsync.fulfilled, (state, action) => {
            state.myWorksLoading = false
            state.myWorks = action.payload
        })
        // workUpdateAsync
        .addCase(workUpdateAsync.fulfilled, (state, action) => {
            if(!(action.payload as any).error) {
                const index = state.myWorks.findIndex(work => work.id === action.payload.id)
                if(index !== -1) {
                    state.myWorks[index] = action.payload
                    message.success("修改成功")
                }
            }
        })
        // workCreateAsync
        .addCase(workCreateAsync.fulfilled, (state, action) => {
            if(!(action.payload as any).error) {
                state.myWorks.unshift(action.payload)
                message.success("創建成功")
            }
    })
    }
})

export const {
    setSelectedWorkTypeId,
    setSelectedWorkTagId,
} = workSlice.actions