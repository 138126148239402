// src/components/base/XSperator.tsx
import React, { memo } from 'react';

interface XSperatorProps {
  className?: string;
}

const XSperator: React.FC<XSperatorProps> = memo(({ className }) => {
  return (
    <div className={`w-full h-[1px] bg-gray-300 ${className}`}></div>
  );
});

export default XSperator;