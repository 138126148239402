import React, { memo, useContext } from 'react'
import { ViewportProviderContext } from '../../providers/ViewportProvider'
import InstagramSVG  from '../../assets/svg/instagram.svg'
import FacebookSVG  from '../../assets/svg/facebook.svg'
import { ScrollToTopLink } from '../base/ScrollToTopLink'
import { useLanguage } from '../../hooks/useLanguage'

const FooterCustom: React.FC = memo(() => {
  // context
  const { notDesktop } = useContext(ViewportProviderContext)

  const { t } = useLanguage()

  return (
    <main 
      className={[
        'border-[#D4D4CC] border-solid border-0 border-t-[1px] bg-white',
        notDesktop ? 'py-10' : 'h-24'
      ].join(' ')}
    >
      <div 
        className={[
          'flex justify-between relative',
          notDesktop ? 'flex-col items-center gap-5' : 'px-24 items-end h-1/2'
        ].join(' ')}
      >
        {/* 社交媒体 */}
        <section className='flex gap-5'>
          <a href='https://www.instagram.com/catnip_dodo' target='_blank' rel="noopener noreferrer">
            <img src={InstagramSVG} alt="instagram" />
          </a>
          <a href='https://www.facebook.com/profile.php?id=61560936414435&name=xhp_nt__fb__action__open_user' target='_blank' rel="noopener noreferrer">
            <img src={FacebookSVG} alt="facebook" />
          </a>
        </section>
        {/* 版权 */}
        <span 
          className={[
            'text-[#A7AAA1] text-nowrap',
            notDesktop ? '' : 'absolute left-1/2 bottom-0 -translate-x-1/2'
          ].join(' ')}
        >
          © 2024 The Catnip Limited.
        </span>
        {/* 法律声明 */}
        <section className={['font-bold text-[#102E24] flex gap-5', notDesktop ? '-order-1' : ''].join(' ')}>
            <ScrollToTopLink to='/other/privacy'>{ t()['footer']['privacy'] }</ScrollToTopLink>
            <ScrollToTopLink to='/other/terms'>{ t()['footer']['terms'] }</ScrollToTopLink>
            <ScrollToTopLink to='/other/contact'>{ t()['footer']['contact'] }</ScrollToTopLink>
        </section>
      </div>
    </main>
  )
})

export default FooterCustom