import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { workFindApi } from "../../api/work/find";
import { workFindUniqueApi } from "../../api/work/findUnique";
import { chapterFindUniqueApi } from "../../api/chapter/findUnique";
import { ChapterVo } from "../../vo/chapter.vo";
import { WorkVo } from "../../vo/work.vo";
import { workAddShelfApi } from "../../api/work/addShelf";
import { workRemoveShelfApi } from "../../api/work/removeShelf";
import { CollectibleFindParams, collectibleFindApi } from "../../api/collectible/find";
import { CollectibleProps, CollectibleVo } from "../../vo/collectible.vo";
import { workAddLikeApi } from "../../api/work/addLike";
import { workRemoveLikeApi } from "../../api/work/removeLike";

interface ReadState {
    books: WorkVo[] | null
    booksLoading: boolean
    browsingBook: WorkVo | null
    browsingBookLoading: boolean
    browsingChapter: ChapterVo | null
    browsingChapterLoading: boolean
    isCollectibleShow: boolean
    bookInitialCollectibles: CollectibleVo[] | null
    bookCollectibles: CollectibleProps[] | null
    bookCollectiblesLoading: boolean
    workTypeId: number | "all"
    workTagId: number | "all"
}

const initialState: ReadState = {
    books: null,
    booksLoading: false,
    browsingBook: null,
    browsingBookLoading: false,
    browsingChapter: null,
    browsingChapterLoading: false,
    isCollectibleShow: true,
    bookInitialCollectibles: null,
    bookCollectibles: null,
    bookCollectiblesLoading: false,
    workTypeId: "all",
    workTagId: "all",
}

// 获取所有书籍
export const workFindAllAsync = createAsyncThunk(
    "read/workFindAllAsync",
    async (accessToken: string) => {
        return await workFindApi(accessToken) 
    }
)

// 根据 id 获取书籍
export const workFindUniqueAsync = createAsyncThunk(
    "read/workFindUniqueAsync",
    async (args: {
        id: number,
        accessToken: string
    }) => {
        const { id, accessToken } = args
        return await workFindUniqueApi(id, accessToken, false)
    }
)

// 根据 id 获取章节
export const chapterFindUniqueAsync = createAsyncThunk(
    "read/chapterFindUniqueApi",
    async (id: number) => {
        return await chapterFindUniqueApi(id)
    }
)

// 书籍加入书架
export const bookAddShelfAsync = createAsyncThunk(
    "read/bookAddShelfAsync",
    async (args: {
        id: number,
        accessToken: string
    }) => {
        const { id, accessToken } = args
        return await workAddShelfApi(id, accessToken)
    }
)

// 书籍移出书架
export const bookRemoveShelfAsync = createAsyncThunk(
    "read/bookRemoveShelfAsync",
    async (args: {
        id: number,
        accessToken: string
    }) => {
        const { id, accessToken } = args
        return await workRemoveShelfApi(id, accessToken)
    }
)

// 书籍添加点赞
export const bookAddLikeAsync = createAsyncThunk(
    "read/bookAddLikeAsync",
    async (args: {
        id: number,
        accessToken: string
    }) => {
        const { id, accessToken } = args
        return await workAddLikeApi(id, accessToken)
    }
)

// 书籍移出书架
export const bookRemoveLikeAsync = createAsyncThunk(
    "read/bookRemoveLikeAsync",
    async (args: {
        id: number,
        accessToken: string
    }) => {
        const { id, accessToken } = args
        return await workRemoveLikeApi(id, accessToken)
    }
)

// 根据书籍 id 获取其所有藏品
export const collectibleFindByBookIdAsync = createAsyncThunk(
    "read/collectibleFindByBookIdAsync",
    async (args: {
        params: CollectibleFindParams,
        accessToken: string
    }) => {
        const { params, accessToken } = args
        return await collectibleFindApi({ work_id: params.work_id }, accessToken)
    }
)

export const readSlice = createSlice({
    name: "read",
    initialState,
    reducers: {
        setWorkTypeId: (state, action: PayloadAction<number | "all">) => ({ ...state, workTypeId: action.payload }),
        setWorkTagId: (state, action: PayloadAction<number | "all">) => ({ ...state, workTagId: action.payload }),
        setBookCollectibles: (state, action: PayloadAction<CollectibleProps[] | null>) => ({ ...state, bookCollectibles: action.payload }),
        setBookCollectiblesLoading: (state, action: PayloadAction<boolean>) => ({ ...state, bookCollectiblesLoading: action.payload }),
        setIsCollectibleShow: (state, action: PayloadAction<boolean>) => ({ ...state, isCollectibleShow: action.payload })
    },
    extraReducers(builder) {
        builder
        // workFindAllAsync
        .addCase(workFindAllAsync.pending, (state) => {
            state.booksLoading = true
            state.books = null
        })
        .addCase(workFindAllAsync.fulfilled, (state, action) => {
            state.booksLoading = false
            state.books = action.payload
        })
        // workFindUniqueAsync
        .addCase(workFindUniqueAsync.pending, (state) => {
            state.browsingBookLoading = true
            state.browsingBook = null
        })
        .addCase(workFindUniqueAsync.fulfilled, (state, action) => {
            state.browsingBookLoading = false
            state.browsingBook = action.payload
        })
        // chapterFindUniqueAsync
        .addCase(chapterFindUniqueAsync.pending, (state) => {
            state.browsingChapterLoading = true
            state.browsingChapter = null
        })
        .addCase(chapterFindUniqueAsync.fulfilled, (state, action) => {
            state.browsingChapterLoading = false
            state.browsingChapter = action.payload
        })
        // collectibleFindByBookIdAsync
        .addCase(collectibleFindByBookIdAsync.fulfilled, (state, action) => {
            if(action.payload) {
                state.bookInitialCollectibles = action.payload
            }
        })
        // bookAddShelfAsync
        .addCase(bookAddShelfAsync.fulfilled, (state, action) => {
            if(action.payload && state.browsingBook) {
                state.browsingBook = { ...state.browsingBook, is_shelf: !state.browsingBook.is_shelf }
            }
        })
        // bookRemoveShelfAsync
        .addCase(bookRemoveShelfAsync.fulfilled, (state, action) => {
            if(action.payload && state.browsingBook) {
                state.browsingBook = { ...state.browsingBook, is_shelf: !state.browsingBook.is_shelf }
            }
        })
        // bookAddLikeAsync
        .addCase(bookAddLikeAsync.fulfilled, (state, action) => {
            if(action.payload && state.browsingBook) {
                state.browsingBook = { ...state.browsingBook, is_like: !state.browsingBook.is_like }
            }
        })
        // bookRemoveLikeAsync
        .addCase(bookRemoveLikeAsync.fulfilled, (state, action) => {
            if(action.payload && state.browsingBook) {
                state.browsingBook = { ...state.browsingBook, is_like: !state.browsingBook.is_like }
            }
        })
    }
})

export const {  
    setWorkTypeId,
    setWorkTagId,
    setBookCollectibles,
    setBookCollectiblesLoading,
    setIsCollectibleShow
} = readSlice.actions