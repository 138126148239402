import React, { memo, useContext } from 'react'
import { Link } from 'react-router-dom'
import { HeaderCustomContext } from '.'
import { LogoLightSVG } from '../svgs/LogoLightSVG'
import { LogoDarkSVG } from '../svgs/LogoDarkSVG'

const Logo: React.FC = memo(() => {
  // context
  const { isTransparent } = useContext(HeaderCustomContext)

  return (
    <Link to=''>
      { isTransparent ? <LogoLightSVG /> : <LogoDarkSVG /> }
    </Link>
  )
})

export default Logo