import { WorkVo } from "../../vo/work.vo"
import { http } from "../request"

export const workFindMyShelfApi = async (userId: number, accessToken: string) => {
    return (accessToken.length === 0)
    ? await http.get<WorkVo[]>(`/work/my-shelf/${userId}`)
    : await http.get<WorkVo[]>(`/work/my-shelf/${userId}`, {
        headers: {
            Authorization: 'Bearer ' + accessToken
        }
    })
}
