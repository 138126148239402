import { useState } from 'react'
import zh_hk from "../lang/zh_hk.json"
import zh from "../lang/zh.json"

type LanguageType = 'zh_hk' | 'zh'

const translations: Record<LanguageType, TranslationsKeys> = { zh_hk, zh }

export const useLanguage = () => {
  const [language, setLanguage] = useState<LanguageType>('zh_hk');

  const t = () => {
      return translations[language]
  }

  return { language, setLanguage, t }
}
