import React, { memo } from 'react'
import { Link, useNavigate } from 'react-router-dom';

interface ScrollToTopLinkProps {
    to: string,
    children: JSX.Element | string | null,
    callback?: () => void,
    state?: {
        [key: string]: any
    },
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void // Optional onClick function
    className?: string
}

// tip: 超链接 - 可滚动至页面顶部
export const ScrollToTopLink = memo((props: ScrollToTopLinkProps) => {
    // props
    const { to, children, callback, state, onClick, className } = props;

    // router
    const navigate = useNavigate();
    
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        navigate(to, { state });
        window.scrollTo(0, 0);
        callback && callback();
        onClick && onClick(event); // Call the optional onClick function
    };

    return (
        <Link
            to={to}
            state={state}
            onClick={handleClick}
            className={className}
        >{ children }</Link>
    )
})