import { http } from "../request"

export interface CollectorRegisterBody {
    account: {
        username: string
        email: string
        password: string
    }
}

export interface CollectorRegisterResponse {
    accessToken: string
}

export const collectorRegisterApi = async (body: CollectorRegisterBody) => {
    return await http.post<CollectorRegisterResponse>("/collector/register", body)
}