import { EditorParagraphJson } from "../../utils/ParagraphsToHtml"
import { ChapterVo } from "../../vo/chapter.vo"
import { http } from "../request"

export interface ChapterUpdateBody {
    id: number
    chapter_prefix: string
    title: string
    paragraphs: EditorParagraphJson
}

export const chapterUpdateApi = async (body: ChapterUpdateBody, accessToken: string) => {
    return await http.put<ChapterVo>("/chapter", body, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
}
