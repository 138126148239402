import { EditorParagraphJson } from "./ParagraphsToHtml";

const italicToCollectible = (json: string): EditorParagraphJson => {
    return JSON.parse(json).map((item: any) => {
        if (item.type === 'paragraph') {
          return {
            ...item,
            children: item.children.map((child: any) => {
              const { text, italic } = child
              delete child.text
              delete child.italic
              if (italic) {
                return {
                  ...child,
                  text: text.trim(),
                  collectible: true,
                }
              }
              return {
                ...child,
                text: text.trim()
              }
            })
          };
        }
        return item;
    })
}

export default italicToCollectible