import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { CollectibleProps, CollectibleVo } from "../../vo/collectible.vo"
import { CollectibleFindParams, collectibleFindApi } from "../../api/collectible/find"
import { collectibleAddShelfApi } from "../../api/collectible/addShelf"
import { collectibleRemoveShelfApi } from "../../api/collectible/removeShelf"

interface HomeCollectibleState {
    browsingInitailCollectible: CollectibleVo | null
    browsingCollectible: CollectibleProps | null
    browsingCollectibleLoading: boolean
}

const initialState: HomeCollectibleState = {
    browsingInitailCollectible: null,
    browsingCollectible: null,
    browsingCollectibleLoading: false
}

// 根据 id 获取藏品
export const collectibleFindUniqueAsync = createAsyncThunk(
    "homeCollectible/collectibleFindUniqueAsync",
    async (args: {
        params: CollectibleFindParams,
        accessToken: string
    }) => {
        const { params, accessToken } = args
        const res = await collectibleFindApi({ id: params.id }, accessToken)
        return res.find(item => item.id === params.id)
    }
)

// 书籍加入书架
export const collectibleAddShelfAsync = createAsyncThunk(
    "homeCollectible/collectibleAddShelfAsync",
    async (args: {
        id: number,
        accessToken: string
    }) => {
        const { id, accessToken } = args
        return await collectibleAddShelfApi(id, accessToken)
    }
)

// 书籍移出书架
export const collectibleRemoveShelfAsync = createAsyncThunk(
    "homeCollectible/collectibleRemoveShelfAsync",
    async (args: {
        id: number,
        accessToken: string
    }) => {
        const { id, accessToken } = args
        return await collectibleRemoveShelfApi(id, accessToken)
    }
)

export const homeCollectibleSlice = createSlice({
    name: "homeCollectible",
    initialState,
    reducers: {
        setBrowsingCollectible: (state, action: PayloadAction<CollectibleProps>) => ({ ...state, browsingCollectible: action.payload }),
        setBrowsingCollectibleLoading: (state, action: PayloadAction<boolean>) => ({ ...state, browsingCollectibleLoading: action.payload })
    },
    extraReducers(builder) {
        builder
        // collectibleFindUniqueAsync
        .addCase(collectibleFindUniqueAsync.pending, (state, action) => {
            state.browsingInitailCollectible = null
        })
        .addCase(collectibleFindUniqueAsync.fulfilled, (state, action) => {
            if(action.payload) {
                state.browsingInitailCollectible = action.payload
            }
        })
        // bookAddShelfAsync
        .addCase(collectibleAddShelfAsync.fulfilled, (state, action) => {
            if(action.payload && state.browsingCollectible) {
                state.browsingCollectible.is_shelf = true
            }
        })
        // bookRemoveShelfAsync
        .addCase(collectibleRemoveShelfAsync.fulfilled, (state, action) => {
            if(action.payload && state.browsingCollectible) {
                state.browsingCollectible.is_shelf = false
            }
        })
    }
})

export const {
    setBrowsingCollectible,
    setBrowsingCollectibleLoading
} = homeCollectibleSlice.actions