// /src/redux/modules/editSlice.ts

import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { chapterFindUniqueApi } from '../../api/chapter/findUnique';
import { workFindUniqueApi } from '../../api/work/findUnique';
import { ChapterUpdateBody, chapterUpdateApi } from '../../api/chapter/update';
import { ChapterCreateBody, chapterCreateApi } from '../../api/chapter/create';
import { ChapterVo } from '../../vo/chapter.vo';
import { WorkVo } from '../../vo/work.vo';
import { chapterPublishApi } from '../../api/chapter/publish';
import { chapterDeleteApi } from '../../api/chapter/delete';

interface EditState {
    editorKey: string;
    editWorkLoading: boolean;
    editWork: WorkVo | null;
    editChapterLoading: boolean;
    editChapter: ChapterVo | null;
}

const initialState: EditState = {
    editorKey: 'catnip-0',
    editWorkLoading: false,
    editWork: null,
    editChapter: null,
    editChapterLoading: false,
};

// 根据 id 获取书籍
export const workFindUniqueAsync = createAsyncThunk(
    'edit/workFindUniqueAsync',
    async (args: { id: number; accessToken: string }) => {
        const { id, accessToken } = args;
        return await workFindUniqueApi(id, accessToken, true);
    }
);

// 根据 id 获取章节
export const chapterFindUniqueAsync = createAsyncThunk(
    'edit/chapterFindUniqueApi',
    async (id: number) => {
        return await chapterFindUniqueApi(id);
    }
);

// 更新章节
export const chapterUpdateAsync = createAsyncThunk(
    'edit/chapterUpdateAsync',
    async (args: { body: ChapterUpdateBody; accessToken: string }) => {
        const { body, accessToken } = args;
        return await chapterUpdateApi(body, accessToken);
    }
);

// 创建章节
export const chapterCreateAsync = createAsyncThunk(
    'edit/chapterCreateAsync',
    async (args: { body: ChapterCreateBody; accessToken: string }) => {
        const { body, accessToken } = args;
        return await chapterCreateApi(body, accessToken);
    }
);

// 发布章节
export const chapterPublishAsync = createAsyncThunk(
    'edit/chapterPublishAsync',
    async (args: { id: number; accessToken: string }) => {
        const { id, accessToken } = args;
        return await chapterPublishApi(id, accessToken);
    }
);

// 删除章节
export const chapterDeleteAsync = createAsyncThunk(
    'edit/chapterDeleteAsync',
    async (args: { id: number; accessToken: string }) => {
        const { id, accessToken } = args;
        await chapterDeleteApi(id, accessToken);
        return { id }; // Return the ID of the deleted chapter
    }
);

export const editSlice = createSlice({
    name: 'edit',
    initialState,
    reducers: {
        setEditorKey: (state, action: PayloadAction<string>) => ({ ...state, editorKey: action.payload }),
        setEditChapter: (state, action: PayloadAction<ChapterVo | null>) => ({ ...state, editChapter: action.payload }),
        setEditWork: (state, action: PayloadAction<WorkVo | null>) => ({ ...state, editWork: action.payload }),
    },
    extraReducers(builder) {
        builder
            // workFindUniqueAsync
            .addCase(workFindUniqueAsync.pending, (state, action) => {
                state.editWorkLoading = true;
                state.editWork = null;
            })
            .addCase(workFindUniqueAsync.fulfilled, (state, action) => {
                state.editWorkLoading = false;
                state.editWork = action.payload;
            })
            // chapterFindUniqueAsync
            .addCase(chapterFindUniqueAsync.pending, (state, action) => {
                state.editChapterLoading = true;
            })
            .addCase(chapterFindUniqueAsync.fulfilled, (state, action) => {
                state.editChapter = action.payload;
                state.editChapterLoading = false;
            })
            // chapterUpdateAsync
            .addCase(chapterUpdateAsync.fulfilled, (state, action) => {
                if (action.payload && state.editWork) {
                    state.editChapter = action.payload;
                    if (state.editWork.chapters) {
                        state.editWork.chapters = state.editWork.chapters.map((chapter) => {
                            return chapter.id === action.payload.id ? action.payload : chapter;
                        });
                    }
                }
            })
            // chapterCreateAsync
            .addCase(chapterCreateAsync.fulfilled, (state, action) => {
                if (action.payload && state.editWork) {
                    state.editChapter = action.payload;
                    // update state.editWork
                    state.editWork.chapters?.pop();
                    state.editWork.chapters?.push(action.payload);
                }
            })
            // chapterPublishAsync
            .addCase(chapterPublishAsync.fulfilled, (state, action) => {
                if (action.payload) {
                    state.editChapter = action.payload;
                    // update state.editWork
                    const newChapters: ChapterVo[] = JSON.parse(JSON.stringify(state.editWork?.chapters));
                    const index = newChapters.findIndex((chapter) => chapter.id === action.payload.id);
                    if (index !== -1) {
                        newChapters[index] = action.payload;
                    }
                    if (state.editWork) {
                        state.editWork = { ...state.editWork, chapters: newChapters };
                    }
                }
            })
            // chapterDeleteAsync
            .addCase(chapterDeleteAsync.fulfilled, (state, action) => {
                if (action.payload && state.editWork) {
                    const newChapters = state.editWork.chapters?.filter((chapter) => chapter.id !== action.payload.id);
                    state.editWork = { ...state.editWork, chapters: newChapters };
                }
            });
    },
});

export const { setEditorKey, setEditChapter, setEditWork } = editSlice.actions;
export default editSlice.reducer;