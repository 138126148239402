import React, { createContext, memo, useEffect, useState } from 'react';
import { debounce } from 'lodash';

interface ViewportProviderProps {
    children: JSX.Element;
}

type ViewportProviderContextType = {
    width: number;
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
    notDesktop: boolean;
};

export const ViewportProviderContext = createContext<ViewportProviderContextType>({} as any);

const MOBILE_BREAKPOINT = 768;
const TABLET_BREAKPOINT = 1024;

const ViewportProvider: React.FC<ViewportProviderProps> = memo(({ children }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [isMobile, setisMobile] = useState<boolean>(width < MOBILE_BREAKPOINT);
    const [isTablet, setIsTablet] = useState<boolean>(width >= MOBILE_BREAKPOINT && width <= TABLET_BREAKPOINT);
    const [isDesktop, setIsDesktop] = useState<boolean>(width > TABLET_BREAKPOINT);
    const [notDesktop, setNotDesktop] = useState<boolean>(width <= TABLET_BREAKPOINT);

    const handleWindowResize = debounce(() => {
        const newWidth = window.innerWidth;
        setWidth(newWidth);
        setisMobile(newWidth < MOBILE_BREAKPOINT);
        setIsTablet(newWidth >= MOBILE_BREAKPOINT && newWidth <= TABLET_BREAKPOINT);
        setIsDesktop(newWidth > TABLET_BREAKPOINT);
        setNotDesktop(newWidth <= TABLET_BREAKPOINT);
    }, 200);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, [handleWindowResize]);

    return (
        <ViewportProviderContext.Provider value={{ isMobile, isTablet, isDesktop, notDesktop, width }}>
            {children}
        </ViewportProviderContext.Provider>
    );
});

export default ViewportProvider;