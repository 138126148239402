import useCookie from './useCookie'; // 导入通用的 useCookie Hook

interface UseAccessTokenReturn {
    accessToken: string;
    setAccessToken: (value: string, expiresDays?: number) => void;
    deleteAccessToken: () => void;
}

// hook: 用于管理 accessToken
const useAccessToken = (): UseAccessTokenReturn => {
    // hook
    const { value, setValue, deleteValue } = useCookie('accessToken')

    return { 
        accessToken: value || "",
        setAccessToken: setValue,
        deleteAccessToken: deleteValue
    }
}

export default useAccessToken;
