import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CollectibleProps, CollectibleVo } from "../../vo/collectible.vo";
import { collectibleFindStarApi } from "../../api/collectible/findStar";

interface UserState {
    starInitialCollectibles: CollectibleVo[] | null
    starCollectibles: CollectibleProps[] | null
    starCollectiblesLoading: boolean
}

const initialState: UserState = {
    starInitialCollectibles: null,
    starCollectibles: null,
    starCollectiblesLoading: false
}

// 根据用户 id 获取已收藏的藏品
// 获取所有书籍
export const collectibleFindStarAsync = createAsyncThunk(
    "user/collectibleFindStarAsync",
    async (userId: number) => {
        return await collectibleFindStarApi(userId) 
    }
)


export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setStarCollectibles: (state, action: PayloadAction<CollectibleProps[]>) => ({ ...state, starCollectibles: action.payload }),
        setStarCollectiblesLoading: (state, action: PayloadAction<boolean>) => ({ ...state, starCollectiblesLoading: action.payload }),
    },
    extraReducers(builder) {
        builder
        // collectibleFindStarAsync
        .addCase(collectibleFindStarAsync.fulfilled, (state, action) => {
            if(action.payload) {
                state.starInitialCollectibles = action.payload
            }
        })
    }
})

export const {
    setStarCollectibles,
    setStarCollectiblesLoading
} = userSlice.actions