import React, { memo } from 'react'

interface StarFormatSVGProps {
    color: string
    size?: number
}

const StarFormatSVG: React.FC<StarFormatSVGProps> = memo(({color, size = 24}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.1434 6.62704C10.93 4.66605 11.3233 3.68555 11.9996 3.68555C12.6759 3.68555 13.0692 4.66605 13.8558 6.62705L13.8925 6.71836C14.3369 7.82623 14.5591 8.38016 15.0119 8.71685C15.4648 9.05354 16.0592 9.10678 17.2481 9.21326L17.4631 9.2325C19.4089 9.40677 20.3818 9.4939 20.59 10.1129C20.7981 10.7319 20.0756 11.3892 18.6306 12.7039L18.1483 13.1427C17.4168 13.8082 17.051 14.1409 16.8806 14.5771C16.8488 14.6584 16.8223 14.7418 16.8014 14.8266C16.6894 15.2813 16.7965 15.764 17.0107 16.7295L17.0774 17.03C17.471 18.8043 17.6679 19.6915 17.3242 20.0741C17.1958 20.2171 17.0289 20.3201 16.8434 20.3707C16.3472 20.5061 15.6428 19.932 14.2338 18.7839C13.3086 18.0301 12.8461 17.6531 12.3149 17.5683C12.106 17.535 11.8932 17.535 11.6843 17.5683C11.1532 17.6531 10.6906 18.0301 9.7654 18.7839C8.35645 19.932 7.65197 20.5061 7.15577 20.3707C6.97035 20.3201 6.80344 20.2171 6.67502 20.0741C6.33134 19.6915 6.52818 18.8043 6.92185 17.03L6.98853 16.7295C7.20274 15.764 7.30984 15.2813 7.19779 14.8266C7.17688 14.7418 7.15045 14.6584 7.11865 14.5771C6.94817 14.1409 6.58242 13.8082 5.85091 13.1427L5.36863 12.7039C3.92359 11.3892 3.20107 10.7319 3.40924 10.1129C3.61742 9.4939 4.59033 9.40677 6.53614 9.2325L6.75108 9.21326C7.94 9.10678 8.53445 9.05354 8.9873 8.71685C9.44015 8.38016 9.66235 7.82623 10.1067 6.71836L10.1434 6.62704Z" stroke={color} strokeWidth="2"/>
    </svg>
  )
})

export default StarFormatSVG