import { UserVo } from "../../vo/user.vo"
import { http } from "../request"

export interface AccountUpdateBody {
    username?: string
    intro?: string
    saying?: string
    mood_id?: number
}

export const accountUpdateApi = async (body: AccountUpdateBody, accessToken: string) => {
    return await http.put<UserVo>("/account", body, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
}