import { Navigate } from 'react-router-dom'
import Loading from '../components/my/MySpin'
import { Route } from './'
import { lazy, Suspense } from 'react'
import Edit from '../pages/work/edit'
import { WorkDetail } from '../pages/work/detail'
import { WorkCoverDetail } from '../pages/work/cover'

const Work = lazy(() => import('../pages/work'))
const Bench = lazy(() => import('../pages/work/bench'))
const Chart = lazy(() => import('../pages/work/chart'))

export const work: Route[] = [
    { 
        path: '/work', 
        element: (
            <Suspense fallback={<Loading />}>
                <Work />
            </Suspense>
        ),
        children: [
            { path: '', element: <Navigate to={"/work/bench"}/> },
            { path: 'bench', element: <Bench /> },
            { path: 'chart', element: <Chart /> },
        ],
        meta: { check: true, flowers: false, author: true },
    },
    {
        path: '/work/edit/:id',
        element: (
            <Suspense fallback={<Loading />}>
                <Edit />
            </Suspense>
        ),
        meta: { check: true, flowers: false, author: true }
    },
    {
        path: '/work/detail',
        element: (
            <Suspense fallback={<Loading />}>
                <WorkDetail />
            </Suspense>
        ),
        meta: { check: true, flowers: false, author: true }
    },
    {
        path: '/work/cover',
        element: (
            <Suspense fallback={<Loading />}>
                <WorkCoverDetail />
            </Suspense>
        ),
        meta: { check: true, flowers: false, author: true }
    }
]